import {
    CLEAR_DECONNEXION,
    SET_AUTH,
    SET_VERIFY_TOKEN,
    TOKEN_EXPIRED
} from "../../actions/api";

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTH:
            if (
                action.payload &&
                action.payload.success &&
                action.payload.token &&
                action.payload.profil
            ) {
                return {
                    ...state,
                    authenticated: true,
                    nums: action.payload.numAffilieNumPensionAttakmili,
                    info: action.payload?.clientDto
                };
            }
        case SET_VERIFY_TOKEN:
            if (action.payload && action.payload.success) {
                return {
                    ...state,
                    authenticated: true,
                    nums: {
                        numAffilie: action.payload?.response?.numAffilie,
                        numsAttakmili: action.payload?.response?.numsAttakmili,
                        numsTypePensions:
                            action.payload?.response?.numsTypePensions
                    },
                    info: action.payload?.response?.clientDto
                };
            }
            return state;
        case TOKEN_EXPIRED:
        case CLEAR_DECONNEXION:
            localStorage.clear();
            return initialState;
        default:
            return state;
    }
};
