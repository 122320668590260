import React from "react";

import { Button, ConfigProvider } from "antd";
import LockIcon from "@mui/icons-material/Lock";
import { BACKEND_URL } from "../../redux/actions/api";

const ThemeBtn = {
    token: {
        colorPrimary: "#006233"
    },
    components: {
        Button: {
            defaultActiveBorderColor: "#006233",
            defaultActiveColor: "#006233",
            defaultHoverBorderColor: "#006233"
        }
    }
};

const ButtonDgsn = ({ children, ...props }) => (
    <ConfigProvider theme={ThemeBtn}>
        <Button {...props}>{children}</Button>
    </ConfigProvider>
);

export default function TestPage() {
    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "2rem"
            }}
        >
            <ButtonDgsn
                icon={<LockIcon />}
                onClick={e => {
                    e.preventDefault();
                    window.location.href =
                        BACKEND_URL + "/api/dgsn/v1/authWithDgsn";
                }}
                size="large"
                type="primary"
            >
                Utiliser ma CNI Électronique{" "}
            </ButtonDgsn>
        </div>
    );
}
