import React, { useEffect } from "react";

const ChatBotVolksWagen = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://cdn.botpress.cloud/webchat/v1/inject.js";
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            window.botpressWebChat.init({
                composerPlaceholder: "Chat with Volkswagen Maroc",
                botConversationDescription: "Bot Volkswagen",
                botId: "782e705f-91a6-45a4-b1ed-9cf84dece183",
                hostUrl: "https://cdn.botpress.cloud/webchat/v1",
                messagingUrl: "https://messaging.botpress.cloud",
                clientId: "782e705f-91a6-45a4-b1ed-9cf84dece183",
                webhookId: "c9641256-6b22-4196-9c05-c98aca37392d",
                lazySocket: true,
                themeName: "prism",
                botName: "Volkswagen Maroc",
                avatarUrl:
                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPS3JjGkmAp1oPlrq2gannzPc2wlNqh8Pyhq-U1CFzTQ&s",
                website: "https://www.volkswagen.ma/fr.html",
                frontendVersion: "v1",
                theme: "prism",
                themeColor: "#2563eb",
                allowedOrigins: [],
                config: {
                    startOpen: true
                }
            });
            setTimeout(() => {
                if (window.botpressWebChat) {
                    window.botpressWebChat.sendEvent({ type: "show" });
                } else {
                    console.log("Botpress WebChat is not defined");
                }
            }, 100);
        };
    }, []);

    return <div id="webchat" />;
};

export default ChatBotVolksWagen;
