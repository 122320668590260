import { replace } from "connected-react-router";
import { connect } from "react-redux";
import { ROOT } from "../../constants/routes";
import SmartAnalysisOverview from "../../components/Tandem/SmartAnalysis/SmartAnalysisOverview";
import {
    clearCustomFile,
    clearFileCheque,
    sendCustomFile,
    sendFileCheque,
    sendDocStructure,
    clearDocStructure,
    sendTraiterDossier,
    clearTraiterDossier,
    comparerSignature,
    clearComparerSignature
} from "../../redux/actions/api";

const mapStateToProps = state => ({
    isLoading:
        state.data.api.isLoadingData &&
        (state.data.api.isLoadingData.isLoadingData_SEND_CUSTOM_FILE ||
            state.data.api.isLoadingData.isLoadingData_SEND_FILE_CHEQUE ||
            state.data.api.isLoadingData.isLoadingData_SEND_DOC_STRUCTURE ||
            state.data.api.isLoadingData.isLoadingData_SEND_TRAITER_DOSSIER ||
            state.data.api.isLoadingData.isLoadingData_COMPARER_SIGNATURE),
    custoFileSended: state.data.api.data_CUSTOM_FILE,
    chequeFileSended: state.data.api.data_FILE_CHEQUE,
    docStructureSended: state.data.api.data_DOC_STRUCTURE,
    folderSended: state.data.api.data_TRAITER_DOSSIER,
    signatureCompared: state.data.api.data_COMPARER_SIGNATURE
});

const mapDispatchToProps = dispatch => ({
    sendCustomFile: data => dispatch(sendCustomFile(data)),
    clearCustomFile: () => dispatch(clearCustomFile()),
    sendFileCheque: data => dispatch(sendFileCheque(data)),
    clearFileCheque: () => dispatch(clearFileCheque()),
    sendDocStructure: data => dispatch(sendDocStructure(data)),
    clearDocStructure: () => dispatch(clearDocStructure()),
    sendTraiterDossier: data => dispatch(sendTraiterDossier(data)),
    clearTraiterDossier: () => dispatch(clearTraiterDossier()),
    comparerSignature: data => dispatch(comparerSignature(data)),
    clearComparerSignature: () => dispatch(clearComparerSignature())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SmartAnalysisOverview);
