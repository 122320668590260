import { ArrowLeftOutlined } from "@ant-design/icons";
import React from "react";
import { IconNotFound } from "../../assets/icons";

const Page404 = ({ goToHome }) => {
    return (
        <>
            <div className="page-error container">
                <div className="page-error--header">Ooops.</div>
                <div className="page-error--content">
                    <div className="error-code">404</div>
                    <IconNotFound />
                </div>
                <div className="page-error--desc">
                    The page you are looking for does not exit or does not exist
                    anymore
                </div>
                <div onClick={goToHome} className="page-error--btn">
                    <ArrowLeftOutlined />
                    &nbsp;{" "}
                    <span className="btn--content">Return to home page</span>
                </div>
            </div>
        </>
    );
};

export default Page404;
