import React, { useState, useEffect } from "react";
import rocket from "../Assets/rocket-2766.png";
import logo from "../Assets/TendemLogo.png";
import { Upload, Input, Button, Form, List, Typography, Table } from "antd";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { toast } from "react-toastify";
import DescriptionComponent from "../DescriptionComponent";
import { Descriptions } from "antd";

/* const RESPONSE_MOYEN_PAYMENT = {
    success: true,
    response:
        "**Type de document :** Chèque\n\n**Numéro de chèque :** 220 0801526\n\n**Montant:** DH 410.00\n\n**Banque:** Crédit du Maroc\n\n**Titulaire du chèque :** Mr. Ouanzih Radouane\n\n**Agence bancaire :** Casablanca - KM 7.300, Route de Rabat\n\n**Numéro de compte :** 021 780 0000 047 001 76407 1 94\n\n**Téléphone de l'agence bancaire:** 022350638\n\n**fffffffffffffffffffffff**"
};
 */

const baseUrl = "https://tandeem.seven-app.org";

const MoyensPaiment = ({
    SMART_ANALYSIS_ETAPE,
    setShowEtape,
    isLoading,
    sendFileCheque,
    chequeFileSended,
    clearFileCheque
}) => {
    const [dataSource, setDataSource] = useState(null);
    const [recto, setRecto] = useState(null);
    const [verso, setVerso] = useState(null);
    const [signature, setSiganture] = useState(null);
    const [file, setFile] = useState(null);

    const columns = [
        {
            title: "Recto Chèque",
            dataIndex: "recto",
            key: "recto",
            align: "center",
            render: text => (
                <img
                    src={`${baseUrl}${text}`}
                    alt="Recto"
                    style={{ width: "100%", height: "auto" }}
                />
            )
        },
        {
            title: "Verso Chèque",
            dataIndex: "verso",
            key: "verso",
            align: "center",
            render: text => (
                <img
                    src={`${baseUrl}${text}`}
                    alt="Verso"
                    style={{ width: "100%", height: "auto" }}
                />
            )
        }
    ];

    const data = [
        {
            key: "1",
            recto: recto,
            verso: verso
        }
    ];
    useEffect(() => {
        return () => {
            clearFileCheque();
        };
    }, []);
    useEffect(() => {
        if (chequeFileSended && chequeFileSended.success) {
            let copy = chequeFileSended?.response;
            let images = chequeFileSended?.image_urls;
            copy = JSON.parse(copy);
            setDataSource(copy);
            setRecto(images?.recto);
            setVerso(images?.verso);
            setSiganture(images?.signature);
        } else if (chequeFileSended && !chequeFileSended.success) {
            toast.error("Une erreur est survenue!");
        }
    }, [chequeFileSended]);

    const parseResponse = responseText => {
        const lines = responseText.split("\n");
        return lines;
    };
    return (
        <>
            <div className="tandem">
                <div className="tandem-logo">
                    <img src={logo} alt="Tandem" style={{ width: "350px" }} />
                </div>
                <div className="autre-document">
                    {" "}
                    <div
                        className="tandem-go-back-svc bg-none w-1"
                        onClick={() =>
                            setShowEtape(SMART_ANALYSIS_ETAPE.OVERVIEW)
                        }
                    >
                        <KeyboardBackspaceIcon fontSize={"small"} />{" "}
                        <span>Retour</span>{" "}
                    </div>
                    <div className="autre-document-title">
                        <img src={rocket} alt="" className="icon" />
                        <h1>
                            Extraction, analyse et contrôle des moyens de
                            paiement
                        </h1>
                    </div>
                    <div className="autre-document-text">
                        Charger votre document:
                    </div>
                    <div className="autre-doc-input mt-4">
                        <Upload
                            maxCount={1}
                            accept=".pdf"
                            style={{ width: "100%" }}
                            fileList={file ? [file] : []}
                            showUploadList={false}
                            beforeUpload={file => {
                                const isPDF = file.type === "application/pdf";
                                if (!isPDF)
                                    toast.warn(
                                        "Merci de choisir un ficher pdf"
                                    );
                                return false;
                            }}
                            onChange={f => {
                                if (f.file.type === "application/pdf") {
                                    setFile(f.file);
                                }
                            }}
                            onRemove={() => setFile(null)}
                        >
                            <Button
                                style={{
                                    width: "100%",
                                    height: "70px",
                                    backgroundColor: "#262730",
                                    borderColor: "#262730",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    color: "white"
                                }}
                                icon={<CloudUploadOutlinedIcon />}
                            >
                                {file ? file.name : "Chargez un fichier (.pdf)"}
                            </Button>
                        </Upload>
                    </div>
                    <div className="button ">
                        <Button
                            className="doc-button h-75"
                            onClick={() => {
                                let formData = new FormData();
                                formData.append("file", file);
                                sendFileCheque(formData);
                            }}
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            <div>Lancer le traitement</div>
                        </Button>
                    </div>
                </div>
                <div style={{ padding: "0 3rem" }}>
                    {" "}
                    {dataSource && (
                        <>
                            {" "}
                            <DescriptionComponent
                                title="Resultat de l'extraction"
                                data={dataSource}
                            />
                            <div style={{ color: "white", marginTop: "3rem" }}>
                                <h2> Document traité</h2>
                                <Descriptions
                                    bordered
                                    column={1}
                                    className="descriptions-white"
                                >
                                    <Descriptions.Item label="Signature Extraite">
                                        <img
                                            style={{
                                                width: "100%",
                                                height: "20rem",
                                                borderRadius: "10px"
                                            }}
                                            src={`${baseUrl}${signature}`}
                                            alt="Signature"
                                            className="table-row-white"
                                        />
                                    </Descriptions.Item>
                                </Descriptions>
                                <h2 className="mt-4"></h2>
                                <Table
                                    columns={columns}
                                    dataSource={data}
                                    pagination={false}
                                    bordered
                                    className="custom-table"
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default MoyensPaiment;
