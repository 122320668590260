import { replace } from "connected-react-router";
import { connect } from "react-redux";
import { ROOT } from "../../constants/routes";
import Page404 from "../../components/ErrorPages/Page404";

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    goToHome: () => dispatch(replace(ROOT))
});

export default connect(mapStateToProps, mapDispatchToProps)(Page404);
