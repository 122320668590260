import { push, replace } from "connected-react-router";
import { connect } from "react-redux";
import { ROOT } from "../../constants/routes";
import SalesAdvisorOverview from "../../components/Tandem/SalesAdvisor/SalesAdvisorOverview";

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    goToUrl: url => dispatch(push(url))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SalesAdvisorOverview);
