import React, { useEffect, useState } from "react";
import rocket from "../Assets/rocket-2766.png";
import logo from "../Assets/TendemLogo.png";
import { Upload, Input, Button, List } from "antd";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { toast } from "react-toastify";

const { TextArea } = Input;

const SmartAnalysisFile = ({
    SMART_ANALYSIS_ETAPE,
    setShowEtape,
    isLoading,
    sendCustomFile,
    custoFileSended,
    clearCustomFile
}) => {
    const [file, setFile] = useState(null);
    const [extractionPerso, setExtractionPerso] = useState(null);
    const [response, setResponse] = useState(null);

    useEffect(() => {
        return () => {
            clearCustomFile();
        };
    }, []);

    useEffect(() => {
        if (custoFileSended && custoFileSended.success) {
            let copy = custoFileSended.response;
            copy = parseResponse(copy);
            setResponse(copy);
        } else if (custoFileSended && !custoFileSended.success) {
            toast.error("Une erreur est survenue!");
        }
    }, [custoFileSended]);

    const parseResponse = responseText => {
        const lines = responseText.split("\n");
        return lines;
    };
    return (
        <>
            <div className="tandem">
                <div className="tandem-logo">
                    <img src={logo} alt="Tandem" style={{ width: "350px" }} />
                </div>
                <div className="autre-document">
                    {" "}
                    <div
                        className="tandem-go-back-svc bg-none w-1"
                        onClick={() =>
                            setShowEtape(SMART_ANALYSIS_ETAPE.OVERVIEW)
                        }
                    >
                        <KeyboardBackspaceIcon fontSize={"small"} />{" "}
                        <span>Retour</span>{" "}
                    </div>
                    <div className="autre-document-title">
                        <img src={rocket} alt="" className="icon" />
                        <h1>Autres documents</h1>
                    </div>
                    <div className="autre-document-text">
                        Charger votre document:
                    </div>
                    <div className="autre-doc-input mt-4">
                        <label id="upload">Tout type de document</label>
                        <Upload
                            maxCount={1}
                            accept=".pdf"
                            style={{ width: "100%" }}
                            fileList={file ? [file] : []}
                            showUploadList={false}
                            beforeUpload={file => {
                                const isPDF = file.type === "application/pdf";
                                if (!isPDF)
                                    toast.warn(
                                        "Merci de choisir un ficher pdf"
                                    );
                                return false;
                            }}
                            onChange={f => {
                                if (f.file.type === "application/pdf") {
                                    setFile(f.file);
                                }
                            }}
                            onRemove={() => setFile(null)}
                        >
                            <Button
                                style={{
                                    width: "100%",
                                    height: "70px",
                                    backgroundColor: "#262730",
                                    borderColor: "#262730",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    color: "white"
                                }}
                                icon={<CloudUploadOutlinedIcon />}
                            >
                                {file ? file.name : "Chargez un fichier (.pdf)"}
                            </Button>
                        </Upload>
                    </div>
                    <div className="autre-doc-input mt-3">
                        <label style={{ color: "grey" }}>
                            Personnaliser votre extraction
                        </label>
                        <TextArea
                            style={{
                                backgroundColor: "#262730",
                                borderColor: "#262730",
                                color: "white"
                            }}
                            rows={3}
                            placeholder="Quel est le RIB du fournisseur ?"
                            onChange={e => {
                                setExtractionPerso(e.target.value);
                            }}
                        />
                    </div>
                    <div className="button ">
                        <Button
                            className="doc-button h-75"
                            onClick={() => {
                                let formData = new FormData();
                                formData.append("file", file);
                                formData.append("prompt", extractionPerso);
                                sendCustomFile(formData);
                            }}
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            <div>Lancer le traitement</div>
                        </Button>
                    </div>
                    <div style={{ color: "white", marginTop: "2rem" }}>
                        {" "}
                        {response && (
                            <>
                                {" "}
                                <h2>Resultat de l'extraction</h2>{" "}
                                <List
                                    bordered
                                    dataSource={response}
                                    renderItem={(item, index) => (
                                        <List.Item
                                            style={{
                                                color: "white"
                                            }}
                                            className={
                                                index % 2 === 0
                                                    ? "table-row-light"
                                                    : "table-row-white"
                                            }
                                        >
                                            {item}
                                        </List.Item>
                                    )}
                                    style={{
                                        backgroundColor: "#1e1f27",
                                        color: "white",
                                        border: "3px solid #262730"
                                    }} // Add custom styles if needed
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SmartAnalysisFile;
