import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LoadingPage from "../../Common/LoadingPage";
import { ROOT } from "../../../constants/routes";

const AuthHandler = ({
    goToUrl,
    authWithToken,
    informations,
    goToMonEspace
}) => {
    const location = useLocation();

    useEffect(() => {
        const getTokenFromUrl = () => {
            const params = new URLSearchParams(location.search);
            return params.get("code");
        };

        const code = getTokenFromUrl();
        if (code) {
            authWithToken(code);
        } else {
            goToUrl(ROOT);
        }
    }, []);

    useEffect(() => {
        if (informations && informations.success) {
            if (informations.profil && informations.profil === "user") {
                goToMonEspace(
                    informations.numAffilieNumPensionAttakmili,
                    informations?.clientDto?.position
                );
            }
        } else if (informations && !informations.success) {
            goToUrl(`${ROOT}#login`);
        }
    }, [informations]);

    return <LoadingPage />;
};

export default AuthHandler;
