import React from "react";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import logo from "../../assets/icons/logo.png";

export default function LoadingPage() {
    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "2rem"
            }}
        >
            <img
                src={logo}
                alt="this is logo of cmr company"
                className="navbar-logo"
            />
            <Box sx={{ width: "20%" }}>
                <LinearProgress />
            </Box>
        </div>
    );
}
