import React, { useEffect, useState } from "react";
import rocket from "../Assets/rocket-2766.png";
import logo from "../Assets/TendemLogo.png";
import { Upload, Input, Button, List } from "antd";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { toast } from "react-toastify";
import { TANDEM_OVERVIEW } from "../../../constants/routes";

const { TextArea } = Input;

/* const questionSended = {
        response:
            "La dur\u00e9e moyenne des projets de marketing est de 18,5 jours.",
        success: true
    }; */

const ChatDataOverview = ({
    goToUrl,
    isLoading,
    sendDataChat,
    questionSended,
    clearDataChat
}) => {
    const [file, setFile] = useState(null);
    const [question, setQuestion] = useState(null);
    const [response, setResponse] = useState(null);

    useEffect(() => {
        return () => {
            clearDataChat();
        };
    }, []);

    useEffect(() => {
        if (questionSended && questionSended.success) {
            let copy = questionSended.response;
            copy = parseResponse(copy);
            setResponse(copy);
        } else if (questionSended && !questionSended.success) {
            toast.error("Une erreur est survenue!");
        }
    }, [questionSended]);

    const parseResponse = responseText => {
        const lines = responseText.split("\n");
        return lines;
    };

    return (
        <>
            <div className="tandem">
                <div className="tandem-logo">
                    <img src={logo} alt="Tandem" style={{ width: "350px" }} />
                </div>
                <div className="autre-document">
                    {" "}
                    <div
                        className="tandem-go-back-svc bg-none w-1"
                        onClick={() => goToUrl(TANDEM_OVERVIEW)}
                    >
                        <KeyboardBackspaceIcon fontSize={"small"} />{" "}
                        <span>Retour</span>{" "}
                    </div>
                    <div className="autre-document-title">
                        <img src={rocket} alt="" className="icon" />
                        <h1>Discutez et explorez vos données</h1>
                    </div>
                    <div className="autre-document-text">
                        Charger votre source de données:
                    </div>
                    <div className="autre-doc-input mt-4">
                        <label id="upload" style={{ color: "grey" }}>
                            {" "}
                            (csv, xls, xlsx, xlsm, xlsb)
                        </label>
                        <Upload
                            maxCount={1}
                            accept=".csv, .xls, .xlsx, .xlsm, .xlsb"
                            style={{ width: "100%" }}
                            fileList={file ? [file] : []}
                            showUploadList={false}
                            beforeUpload={file => {
                                const isAcceptedFormat =
                                    file.type === "text/csv" ||
                                    file.type === "application/vnd.ms-excel" ||
                                    file.type ===
                                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                    file.type ===
                                        "application/vnd.ms-excel.sheet.macroEnabled.12" ||
                                    file.type ===
                                        "application/vnd.ms-excel.sheet.binary.macroEnabled";

                                if (!isAcceptedFormat) {
                                    toast.warn(
                                        "Merci de choisir un fichier CSV ou Excel"
                                    );
                                }

                                return false;
                            }}
                            onChange={f => {
                                const isAcceptedFormat =
                                    f.file.type === "text/csv" ||
                                    f.file.type ===
                                        "application/vnd.ms-excel" ||
                                    f.file.type ===
                                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                    f.file.type ===
                                        "application/vnd.ms-excel.sheet.macroEnabled.12" ||
                                    f.file.type ===
                                        "application/vnd.ms-excel.sheet.binary.macroEnabled";
                                if (isAcceptedFormat) {
                                    setFile(f.file);
                                }
                            }}
                            onRemove={() => setFile(null)}
                        >
                            <Button
                                style={{
                                    width: "100%",
                                    height: "70px",
                                    backgroundColor: "#262730",
                                    borderColor: "#262730",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    color: "white"
                                }}
                                icon={<CloudUploadOutlinedIcon />}
                            >
                                {file ? file.name : "Chargez un fichier"}
                            </Button>
                        </Upload>
                    </div>
                    <div className="autre-doc-input mt-3">
                        <label style={{ color: "grey" }}>
                            Personnaliser votre extraction
                        </label>
                        <TextArea
                            style={{
                                backgroundColor: "#262730",
                                borderColor: "#262730",
                                color: "white"
                            }}
                            rows={3}
                            placeholder="Posez votre question ..."
                            onChange={e => {
                                setQuestion(e.target.value);
                            }}
                        />
                    </div>
                    <div className="button ">
                        <Button
                            className="doc-button h-75"
                            onClick={() => {
                                if (file && question) {
                                    let formData = new FormData();
                                    formData.append("file", file);
                                    formData.append("prompt", question);
                                    sendDataChat(formData);
                                } else {
                                    toast.error(
                                        "Veuillez remplir tout les champs!"
                                    );
                                }
                            }}
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            <div>Lancer le traitement</div>
                        </Button>
                    </div>
                    <div style={{ color: "white", marginTop: "2rem" }}>
                        {" "}
                        {response && (
                            <>
                                {" "}
                                <h2>Réponse :</h2>{" "}
                                <List
                                    bordered
                                    dataSource={response}
                                    renderItem={(item, index) => (
                                        <List.Item
                                            style={{
                                                color: "white"
                                            }}
                                            className={
                                                index % 2 === 0
                                                    ? "table-row-light"
                                                    : "table-row-white"
                                            }
                                        >
                                            {item}
                                        </List.Item>
                                    )}
                                    style={{
                                        backgroundColor: "#1e1f27",
                                        color: "white",
                                        border: "3px solid #262730"
                                    }} // Add custom styles if needed
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChatDataOverview;
