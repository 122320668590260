export const SaveIcon = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V7.99999C21.0008 7.86838 20.9755 7.73792 20.9258 7.61608C20.876 7.49424 20.8027 7.38343 20.71 7.28999L16.71 3.28999C16.6166 3.1973 16.5057 3.12398 16.3839 3.07421C16.2621 3.02445 16.1316 2.99923 16 2.99999H5C4.46957 2.99999 3.96086 3.2107 3.58579 3.58577C3.21071 3.96085 3 4.46955 3 4.99999V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21ZM15 19H9V14H15V19ZM13 6.99999H11V4.99999H13V6.99999ZM5 4.99999H7V8.99999H15V4.99999H15.59L19 8.40999V19H17V14C17 13.4696 16.7893 12.9608 16.4142 12.5858C16.0391 12.2107 15.5304 12 15 12H9C8.46957 12 7.96086 12.2107 7.58579 12.5858C7.21071 12.9608 7 13.4696 7 14V19H5V4.99999Z"
            fill="currentColor"
        />
    </svg>
);
export const IconNotFound = () => (
    <svg
        width="211"
        height="211"
        viewBox="0 0 211 211"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            opacity="0.6"
            d="M60.1711 2.51389C61.4009 1.28712 63.067 0.598025 64.8042 0.597656L146.362 0.597656C148.099 0.598025 149.766 1.28712 150.995 2.51389L208.666 60.1847C209.893 61.4146 210.582 63.0807 210.582 64.8178V146.376C210.582 148.113 209.893 149.779 208.666 151.009L150.995 208.68C149.766 209.907 148.099 210.596 146.362 210.596H64.8042C63.067 210.596 61.4009 209.907 60.1711 208.68L2.50022 151.009C1.27345 149.779 0.584353 148.113 0.583984 146.376L0.583984 64.8178C0.584353 63.0807 1.27345 61.4146 2.50022 60.1847L60.1711 2.51389ZM67.521 13.7226L13.7089 67.5347L13.7089 143.659L67.521 197.471H143.645L197.458 143.659V67.5347L143.645 13.7226L67.521 13.7226Z"
            fill="#046DB2"
        />
        <path
            opacity="0.7"
            d="M92.4844 144.971C92.4844 143.248 92.8239 141.541 93.4834 139.949C94.143 138.356 95.1098 136.909 96.3286 135.691C97.5473 134.472 98.9942 133.505 100.587 132.845C102.179 132.186 103.886 131.846 105.609 131.846C107.333 131.846 109.04 132.186 110.632 132.845C112.224 133.505 113.671 134.472 114.89 135.691C116.109 136.909 117.076 138.356 117.735 139.949C118.395 141.541 118.734 143.248 118.734 144.971C118.734 148.452 117.351 151.791 114.89 154.252C112.429 156.713 109.09 158.096 105.609 158.096C102.128 158.096 98.79 156.713 96.3286 154.252C93.8672 151.791 92.4844 148.452 92.4844 144.971V144.971ZM93.7706 66.1562C93.5958 64.5003 93.7711 62.8263 94.2851 61.2426C94.7991 59.6589 95.6403 58.2009 96.7542 56.9633C97.868 55.7257 99.2296 54.7361 100.751 54.0587C102.272 53.3813 103.918 53.0313 105.583 53.0312C107.248 53.0312 108.894 53.3813 110.415 54.0587C111.936 54.7361 113.298 55.7257 114.412 56.9633C115.526 58.2009 116.367 59.6589 116.881 61.2426C117.395 62.8263 117.57 64.5003 117.395 66.1562L112.802 112.185C112.647 113.993 111.82 115.678 110.483 116.905C109.147 118.133 107.398 118.814 105.583 118.814C103.768 118.814 102.019 118.133 100.683 116.905C99.346 115.678 98.5187 113.993 98.3643 112.185L93.7706 66.1562Z"
            fill="#FF9900"
        />
    </svg>
);
