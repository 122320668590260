import { combineReducers } from "redux";
import api from "./api";
import ui from "./ui";
import client from "./client";
import fournisseur from "./fournisseur";

export default combineReducers({
    api,
    ui,
    // data reducers go here
    client,
    fournisseur
});
