import React, { useState, useEffect } from "react";
import cgi_logo from "../Assets/immobilieIcone .png";
import volksWagen_logo from "../Assets/automobileIcone.png";
import logo from "../Assets/TendemLogo.png";
import { Button } from "antd";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import bot_logo from "../Assets/chatbotIcone.png";
import { TANDEM_OVERVIEW } from "../../../constants/routes";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ChatBotVolksWagen from "./ChatBot/ChatBotVolksWagen";
import ChatBotCGI from "./ChatBot/ChatBotCGI";

const SalesAdvisorOverview = ({ goToUrl }) => {
    const [showBotVW, setShowBotVW] = useState(false);
    const [showBotCGI, setShowBotCGI] = useState(false);
    const [disableBotVW, setDisableBotVW] = useState(false);
    const [disableBotCGI, setDisableBotCGI] = useState(false);

    const handleGoBack = () => {
        try {
            document.getElementById("bp-web-widget-container").remove();
        } catch (e) {}
        goToUrl(TANDEM_OVERVIEW);
    };

    return (
        <>
            <div className="tandem">
                <div className="tandem-logo">
                    <img src={logo} alt="Tandem" style={{ width: "350px" }} />
                </div>
                <div className="autre-document">
                    <div
                        className="tandem-go-back-svc bg-none w-1"
                        onClick={handleGoBack}
                    >
                        <KeyboardBackspaceIcon fontSize={"small"} />{" "}
                        <span>Retour</span>{" "}
                    </div>
                    <div className="autre-document-title">
                        <img src={bot_logo} alt="" className="icon" />
                        <h1>Nos commerciaux virtuels</h1>
                    </div>
                    <div className="tandem-cards">
                        <div
                            className="tandem-page2"
                            onClick={() => {
                                if (showBotVW) {
                                    setDisableBotVW(true);
                                    setTimeout(() => {
                                        setDisableBotVW(false);
                                        setShowBotVW(true);
                                    }, 0);
                                } else {
                                    setShowBotVW(true);
                                    setShowBotCGI(false);
                                }
                                try {
                                    document
                                        .getElementById(
                                            "bp-web-widget-container"
                                        )
                                        .remove();
                                } catch (e) {}
                            }}
                        >
                            <div className="tandem-card-page2">
                                <img
                                    src={volksWagen_logo}
                                    alt=""
                                    className="img-card-page2"
                                />
                            </div>
                            <div
                                className="tandem-card-text-page2"
                                style={{ fontWeight: "500", fontSize: "30px" }}
                            >
                                Automobile
                            </div>
                        </div>

                        <div
                            className="tandem-page2"
                            onClick={() => {
                                if (showBotCGI) {
                                    setDisableBotCGI(true);
                                    setTimeout(() => {
                                        setDisableBotCGI(false);
                                        setShowBotCGI(true);
                                    }, 0);
                                } else {
                                    setShowBotCGI(true);
                                    setShowBotVW(false);
                                }
                                try {
                                    document
                                        .getElementById(
                                            "bp-web-widget-container"
                                        )
                                        .remove();
                                } catch (e) {}
                            }}
                        >
                            <div className="tandem-card-page2">
                                <img
                                    src={cgi_logo}
                                    alt=""
                                    className="img-card-page2"
                                />
                            </div>
                            <div
                                className="tandem-card-text-page2"
                                style={{ fontWeight: "500", fontSize: "30px" }}
                            >
                                Immobilier
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showBotVW && !disableBotVW && <ChatBotVolksWagen />}
            {showBotCGI && !disableBotCGI && <ChatBotCGI />}
        </>
    );
};

export default SalesAdvisorOverview;
