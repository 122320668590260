import React, { useState, useEffect } from "react";
import smartAnlysis from "../Tandem/Assets/noun-brain-noText-5265690.png";
import chatData from "../Tandem/Assets/bddIconeWhite.png";
import salesAdvisor from "../Tandem/Assets/noun-virtual-assistant-noText-6308440.png";
import logo from "../Tandem/Assets/TendemLogo.png";
import {
    SMART_ANALYSIS,
    CHAT_DATA,
    SALES_ADVISOR
} from "../../constants/routes";

const TandemOverview = ({ goToUrl }) => {
    const [showChatbot, setShowChatbot] = useState(false);

    useEffect(() => {
        if (showChatbot) {
            window.botpressWebChat.init({
                composerPlaceholder: "Chat with Volkswagen Maroc",
                botConversationDescription: "Bot Volkswagen",
                botId: "782e705f-91a6-45a4-b1ed-9cf84dece183",
                hostUrl: "https://cdn.botpress.cloud/webchat/v1",
                messagingUrl: "https://messaging.botpress.cloud",
                clientId: "782e705f-91a6-45a4-b1ed-9cf84dece183",
                webhookId: "c9641256-6b22-4196-9c05-c98aca37392d",
                lazySocket: true,
                themeName: "prism",
                botName: "Volkswagen Maroc",
                avatarUrl:
                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPS3JjGkmAp1oPlrq2gannzPc2wlNqh8Pyhq-U1CFzTQ&s",
                website: "https://www.volkswagen.ma/fr.html",
                frontendVersion: "v1",
                theme: "prism",
                themeColor: "#2563eb",
                allowedOrigins: []
            });
            window.botpressWebChat.sendEvent({
                type: "show"
            });
        }
    }, [showChatbot]);
    return (
        <>
            <div className="tandem">
                <div className="tandem-logo">
                    <img src={logo} alt="Tandem" style={{ width: "500px" }} />
                </div>

                <div className="tandem-title">
                    La plateforme qui analyse et automatise tous vos traitements
                    Back-office
                </div>
                <div className="tandem-cards" style={{ marginTop: "10rem" }}>
                    <div
                        className="tandem-card"
                        onClick={() => goToUrl(SMART_ANALYSIS)}
                    >
                        <img src={smartAnlysis} alt="" className="img-card" />
                        <h2>Smart Analysis</h2>
                    </div>
                    <div
                        className="tandem-card"
                        onClick={() => goToUrl(CHAT_DATA)}
                    >
                        <img
                            src={chatData}
                            alt="Chat with Data"
                            className="img-card"
                        />
                        <h2>Chat with Data</h2>
                    </div>
                    <div
                        className="tandem-card"
                        onClick={() => goToUrl(SALES_ADVISOR)}
                    >
                        <img
                            src={salesAdvisor}
                            alt="AI SalesAdvisor"
                            className="img-card"
                        />
                        <h2>AI SalesAdvisor</h2>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TandemOverview;
