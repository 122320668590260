import i18next from "i18next";
import common_ar from "./ar/common.json";
import landingPage_ar from "./ar/landingPage.json";
import inscription_ar from "./ar/inscription.json";
import espace_retraite_ar from "./ar/espace_retraite.json";
import espaceAffilie_ar from "./ar/espaceAffilie.json";
import actualites_ar from "./ar/all_actualites.json";
import common_fr from "./fr/common.json";
import landingPage_fr from "./fr/landingPage.json";
import inscription_fr from "./fr/inscription.json";
import espace_retraite_fr from "./fr/espace_retraite.json";
import espaceAffilie_fr from "./fr/espaceAffilie.json";
import actualites_fr from "./fr/all_actualites.json";
import actualitesDetails_fr from "./fr/actualitesDetails.json";
import actualitesDetails_ar from "./ar/actualitesDetails.json";
import attakmiliLP_ar from "./ar/attakmiliLP.json";
import attakmiliLP_fr from "./fr/attakmiliLP.json";
import innovation_fr from "./fr/innovation.json";
import innovation_ar from "./ar/innovation.json";
import textesJuridiques_fr from "./fr/textesJuridiques.json";
import textesJuridiques_ar from "./ar/textesJuridiques.json";
import presentation_fr from "./fr/presentation.json";
import presentation_ar from "./ar/presentation.json";
import chiffres_fr from "./fr/chiffres.json";
import chiffres_ar from "./ar/chiffres.json";
import contacter_fr from "./fr/contacter.json";
import contacter_ar from "./ar/contacter.json";
import mention_legales_fr from "./fr/mention-legales.json";
import mention_legales_ar from "./ar/mention-legales.json";
import RH_presentation_fr from "./fr/RH_presentation.json";
import presentation_attakmili_fr from "./fr/presentationAttakmili.json";

import parcoursRetraite_fr from "./fr/parcoursRetraite.json";
import parcoursRetraite_ar from "./ar/parcoursRetraite.json";

import ayantCauseServices_fr from "./fr/ayantCauseServices.json";
import ayantCauseServices_ar from "./ar/ayantCauseServices.json";

i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    lng: localStorage.getItem("i18nextLng") || "fr", // language to use
    resources: {
        ar: {
            common: common_ar, // 'common' is our custom namespace
            landingPage: landingPage_ar,
            inscription: inscription_ar,
            espace_retraite: espace_retraite_ar,
            espace_affilie: espaceAffilie_ar,
            all_actualites: actualites_ar,
            actualites_details: actualitesDetails_ar,
            attakmiliLP: attakmiliLP_ar,
            innovation: innovation_ar,
            textesJuridiques: textesJuridiques_ar,
            presentation: presentation_ar,
            chiffres: chiffres_ar,
            contacter: contacter_ar,
            mention_legales: mention_legales_ar,
            parcoursRetraite: parcoursRetraite_ar,
            ayantCauseServices: ayantCauseServices_ar
        },
        fr: {
            common: common_fr,
            landingPage: landingPage_fr,
            inscription: inscription_fr,
            espace_retraite: espace_retraite_fr,
            espace_affilie: espaceAffilie_fr,
            all_actualites: actualites_fr,
            actualites_details: actualitesDetails_fr,
            attakmiliLP: attakmiliLP_fr,
            innovation: innovation_fr,
            textesJuridiques: textesJuridiques_fr,
            presentation: presentation_fr,
            chiffres: chiffres_fr,
            contacter: contacter_fr,
            mention_legales: mention_legales_fr,
            RH_presentation: RH_presentation_fr,
            presentation_attakmili: presentation_attakmili_fr,
            parcoursRetraite: parcoursRetraite_fr,
            ayantCauseServices: ayantCauseServices_fr
        }
    }
});

export default i18next;
