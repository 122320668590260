import { ArrowLeftOutlined } from "@ant-design/icons";
import React from "react";
import { IconNotFound } from "../../assets/icons";
import logo from "../../assets/images/logoConstruction.png";
import logoConstruction from "../../assets/images/maintenance.svg";

const UnderConstruction = ({ goToHome }) => {
    return (
        <div className="page-under-construction">
            {" "}
            <div className="container">
                <div className="logo-placeholder">
                    <img src={logo} alt="Logo" />
                </div>
                <div className="message">
                    <h1>Service bientôt disponible</h1>
                    <p>
                        La Caisse Marocaine de la Retraite vous informe que ce
                        service est en cours de reconstruction.
                    </p>
                    <p>
                        Nous nous efforçons de rétablir l'accès dans les plus
                        brefs délais.
                    </p>
                    <p>Merci pour votre compréhension.</p>
                </div>
                <div className="image-container">
                    <img src={logoConstruction} alt="Maintenance" />
                </div>
                <div onClick={goToHome} className="page-error--btn">
                    <ArrowLeftOutlined />
                    &nbsp;{" "}
                    <span className="btn--content">Return to home page</span>
                </div>
            </div>
        </div>
    );
};

export default UnderConstruction;
