export const API = "API";
export const API_START = "API_START";
export const API_END = "API_END";
export const ACCESS_DENIED = "ACCESS_DENIED";
export const TOKEN_EXPIRED = "TOKEN_EXPIRED";
export const API_ERROR = "API_ERROR";
export const CLEAR_DECONNEXION = "CLEAR_DECONNEXION";

export const BACKEND_URL = "https://cmr.api.seven-app.org/eclient";
export const BACKEND_URL_ACHAT = "https://cmr.api.seven-app.org/eachat";
export const BACKEND_URL_R_HUMAINE = "https://cmr.api.seven-app.org/r-humaine";
/* export const BACKEND_URL = "http://172.17.43.10/c";
export const BACKEND_URL_ACHAT = "http://172.17.43.10/f";
export const BACKEND_URL_R_HUMAINE = "http://172.17.43.10/rh";  */

export const SEND_AUTH = "SEND_AUTH";
export const SEND_AUTH_TOKEN = "SEND_AUTH_TOKEN";
export const SET_AUTH = "SET_AUTH";

export const GET_DETAILS_CLIENT = "GET_DETAILS_CLIENT";
export const SET_GET_DETAILS_CLIENT = "SET_GET_DETAILS_CLIENT";

export const ADD_INSCRIPTION = "ADD_INSCRIPTION";
export const SET_ADD_INSCRIPTION = "SET_ADD_INSCRIPTION";
export const CLEAR_ADD_INSCRIPTION = "CLEAR_ADD_INSCRIPTION";

export const VERIFY_OTP = "VERIFY_OTP";
export const SET_VERIFY_OTP = "SET_VERIFY_OTP";
export const CLEAR_VERIFY_OTP = "CLEAR_VERIFY_OTP";

export const CONFIGURE_MDP = "CONFIGURE_MDP";
export const SET_CONFIGURE_MDP = "SET_CONFIGURE_MDP";
export const CLEAR_CONFIGURE_MDP = "CLEAR_CONFIGURE_MDP";

export const FORGET_MDP = "FORGET_MDP";
export const SET_FORGET_MDP = "SET_FORGET_MDP";
export const CLEAR_FORGET_MDP = "CLEAR_FORGET_MDP";

export const CHANGE_MDP = "CHANGE_MDP";
export const SET_CHANGE_MDP = "SET_CHANGE_MDP";
export const CLEAR_CHANGE_MDP = "CLEAR_CHANGE_MDP";

export const RESEND_OTP = "RESEND_OTP";
export const SET_RESEND_OTP = "SET_RESEND_OTP";
export const CLEAR_RESEND_OTP = "CLEAR_RESEND_OTP";

export const SEND_ALL_PRESTATIONS = "SEND_ALL_PRESTATIONS";
export const SET_ALL_PRESTATIONS = "SET_ALL_PRESTATIONS";

export const SEND_ALL_PRESTATIONS_2 = "SEND_ALL_PRESTATIONS_2";
export const SET_ALL_PRESTATIONS_2 = "SET_ALL_PRESTATIONS_2";

export const SEND_PRESTATION_BY_ID = "SEND_PRESTATION_BY_ID";
export const SET_PRESTATION_BY_ID = "SET_PRESTATION_BY_ID";
export const SEND_LIST_ACTUALITES = "SEND_LIST_ACTUALITES";
export const SET_LIST_ACTUALITES = "SET_LIST_ACTUALITES";

export const SEND_LIST_ACTUALITES_ENABLED = "SEND_LIST_ACTUALITES_ENABLED";
export const SET_LIST_ACTUALITES_ENABLED = "SET_LIST_ACTUALITES_ENABLED";

export const SEND_LIST_FAQ = "SEND_LIST_FAQ";
export const SET_LIST_FAQ = "SET_LIST_FAQ";

export const SEND_OTP_MAIL = "SEND_OTP_MAIL";
export const SET_OTP_MAIL = "SET_OTP_MAIL";
export const CLEAR_OTP_MAIL = "CLEAR_OTP_MAIL";

export const SEND_OTP_PHONE = "SEND_OTP_PHONE";
export const SET_OTP_PHONE = "SET_OTP_PHONE";
export const CLEAR_OTP_PHONE = "CLEAR_OTP_PHONE";

export const SEND_UPDATE_MAIL_PHONE = "SEND_UPDATE_MAIL_PHONE";
export const SET_UPDATE_MAIL_PHONE = "SET_UPDATE_MAIL_PHONE";

export const SEND_CHANGE_PWD = "SEND_CHANGE_PWD";
export const SET_CHANGE_PWD = "SET_CHANGE_PWD";
export const CLEAR_CHANGE_PWD = "CLEAR_CHANGE_PWD";

export const SEND_SIMULATION = "SEND_SIMULATION";
export const SET_SIMULATION = "SET_SIMULATION";

export const SEND_PRESTATIONS = "SEND_PRESTATIONS";
export const SET_PRESTATIONS = "SET_PRESTATIONS";

export const SEND_PRESTATION = "SEND_PRESTATION";
export const SET_PRESTATION = "SET_PRESTATION";
export const CLEAR_PRESTATION = "CLEAR_PRESTATION";

export const ADD_PIECE = "ADD_PIECE";
export const SET_ADD_PIECE = "SET_ADD_PIECE";
export const CLEAR_ADD_PIECE = "CLEAR_ADD_PIECE";

export const SEND_PREST_DEMANDE = "SEND_PREST_DEMANDE";
export const SET_PREST_DEMANDE = "SET_PREST_DEMANDE";

export const SEND_SITUATION = "SEND_SITUATION";
export const SET_SITUATION = "SET_SITUATION";

export const SEND_PENSION_DONNEE = "SEND_PENSION_DONNEE";
export const SET_PENSION_DONNEE = "SET_PENSION_DONNEE";
export const SEND_PENSION_DETAIL = "SEND_PENSION_DETAIL";
export const SET_PENSION_DETAIL = "SET_PENSION_DETAIL";
export const SET_PEN_PAIMENT_DETAIL = "SET_PEN_PAIMENT_DETAIL";
export const SEND_PEN_PAIMENT_DETAIL = "SEND_PEN_PAIMENT_DETAIL";
export const SET_PEN_ENFANT_DETAIL = "SET_PEN_ENFANT_DETAIL";
export const SEND_PEN_ENFANT_DETAIL = "SEND_PEN_ENFANT_DETAIL";

export const SET_PEN_ATTAK_DETAIL = "SET_PEN_ATTAK_DETAIL";
export const SEND_PEN_ATTAK_DETAIL = "SEND_PEN_ATTAK_DETAIL";

export const SEND_PENSION_DEMANDES = "SEND_PENSION_DEMANDES";
export const SET_PENSION_DEMANDES = "SET_PENSION_DEMANDES";

export const SET_PENSION_TYPE = "SET_PENSION_TYPE";
export const CLEAR_LIST_PRESTATIONS = "CLEAR_LIST_PRESTATIONS";

export const SEND_PRESTATION_CONNECTED = "SEND_PRESTATION_CONNECTED";
export const SET_PRESTATION_CONNECTED = "SET_PRESTATION_CONNECTED";

export const ADD_PIECE_CONNECTED = "ADD_PIECE_CONNECTED";
export const SET_ADD_PIECE_CONNECTED = "SET_ADD_PIECE_CONNECTED";
export const CLEAR_ADD_PIECE_CONNECTED = "CLEAR_ADD_PIECE_CONNECTED";

export const VERIFY_OTP_PRESTATION = "VERIFY_OTP_PRESTATION";
export const SET_VERIFY_OTP_PRESTATION = "SET_VERIFY_OTP_PRESTATION";

export const SEND_OTP_PRESTATION = "SEND_OTP_PRESTATION";
export const SET_SEND_OTP_PRESTATION = "SET_SEND_OTP_PRESTATION";
export const SEND_VERIFY_TOKEN = "SEND_VERIFY_TOKEN";
export const SET_VERIFY_TOKEN = "SET_VERIFY_TOKEN";

export const SEND_VERIFY_TOKEN_FOURNISSEUR = "SEND_VERIFY_TOKEN_FOURNISSEUR";
export const SET_VERIFY_TOKEN_FOURNISSEUR = "SET_VERIFY_TOKEN_FOURNISSEUR";

export const SET_PRESTATION_NULL = "SET_PRESTATION_NULL";
export const SET_PRESTATION_BY_ID_NULL = "SET_PRESTATION_BY_ID_NULL";
export const SET_PJS = "SET_PJS";

export const DOWNLOAD_ATTESTATION = "DOWNLOAD_ATTESTATION";
export const SET_DOWNLOAD_ATTESTATION = "SET_DOWNLOAD_ATTESTATION";

export const CLEAR_OTP = "CLEAR_OTP";

export const SEND_DEMANDE_DETAILS = "SEND_DEMANDE_DETAILS";
export const SET_DEMANDE_DETAILS = "SET_DEMANDE_DETAILS";

export const SEND_DOCUMENTATIONS = "SEND_DOCUMENTATIONS";
export const SET_DOCUMENTATIONS = "SET_DOCUMENTATIONS";
export const SET_ZOOM = "SET_ZOOM";
export const SET_DISPLAYED_ADS = "SET_DISPLAYED_ADS";

export const SEND_SIMULATION_DATA = "SEND_SIMULATION_DATA";
export const SET_SIMULATION_DATA = "SET_SIMULATION_DATA";
export const SEND_MDP_RESET = "SEND_MDP_RESET";

export const CHANGE_CANAL = "CHANGE_CANAL";
export const SET_CHANGE_CANAL = "SET_CHANGE_CANAL";
export const SET_CANAL_NULL = "SET_CANAL_NULL";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const SET_CHANGE_PASSWORD = "SET_CHANGE_PASSWORD";
export const SET_PASSWORD_NULL = "SET_PASSWORD_NULL";

export const CHANGE_DATA = "CHANGE_DATA";
export const SET_CHANGE_DATA = "SET_CHANGE_DATA";
export const CLEAR_CHANGE_DATA = "CLEAR_CHANGE_DATA";

export const SEND_CONTACT = "SEND_CONTACT";
export const SET_CONTACT = "SET_CONTACT";
export const CLEAR_CONTACT = "CLEAR_CONTACT";

export const FORGOT_PWD = "FORGOT_PWD";
export const SET_FORGOT_PWD = "SET_FORGOT_PWD";

export const NEW_PWD = "NEW_PWD";
export const SET_NEW_PWD = "SET_NEW_PWD";

export const ADD_WORKFLOW = "ADD_WORKFLOW";
export const SET_ADD_WORKFLOW = "SET_ADD_WORKFLOW";

export const GET_VIDEOS_EVENTS = "GET_VIDEOS_EVENTS";
export const SET_VIDEOS_EVENTS = "SET_VIDEOS_EVENTS";

export const ADD_WORKFLOW_DISCONNECTED = "ADD_WORKFLOW_DISCONNECTED";
export const SET_ADD_WORKFLOW_DISCONNECTED = "SET_ADD_WORKFLOW_DISCONNECTED";

export const INSCRIPTION_FOURNISSEUR = "INSCRIPTION_FOURNISSEUR"; //isLoadingData_INSCRIPTION_FOURNISSEUR
export const SET_INSCRIPTION_FOURNISSEUR = "SET_INSCRIPTION_FOURNISSEUR"; //data_inscription_fournisseur
export const CLEAR_INSCRIPTION_FOURNISSEUR = "CLEAR_INSCRIPTION_FOURNISSEUR";

export const INSCRIPTION_FOURNISSEUR_FILE = "INSCRIPTION_FOURNISSEUR_FILE";
export const SET_INSCRIPTION_FOURNISSEUR_FILE =
    "SET_INSCRIPTION_FOURNISSEUR_FILE";
export const CLEAR_INSCRIPTION_FOURNISSEUR_FILE =
    "CLEAR_INSCRIPTION_FOURNISSEUR_FILE";

export const CONFIGURE_MDP_FOURNISSEUR = "CONFIGURE_MDP_FOURNISSEUR";
export const SET_CONFIGURE_MDP_FOURNISSEUR = "SET_CONFIGURE_MDP_FOURNISSEUR";
export const CLEAR_CONFIGURE_MDP_FOURNISSEUR =
    "CLEAR_CONFIGURE_MDP_FOURNISSEUR";

export const GET_APPELS_OFFRE = "GET_APPELS_OFFRE";
export const SET_APPELS_OFFRE = "SET_APPELS_OFFRE";
export const CLEAR_GET_APPELS_OFFRE = "CLEAR_GET_APPELS_OFFRE";

export const SEND_AUTH_FOURNISSEUR = "SEND_AUTH_FOURNISSEUR";
export const SET_AUTH_FOURNISSEUR = "SET_AUTH_FOURNISSEUR";

export const GET_MARCHE = "GET_MARCHE";
export const SET_MARCHE = "SET_MARCHE";

export const UPLOAD_FACTURE = "UPLOAD_FACTURE";
export const SET_UPLOAD_FACTURE = "SET_UPLOAD_FACTURE";
export const CLEAR_UPLOAD_FACTURE = "CLEAR_UPLOAD_FACTURE";

export const CHECK_AUTHENTICITE = "CHECK_AUTHENTICITE";
export const SET_CHECK_AUTHENTICITE = "SET_CHECK_AUTHENTICITE";
export const CLEAR_CHECK_AUTHENTICITE = "CLEAR_CHECK_AUTHENTICITE";

export const GET_NUM_AFFILIATION = "GET_NUM_AFFILIATION";
export const SET_NUM_AFFILIATION = "SET_NUM_AFFILIATION";
export const CLEAR_NUM_AFFILIATION = "CLEAR_NUM_AFFILIATION";

export const GET_APPELS_OFFRE_NC = "GET_APPELS_OFFRE_NC";
export const SET_APPELS_OFFRE_NC = "SET_APPELS_OFFRE_NC";
export const CLEAR_APPELS_OFFRE_NC = "CLEAR_APPELS_OFFRE_NC";

export const GET_FACTURE_FOURNISSEUR = "GET_FACTURE_FOURNISSEUR";
export const SET_FACTURE_FOURNISSEUR = "SET_FACTURE_FOURNISSEUR";
export const SEND_UPDATE_MAIL = "SEND_UPDATE_MAIL";
export const SET_UPDATE_MAIL = "SET_UPDATE_MAIL";
export const SEND_UPDATE_PHONE = "SEND_UPDATE_PHONE";
export const SET_UPDATE_PHONE = "SET_UPDATE_PHONE";
export const GET_ATTAKMILI_INFO = "GET_ATTAKMILI_INFO";
export const SET_ATTAKMILI_INFO = "SET_ATTAKMILI_INFO";

export const SEND_RESET_PASSWORD = "SEND_RESET_PASSWORD";
export const SET_RESET_PASSWORD = "SET_RESET_PASSWORD";
export const CLEAR_RESET_PASSWORD = "CLEAR_RESET_PASSWORD";

export const SEND_APPELS_OFFRES = "SEND_APPELS_OFFRES";
export const SET_APPELS_OFFRES = "SET_APPELS_OFFRES";

export const SEND_APPELS_OFFRES_PUBLIC = "SEND_APPELS_OFFRES_PUBLIC";
export const SET_APPELS_OFFRES_PUBLIC = "SET_APPELS_OFFRES_PUBLIC";

export const SEND_APPEL_OFFRE_QUESTIONS = "SEND_APPEL_OFFRE_QUESTIONS";
export const SET_APPEL_OFFRE_QUESTIONS = "SET_APPEL_OFFRE_QUESTIONS";

export const SEND_ANSWER_QUESTIONNAIRE = "SEND_ANSWER_QUESTIONNAIRE";
export const SET_ANSWER_QUESTIONNAIRE = "SET_ANSWER_QUESTIONNAIRE";
export const CLEAR_ANSWER = "CLEAR_ANSWER";

export const GET_NOTIF = "GET_NOTIF";
export const SET_NOTIF = "SET_NOTIF";

export const GET_CONSULTATION_DONNEES = "GET_CONSULTATION_DONNEES";
export const SET_CONSULTATION_DONNEES = "SET_CONSULTATION_DONNEES";

export const SEND_SOUSCRIPTION_ATTAKMILI = "SEND_SOUSCRIPTION_ATTAKMILI";
export const SET_SOUSCRIPTION_ATTAKMILI = "SET_SOUSCRIPTION_ATTAKMILI";
export const CLEAR_SOUSCRIPTION_ATTAKMILI = "CLEAR_SOUSCRIPTION_ATTAKMILI";

export const SET_ID_DEMANDE_ERSAL = "SET_ID_DEMANDE_ERSAL";

export const DELETE_BENEF = "DELETE_BENEF";
export const SET_DELETE_BENEF = "SET_DELETE_BENEF";

export const UPLOAD_PJ = "UPLOAD_PJ";
export const SET_UPLOAD_PJ = "SET_UPLOAD_PJ";
export const CLEAR_UPLOAD_PJ = "CLEAR_UPLOAD_PJ";

export const SYNCHRO_ATTAKMILI = "SYNCHRO_ATTAKMILI";
export const SET_SYNCHRO_ATTAKMILI = "SET_SYNCHRO_ATTAKMILI";

export const SYNCHRO_ATTAKMILI_LIQUIDATION = "SYNCHRO_ATTAKMILI_LIQUIDATION";
export const SET_SYNCHRO_ATTAKMILI_LIQUIDATION =
    "SET_SYNCHRO_ATTAKMILI_LIQUIDATION";

export const MODIF_DONNEE = "MODIF_DONNEE";
export const SET_MODIF_DONNEE = "SET_MODIF_DONNEE";
export const CLEAR_MODIF_DONNEE = "CLEAR_MODIF_DONNEE";

export const SET_ID_SOUSCRIPTION = "SET_ID_SOUSCRIPTION";

export const VALIDER_SOUSCRIPTION = "VALIDER_SOUSCRIPTION";
export const SET_VALIDER_SOUSCRIPTION = "SET_VALIDER_SOUSCRIPTION";
export const CLEAR_VALIDER_SOUSCRIPTION = "CLEAR_VALIDER_SOUSCRIPTION";

export const GET_SOUSCRIPTION_STATUS = "GET_SOUSCRIPTION_STATUS";
export const SET_SOUSCRIPTION_STATUS = "SET_SOUSCRIPTION_STATUS";

export const GET_PENS_DONNEES = "GET_PENS_DONNEES";
export const SET_PENS_DONNEES = "SET_PENS_DONNEES";

export const SEND_VERSEMENT = "SEND_VERSEMENT";
export const SET_VERSEMENT = "SET_VERSEMENT";
export const CLEAR_VERSEMENT = "CLEAR_VERSEMENT";

export const DEMANDE_INTERRUPTION = "DEMANDE_INTERRUPTION";
export const SET_DEMANDE_INTERRUPTION = "SET_DEMANDE_INTERRUPTION";
export const CLEAR_DEMANDE_INTERRUPTION = "CLEAR_DEMANDE_INTERRUPTION";

export const DEMANDE_REPRISE = "DEMANDE_REPRISE";
export const SET_DEMANDE_REPRISE = "SET_DEMANDE_REPRISE";
export const CLEAR_DEMANDE_REPRISE = "CLEAR_DEMANDE_REPRISE";

export const VALIDER_INTERRUPTION = "VALIDER_INTERRUPTION";
export const SET_VALIDER_INTERRUPTION = "SET_VALIDER_INTERRUPTION";
export const CLEAR_VALIDER_INTERRUPTION = "CLEAR_VALIDER_INTERRUPTION";

export const VALIDER_REPRISE = "VALIDER_REPRISE";
export const SET_VALIDER_REPRISE = "SET_VALIDER_REPRISE";
export const CLEAR_VALIDER_REPRISE = "CLEAR_VALIDER_REPRISE";

export const METIER_VER = "METIER_VER";
export const SET_METIER_VER = "SET_METIER_VER";
export const CLEAR_METIER_VER = "CLEAR_METIER_VER";

export const GET_SLIDERS = "GET_SLIDERS";
export const SET_SLIDERS = "SET_SLIDERS";

export const GET_PIECES = "GET_PIECES";
export const SET_PIECES = "SET_PIECES";

export const GET_PHOTOS = "GET_PHOTOS";
export const SET_PHOTOS = "SET_PHOTOS";

export const GET_APPELS_FOURNISSEUR = "GET_APPELS_FOURNISSEUR";
export const SET_APPELS_FOURNISSEUR = "SET_APPELS_FOURNISSEUR";

export const GET_FACTURE_APPEL = "GET_FACTURE_APPEL";
export const SET_FACTURE_APPEL = "SET_FACTURE_APPEL";

export const ADD_FACTURE = "ADD_FACTURE";
export const SET_FACTURE = "SET_FACTURE";
export const CLEAR_ADD_FACTURE = "CLEAR_ADD_FACTURE";

export const ADD_RECLAMATION = "ADD_RECLAMATION";
export const SET_RECLAMATION = "SET_RECLAMATION";
export const CLEAR_RECLAMATION = "CLEAR_RECLAMATION";

export const GET_RECLAMATION = "GET_RECLAMATION";
export const SET_GET_RECLAMATION = "SET_GET_RECLAMATION";

export const SEND_CANDIDATURE_SPONTANEE = "SEND_CANDIDATURE_SPONTANEE";
export const SET_CANDIDATURE_SPONTANEE = "SET_CANDIDATURE_SPONTANEE";

export const SEND_CANDIDATURE_SPONTANEE_FILE =
    "SEND_CANDIDATURE_SPONTANEE_FILE";
export const SET_CANDIDATURE_SPONTANEE_FILE = "SET_CANDIDATURE_SPONTANEE_FILE";

export const SEND_APPLICATION_FILE = "SEND_APPLICATION_FILE";
export const SET_APPLICATION_FILE = "SET_APPLICATION_FILE";

export const CLEAR_CANDIDATURE_SPONTANEE = "CLEAR_CANDIDATURE_SPONTANEE";
export const CLEAR_CANDIDATURE_SPONTANEE_FILE =
    "CLEAR_CANDIDATURE_SPONTANEE_FILE";

export const CLEAR_APPLICATION_FILE = "CLEAR_APPLICATION_FILE";

export const GET_OFFRES = "GET_OFFRES";
export const SET_OFFRES = "SET_OFFRES";

export const GET_ACTUALITY = "GET_ACTUALITY";
export const SET_ACTUALITY = "SET_ACTUALITY";

export const GET_STATISTIQUE = "GET_STATISTIQUE";
export const SET_STATISTIQUE = "SET_STATISTIQUE";

export const GET_LINKS = "GET_LINKS";
export const SET_LINKS = "SET_LINKS";

export const CONFIGURE_PWD = "CONFIGURE_PWD";
export const SET_CONFIGURE_PWD = "SET_CONFIGURE_PWD";

export const SEND_OFFRE_APPLICATION = "SEND_OFFRE_APPLICATION";
export const SET_OFFRE_APPLICATION = "SET_OFFRE_APPLICATION";
export const CLEAR_OFFRE_APPLICATION = "CLEAR_OFFRE_APPLICATION";

export const UPDATE_DONNEES = "UPDATE_DONNEES";
export const SET_DONNEES = "SET_DONNEES";
export const CLEAR_DONNEES = "CLEAR_DONNEES";

export const ADD_PLAQUETTE = "ADD_PLAQUETTE";
export const SET_PLAQUETTE = "SET_PLAQUETTE";
export const CLEAR_PLAQUETTE = "CLEAR_PLAQUETTE";

export const RESET_PWD = "RESET_PWD";
export const SET_RESET_PWD = "SET_RESET_PWD";

export const SEND_OFFRE_INFO = "SEND_OFFRE_INFO";
export const SET_OFFRE_INFO = "SET_OFFRE_INFO";

export const GET_LIENS = "GET_LIENS";
export const SET_LIENS = "SET_LIENS";

export const GET_BENEFS = "GET_BENEFS";
export const SET_BENEFS = "SET_BENEFS";

export const SEND_OTP = "SEND_OTP";
export const SET_OTP = "SET_OTP";
export const CLEAR_ATTAK_OTP = "CLEAR_ATTAK_OTP";

export const SEND_VERIFY_ATTAK_OTP = "SEND_VERIFY_ATTAK_OTP";
export const SET_VERIFY_ATTAK_OTP = "SET_VERIFY_ATTAK_OTP";
export const CLEAR_VERIFY_ATTAK_OTP = "CLEAR_VERIFY_ATTAK_OTP";

export const SEND_VERIFY_VER_OTP = "SEND_VERIFY_VER_OTP";
export const SET_VERIFY_VER_OTP = "SET_VERIFY_VER_OTP";
export const CLEAR_VERIFY_VER_OTP = "CLEAR_VERIFY_VER_OTP";

export const SEND_POSTULATION_FILE = "SEND_POSTULATION_FILE";
export const SET_POSTULATION_FILE = "SET_POSTULATION_FILE";
export const CLEAR_POSTULATION_FILE = "CLEAR_POSTULATION_FILE";

export const SEND_DEMANDE_LIQUIDATION = "SEND_DEMANDE_LIQUIDATION";
export const SET_DEMANDE_LIQUIDATION = "SET_DEMANDE_LIQUIDATION";
export const CLEAR_DEMANDE_LIQUIDATION = "CLEAR_DEMANDE_LIQUIDATION";

export const SEND_COTISATION = "SEND_COTISATION";
export const SET_COTISATION = "SET_COTISATION";
export const CLEAR_COTISATION = "CLEAR_COTISATION";

export const UPDATE_BENEFS = "UPDATE_BENEFS";
export const SET_UPDATE_BENEFS = "SET_UPDATE_BENEFS";
export const CLEAR_UPDATE_BENEFS = "CLEAR_UPDATE_BENEFS";

export const UPDATE_SOUSCRIPTION = "UPDATE_SOUSCRIPTION";
export const SET_UPDATE_SOUSCRIPTION = "SET_UPDATE_SOUSCRIPTION";
export const CLEAR_UPDATE_SOUSCRIPTION = "CLEAR_UPDATE_SOUSCRIPTION";

export const SEND_LIBELLE_PPR = "SEND_LIBELLE_PPR";
export const SET_LIBELLE_PPR = "SET_LIBELLE_PPR";

export const SEND_VALIDER_COTISATION = "SEND_VALIDER_COTISATION";
export const SET_VALIDER_COTISATION = "SET_VALIDER_COTISATION";
export const CLEAR_VALIDER_COTISATION = "CLEAR_VALIDER_COTISATION";

export const GET_FACTURES = "GET_FACTURES";
export const SET_FACTURES = "SET_FACTURES";

export const GET_MOTIFS = "GET_MOTIFS";
export const SET_MOTIFS = "SET_MOTIFS";

export const SEND_VALIDER_DEMANDE_LIQUIDATION =
    "SEND_VALIDER_DEMANDE_LIQUIDATION";
export const SET_VALIDER_DEMANDE_LIQUIDATION =
    "SET_VALIDER_DEMANDE_LIQUIDATION";
export const CLEAR_VALIDER_DEMANDE_LIQUIDATION =
    "CLEAR_VALIDER_DEMANDE_LIQUIDATION";

export const SIMULER_CAPITAL = "SIMULER_CAPITAL";
export const SET_SIMULATION_CAPITAL = "SET_SIMULATION_CAPITAL";
export const CLEAR_SIMULATION_CAPITAL = "CLEAR_SIMULATION_CAPITAL";

export const SIMULER_RENTE = "SIMULER_RENTE";
export const SET_SIMULATION_RENTE = "SET_SIMULATION_RENTE";
export const CLEAR_SIMULATION_RENTE = "CLEAR_SIMULATION_RENTE";

export const SIMULER_CAPITAL_AVANCE = "SIMULER_CAPITAL_AVANCE";
export const SET_SIMULATION_CAPITAL_AVANCE = "SET_SIMULATION_CAPITAL_AVANCE";
export const CLEAR_SIMULATION_CAPITAL_AVANCE =
    "CLEAR_SIMULATION_CAPITAL_AVANCE";

export const SIMULER_RENTE_AVANCE = "SIMULER_RENTE_AVANCE";
export const SET_SIMULATION_RENTE_AVANCE = "SET_SIMULATION_RENTE_AVANCE";
export const CLEAR_SIMULATION_RENTE_AVANCE = "CLEAR_SIMULATION_RENTE_AVANCE";

export const SIMULER_CAPITAL_INVERSE = "SIMULER_CAPITAL_INVERSE";
export const SET_SIMULATION_CAPITAL_INVERSE = "SET_SIMULATION_CAPITAL_INVERSE";
export const CLEAR_SIMULATION_CAPITAL_INVERSE =
    "CLEAR_SIMULATION_CAPITAL_INVERSE";

export const SIMULER_RENTE_INVERSE = "SIMULER_RENTE_INVERSE";
export const SET_SIMULATION_RENTE_INVERSE = "SET_SIMULATION_RENTE_INVERSE";
export const CLEAR_SIMULATION_RENTE_INVERSE = "CLEAR_SIMULATION_RENTE_INVERSE";

export const GET_EVENTS = "GET_EVENTS";
export const SET_EVENTS = "SET_EVENTS";

export const GET_NOTIFS = "GET_NOTIFS";
export const SET_NOTIFS = "SET_NOTIFS";

export const GET_INBOX = "GET_INBOX";
export const SET_INBOX = "SET_INBOX";

export const OPEN_NOTIF = "OPEN_NOTIF";
export const SET_OPEN_NOTIF = "SET_OPEN_NOTIF";

export const GET_ALL_RENCONTRES = "GET_ALL_RENCONTRES";
export const SET_ALL_RENCONTRES = "SET_ALL_RENCONTRES";

export const SET_SIM = "SET_SIM";
export const CLEAR_SIM = "CLEAR_SIM";

export const SET_BACK = "SET_BACK";
export const CLEAR_BACK = "CLEAR_BACK";

export const SEND_SURVEY = "SEND_SURVEY";
export const SET_SURVEY = "SET_SURVEY";
export const SEND_ADS = "SEND_ADS";
export const SET_ADS = "SET_ADS";

export const SEND_DATA = "SEND_DATA";
export const SET_DATA = "SET_DATA";
export const CLEAR_DATA = "CLEAR_DATA";

export const GET_CHAUDS = "GET_CHAUDS";
export const SET_CHAUDS = "SET_CHAUDS";

export const GET_INFO_EPARGNE = "GET_INFO_EPARGNE";
export const SET_INFO_EPARGNE = "SET_INFO_EPARGNE";

export const SET_DATA_VERSEMENT = "SET_DATA_VERSEMENT";

export const EVALUATE_PRESTA = "EVALUATE_PRESTA";
export const SET_EVALUATE_PRESTA = "SET_EVALUATE_PRESTA";

export const SIMULATION_COMBINE = "SIMULATION_COMBINE";
export const SET_SIMULATION_COMBINE = "SET_SIMULATION_COMBINE";
export const CLEAR_SIMULATION_COMBINE = "CLEAR_SIMULATION_COMBINE";

export const GET_TAUX_BO = "GET_TAUX_BO";
export const SET_TAUX_BO = "SET_TAUX_BO";

export const SEND_JOURNALISTE = "SEND_JOURNALISTE";
export const SET_JOURNALISTE = "SET_JOURNALISTE";
export const CLEAR_JOURNALISTE = "CLEAR_JOURNALISTE";

export const PAIMENT_MODE = "PAIMENT_MODE";

export const CHANGE_STATUS_NOTIF = "CHANGE_STATUS_NOTIF";
export const SET_CHANGE_STATUS_NOTIF = "SET_CHANGE_STATUS_NOTIF";
export const CLEAR_CHANGE_STATUS_NOTIF = "CLEAR_CHANGE_STATUS_NOTIF";

export const UNOPPENED_NOTIF = "UNOPPENED_NOTIF";

export const apiStart = label => ({
    type: API_START,
    payload: label
});

export const apiEnd = label => ({
    type: API_END,
    payload: label
});

export const accessDenied = url => ({
    type: ACCESS_DENIED,
    payload: {
        url
    }
});

export const apiError = error => ({
    type: API_ERROR,
    error
});

export const clearDataInscription = () => ({
    type: CLEAR_ADD_INSCRIPTION
});
export const clearDataListPrestations = () => ({
    type: CLEAR_LIST_PRESTATIONS
});
export const clearVerifyOtp = () => ({
    type: CLEAR_VERIFY_OTP
});
export const clearConfigureMDP = () => ({
    type: CLEAR_CONFIGURE_MDP
});
export const clearForgetMDP = () => ({
    type: CLEAR_FORGET_MDP
});
export const clearChangeMDP = () => ({
    type: CLEAR_CHANGE_MDP
});
export const clearResendOTP = () => ({
    type: CLEAR_RESEND_OTP
});
export const clearInscriptionFournisseur = () => ({
    type: CLEAR_INSCRIPTION_FOURNISSEUR
});
export const clearInscriptionFournisseurFile = () => ({
    type: CLEAR_INSCRIPTION_FOURNISSEUR_FILE
});
export const clearConfigureMdpFournisseur = () => ({
    type: CLEAR_CONFIGURE_MDP_FOURNISSEUR
});
export const clearUploadFacture = () => ({
    type: CLEAR_UPLOAD_FACTURE
});
export const ClearAllAppelOffreNC = () => ({
    type: CLEAR_APPELS_OFFRE_NC
});
export const clearOtpMail = () => ({
    type: CLEAR_OTP_MAIL
});
export const clearOtpPhone = () => ({
    type: CLEAR_OTP_PHONE
});
export const clearAnswer = () => ({
    type: CLEAR_ANSWER
});
export const clearPlaquette = () => ({
    type: CLEAR_PLAQUETTE
});
export const clearDonnees = () => ({
    type: CLEAR_DONNEES
});
export const clearChangeData = () => ({
    type: CLEAR_CHANGE_DATA
});
export const clearSimulationCapital = () => ({
    type: CLEAR_SIMULATION_CAPITAL
});
export const clearSimulationRente = () => ({
    type: CLEAR_SIMULATION_RENTE
});
export const clearSimulationRenteAva = () => ({
    type: CLEAR_SIMULATION_RENTE_AVANCE
});
export const clearSimulationCapitalAva = () => ({
    type: CLEAR_SIMULATION_CAPITAL_AVANCE
});
export const clearSimulationCapitalInverse = () => ({
    type: CLEAR_SIMULATION_CAPITAL_INVERSE
});
export const clearSimulationRenteInverse = () => ({
    type: CLEAR_SIMULATION_RENTE_INVERSE
});
export const clearSim = () => ({
    type: CLEAR_SIM
});
export const clearBack = () => ({
    type: CLEAR_BACK
});
export const clearSimulationCombine = () => ({
    type: CLEAR_SIMULATION_COMBINE
});
export const clearJournaliste = () => ({
    type: CLEAR_JOURNALISTE
});
export const clearChangeStatus = () => ({
    type: CLEAR_CHANGE_STATUS_NOTIF
});
/*************************************************** FUNCTIONS /*************************************************** */
export const sendAuth = (username, password) => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/authentication",
        method: "POST",
        data: { username: username, password: password },
        privateAPI: false,
        onSuccess: setAuthData,
        onFailure: () =>
            console.log("Error occured loading authentification data"),
        label: SEND_AUTH
    });
};

export const authUsingThirdParty = code => {
    return apiAction({
        url: BACKEND_URL + "/api/dgsn/v1/auth-dgsn?authCode=" + code,
        method: "POST",
        privateAPI: false,
        onSuccess: setAuthData,
        onFailure: () =>
            console.log("Error occured loading authentification data"),
        label: SEND_AUTH_TOKEN
    });
};

export function setAuthData(data) {
    return {
        type: SET_AUTH,
        payload: data
    };
}
export const sendAuthFournisseur = (username, password) => {
    return apiAction({
        url: BACKEND_URL_ACHAT + "/api/v1/auth/signin",
        method: "POST",
        data: { username: username, password: password },
        privateAPI: false,
        onSuccess: setAuthDataFournisseur,
        onFailure: () =>
            console.log("Error occured loading authentification data"),
        label: SEND_AUTH_FOURNISSEUR
    });
};

export function setAuthDataFournisseur(data) {
    return {
        type: SET_AUTH_FOURNISSEUR,
        payload: data
    };
}
export const getDetailsClient = numAffilie => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/eclient/detail/" + numAffilie,
        method: "GET",
        onSuccess: setDetailsClient,
        onFailure: () =>
            console.log("Error occured loading details client data"),
        label: GET_DETAILS_CLIENT
    });
};

export const verifyToken = () => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/eclient/verify-token",
        method: "GET",
        onSuccess: setVerifyToken,
        onFailure: () => console.log("Error occurred verifying token"),
        label: SEND_VERIFY_TOKEN
    });
};

export function setVerifyToken(data) {
    return {
        type: SET_VERIFY_TOKEN,
        payload: data
    };
}

export const verifyTokenFournisseur = () => {
    return apiAction({
        url: BACKEND_URL_ACHAT + "/api/v1/auth/token-verif",
        method: "GET",
        onSuccess: setVerifyTokenFournisseur,
        onFailure: () => console.log("Error occurred verifying token"),
        label: SEND_VERIFY_TOKEN_FOURNISSEUR
    });
};

export function setVerifyTokenFournisseur(data) {
    return {
        type: SET_VERIFY_TOKEN_FOURNISSEUR,
        payload: data
    };
}

export function setDetailsClient(data) {
    return {
        type: SET_GET_DETAILS_CLIENT,
        payload: data
    };
}

export function setIdDemandeErsal(data) {
    return {
        type: SET_ID_DEMANDE_ERSAL,
        payload: data
    };
}

export const addInscription = (nom, prenom, cin, operateur, phone, email) => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/eclient/add",
        method: "POST",
        data: { nom, prenom, cin, operateur, phone, email },
        onSuccess: setAddInscription,
        onFailure: () => console.log("Error occured adding inscription"),
        label: ADD_INSCRIPTION,
        privateAPI: false
    });
};
export const verifyOtp = (idClient, otp) => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/eclient/verify-otp/" + idClient,
        // method: "POST",
        data: { otp },
        onSuccess: setVerifyOtp,
        onFailure: () => console.log("Error occured verifying OTP"),
        label: VERIFY_OTP,
        privateAPI: false
    });
};

export const clearOTP = () => {
    return {
        type: CLEAR_OTP
    };
};

export const configureMDP = (idClient, password, confirmpass, typeCanal) => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/eclient/configure-password/" + idClient,
        method: "POST",
        data: { password, confirmpass, typeCanal },
        onSuccess: setConfigureMDP,
        privateAPI: false,
        onFailure: () => console.log("Error occured configuring MDP"),
        label: CONFIGURE_MDP
    });
};
export const forgetMDP = email => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/eclient/send-otp/" + email,
        method: "GET",
        onSuccess: setForgetMDP,
        onFailure: () => console.log("Error occured forget MDP"),
        label: FORGET_MDP
    });
};
export const changeMDP = (token, newPassword) => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/eclient/change-password",
        method: "PUT",
        data: { token, newPassword },
        onSuccess: setChangeMDP,
        onFailure: () => console.log("Error occured changing MDP"),
        label: CHANGE_MDP
    });
};
export const resendOTP = idClient => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/eclient/send-back-otp/" + idClient,
        onSuccess: setResendOTP,
        onFailure: () => console.log("Error occured resending OTP"),
        label: RESEND_OTP
    });
};
//*************************************************** SET ************************************************** */

export function setAddInscription(data) {
    return {
        type: SET_ADD_INSCRIPTION,
        payload: data
    };
}

export const clearLoginData = () => ({
    type: CLEAR_DECONNEXION
});

export const tokenExpired = () => ({
    type: TOKEN_EXPIRED
});

export function setVerifyOtp(data) {
    return {
        type: SET_VERIFY_OTP,
        payload: data
    };
}

export function setConfigureMDP(data) {
    return {
        type: SET_CONFIGURE_MDP,
        payload: data
    };
}

export function setForgetMDP(data) {
    return {
        type: SET_FORGET_MDP,
        payload: data
    };
}

export function setChangeMDP(data) {
    return {
        type: SET_CHANGE_MDP,
        payload: data
    };
}
export function setResendOTP(data) {
    return {
        type: SET_RESEND_OTP,
        payload: data
    };
}
/*********************************************************************************************** */

export const getAllPrestations = () => {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/prestation/param/get/all?targetType=GRAND_PUBLIC",
        method: "GET",
        onSuccess: setAllPrestations,
        onFailure: () => null,
        privateAPI: false,
        label: SEND_ALL_PRESTATIONS
    });
};

export function setAllPrestations(data) {
    return {
        type: SET_ALL_PRESTATIONS,
        payload: data
    };
}

export const getAllPrestations2 = data => {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/prestation/param/public/get/all?" +
            new URLSearchParams(data).toString(),
        method: "GET",
        onSuccess: setAllPrestations2,
        onFailure: () => null,
        privateAPI: false,
        label: SEND_ALL_PRESTATIONS_2
    });
};

export function setAllPrestations2(data) {
    return {
        type: SET_ALL_PRESTATIONS_2,
        payload: data
    };
}

export const getPerstationById = idPrestation => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/prestation/param/get/" + idPrestation,
        method: "GET",
        onSuccess: setPrestationByID,
        onFailure: () => null,
        label: SEND_PRESTATION_BY_ID
    });
};

export function setPrestationByID(data) {
    return {
        type: SET_PRESTATION_BY_ID,
        payload: data
    };
}

export function setPrestationByIDNull() {
    return {
        type: SET_PRESTATION_BY_ID_NULL
    };
}

export const getListActualites = data => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/actualite/get/all",
        method: "GET",
        data: data,
        onSuccess: setListActualites,
        onFailure: () => null,
        label: SEND_LIST_ACTUALITES
    });
};
export function setListActualites(data) {
    return {
        type: SET_LIST_ACTUALITES,
        payload: data
    };
}

export function calculSimulationPrincipale(data, num) {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/simulation/" +
            num +
            "/simulation-principale?" +
            new URLSearchParams(data).toString(),
        method: "GET",
        onSuccess: setCalculSimulationPrincipale,
        onFailure: () => null,
        label: SEND_SIMULATION
    });
}

export function setCalculSimulationPrincipale(data) {
    return {
        type: SET_SIMULATION,
        payload: data
    };
}

export const getListActualitesEnabled = form => {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/actualite/enabled?" +
            new URLSearchParams(form).toString(),
        method: "GET",
        onSuccess: setListActualitesEnabled,
        onFailure: () => null,
        privateAPI: false,
        label: SEND_LIST_ACTUALITES_ENABLED
    });
};

export function setListActualitesEnabled(data) {
    return {
        type: SET_LIST_ACTUALITES_ENABLED,
        payload: data
    };
}

export const getListFaq = () => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/efaq/get/enabled/all",
        method: "GET",
        onSuccess: setListFaq,
        onFailure: () => null,
        label: SEND_LIST_FAQ,
        privateAPI: false
    });
};

export function setListFaq(data) {
    return {
        type: SET_LIST_FAQ,
        payload: data
    };
}

export function verifyOTPMail(data) {
    return apiAction({
        url: BACKEND_URL + "/api/v1/eclient/verify-otp/mail",
        method: "PUT",
        data: data,
        onSuccess: setVerifyOTPMail,
        onFailure: () => null,
        label: SEND_OTP_MAIL
    });
}

export function setVerifyOTPMail(data) {
    return {
        type: SET_OTP_MAIL,
        payload: data
    };
}

export function verifyOTPPhone(data) {
    return apiAction({
        url: BACKEND_URL + "/api/v1/eclient/verify-otp/phone",
        method: "PUT",
        data: data,
        onSuccess: setVerifyOTPPhone,
        onFailure: () => null,
        label: SEND_OTP_PHONE
    });
}

export function setVerifyOTPPhone(data) {
    return {
        type: SET_OTP_PHONE,
        payload: data
    };
}

export function updateMailPhone(form) {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/eclient/update/mail-phone?" +
            new URLSearchParams(form).toString(),
        method: "PUT",
        // data: data,
        onSuccess: setUpdateMailPhone,
        onFailure: () => null,
        label: SEND_UPDATE_MAIL_PHONE
    });
}

export function setUpdateMailPhone(data) {
    return {
        type: SET_UPDATE_MAIL_PHONE,
        payload: data
    };
}

export function updateMail(form) {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/eclient/update/mail-phone?" +
            new URLSearchParams(form).toString(),
        method: "PUT",
        // data: data,
        onSuccess: setUpdateMail,
        onFailure: () => null,
        label: SEND_UPDATE_MAIL
    });
}

export function setUpdateMail(data) {
    return {
        type: SET_UPDATE_MAIL,
        payload: data
    };
}

export function updatePhone(form) {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/eclient/update/mail-phone?" +
            new URLSearchParams(form).toString(),
        method: "PUT",
        // data: data,
        onSuccess: setUpdatePhone,
        onFailure: () => null,
        label: SEND_UPDATE_PHONE
    });
}

export function setUpdatePhone(data) {
    return {
        type: SET_UPDATE_PHONE,
        payload: data
    };
}

export function getOffreInfo(id) {
    return apiAction({
        url: BACKEND_URL_R_HUMAINE + "/api/v1/offre/get/" + id,
        method: "GET",
        onSuccess: setOffreInfo,
        privateAPI: false,
        onFailure: () => null,
        label: SEND_OFFRE_INFO
    });
}

export function setOffreInfo(data) {
    return {
        type: SET_OFFRE_INFO,
        payload: data
    };
}

export function changePWD(data) {
    return apiAction({
        url: BACKEND_URL + "/api/v1/eclient/change-password",
        method: "PUT",
        data: data,
        onSuccess: setChangePWD,
        onFailure: () => null,
        label: SEND_CHANGE_PWD
    });
}

export function setChangePWD(data) {
    return {
        type: SET_CHANGE_PWD,
        payload: data
    };
}

export function clearPWD() {
    return {
        type: CLEAR_CHANGE_PWD
    };
}

export function setPensionType(type) {
    return {
        type: SET_PENSION_TYPE,
        payload: type
    };
}

export function getPrestationDemandes(num) {
    return apiAction({
        url: BACKEND_URL + "/api/v1/demande/get/" + num,
        method: "GET",
        data: num,
        onSuccess: setPrestationDemandes,
        onFailure: () => null,
        label: SEND_PREST_DEMANDE
    });
}

export function setPrestationDemandes(data) {
    return {
        type: SET_PREST_DEMANDE,
        payload: data
    };
}

export function getDocumentations() {
    return apiAction({
        url: BACKEND_URL + "/api/v1/document/get/visibled",
        method: "GET",
        onSuccess: setDocumentations,
        onFailure: () => null,
        label: SEND_DOCUMENTATIONS,
        privateAPI: false
    });
}

export function setDocumentations(data) {
    return {
        type: SET_DOCUMENTATIONS,
        payload: data
    };
}

export function getDemandeDetails(id) {
    return apiAction({
        url: BACKEND_URL + "/api/v1/demande/" + id,
        method: "GET",
        data: id,
        onSuccess: setDemandeDetails,
        onFailure: () => null,
        label: SEND_DEMANDE_DETAILS
    });
}

export function setDemandeDetails(data) {
    return {
        type: SET_DEMANDE_DETAILS,
        payload: data
    };
}

export function getClientSituation(num) {
    return apiAction({
        url: BACKEND_URL + "/api/v1/eclient/situation/" + num,
        method: "GET",
        onSuccess: setClientSituation,
        onFailure: () => null,
        label: SEND_SITUATION
    });
}

export function setClientSituation(data) {
    return {
        type: SET_SITUATION,
        payload: data
    };
}

export function getPensionDonnees(numP) {
    return apiAction({
        url: BACKEND_URL + `/api/v1/pension/get-numPension/?numPension=${numP}`,
        method: "GET",
        onSuccess: setPensionDonnees,
        onFailure: () => null,
        label: SEND_PENSION_DONNEE
    });
}

export function setPensionDonnees(data) {
    return {
        type: SET_PENSION_DONNEE,
        payload: data
    };
}

export function getPensionDemandes(data) {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/demande/pension?" +
            new URLSearchParams(data).toString(),
        method: "GET",
        data: data,
        onSuccess: setPensionDemandes,
        onFailure: () => null,
        label: SEND_PENSION_DEMANDES
    });
}

export function setPensionDemandes(data) {
    return {
        type: SET_PENSION_DEMANDES,
        payload: data
    };
}

export function getPrestations(type, penType) {
    return apiAction({
        url:
            BACKEND_URL +
            `/api/v1/prestation/param/get/all?targetType=` +
            type +
            (penType ? `&prestationType=${penType}` : ""),
        method: "GET",
        //data: form,
        onSuccess: setPrestations,
        onFailure: () => null,
        label: SEND_PRESTATIONS
    });
}

export function setPrestations(data) {
    return {
        type: SET_PRESTATIONS,
        payload: data
    };
}

export function getSimulation(numAffilie, form) {
    return apiAction({
        url:
            BACKEND_URL +
            `/api/v1/simulation/${numAffilie}/simulation-retenues/?` +
            new URLSearchParams(form).toString(),
        method: "GET",
        // data: form,
        onSuccess: setSimulation,
        onFailure: () => null,
        label: SEND_SIMULATION_DATA
    });
}

export function setSimulation(data) {
    return {
        type: SET_SIMULATION_DATA,
        payload: data
    };
}

export function addPrestationDisconnected(prestation) {
    return apiAction({
        url: BACKEND_URL + "/api/v1/demande/disconnected/add",
        method: "POST",
        data: prestation,
        onSuccess: setPrestation,
        onFailure: () => null,
        label: SEND_PRESTATION
    });
}

export function setPrestation(data) {
    return {
        type: SET_PRESTATION,
        payload: data
    };
}

export function clearPrestation() {
    return {
        type: CLEAR_PRESTATION
    };
}

export function addPieceDisconnected(idDemande, libellePiece, file) {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/demande/disconnected/add/piece/" +
            idDemande +
            "?libelleJustif=" +
            libellePiece,
        method: "POST",
        data: file,
        onSuccess: setAddPiece,
        onFailure: () => null,
        label: ADD_PIECE
    });
}

export function setAddPiece(data) {
    return {
        type: SET_ADD_PIECE,
        payload: data
    };
}

export function clearAddPiece() {
    return {
        type: CLEAR_ADD_PIECE
    };
}

export function addPrestationConnected(prestation, otpSMS) {
    return apiAction({
        url: BACKEND_URL + "/api/v1/demande/add?otpSms=" + otpSMS,
        method: "POST",
        data: prestation,
        onSuccess: setPrestationConnected,
        onFailure: () => null,
        label: SEND_PRESTATION_CONNECTED
    });
}

export function setPrestationConnected(data) {
    return {
        type: SET_PRESTATION_CONNECTED,
        payload: data
    };
}

export function clearPrestationConnected() {
    return {
        type: SET_PRESTATION_NULL
    };
}

export function setPJS(data) {
    return {
        type: SET_PJS,
        payload: data
    };
}

export function setZoom(data) {
    return {
        type: SET_ZOOM,
        payload: data
    };
}
export function setDisplayedAds(data) {
    return {
        type: SET_DISPLAYED_ADS,
        payload: data
    };
}

export function addPieceConnected(idDemande, libellePiece, file) {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/demande/add/piece/" +
            idDemande +
            "?libelleJustif=" +
            libellePiece,
        method: "POST",
        data: file,
        onSuccess: setAddPieceConnected,
        onFailure: () => null,
        label: ADD_PIECE_CONNECTED
    });
}

export function setAddPieceConnected(data) {
    return {
        type: SET_ADD_PIECE_CONNECTED,
        payload: data
    };
}

export function clearAddPieceConnected() {
    return {
        type: CLEAR_ADD_PIECE_CONNECTED
    };
}

export function verifyOTPPrestation(otpSMS, numAffilie, idDemande) {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/demande/verify_otp/" +
            otpSMS +
            "/" +
            numAffilie +
            "/" +
            idDemande,
        method: "GET",
        onSuccess: setVerifyOtpPrestation,
        onFailure: () => null,
        label: VERIFY_OTP_PRESTATION
    });
}

export function setVerifyOtpPrestation(data) {
    return {
        type: SET_VERIFY_OTP_PRESTATION,
        payload: data
    };
}

export function sendOTPPrestation() {
    return apiAction({
        url: BACKEND_URL + "/api/v1/demande/send_otp",
        method: "GET",
        onSuccess: setSendOtpPrestation,
        onFailure: () => null,
        label: SEND_OTP_PRESTATION
    });
}

export function setSendOtpPrestation(data) {
    return {
        type: SET_SEND_OTP_PRESTATION,
        payload: data
    };
}

export function downloadAttestation(form) {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/attestation/download?" +
            new URLSearchParams(form).toString(),
        data: form,
        method: "GET",
        onSuccess: setDownloadAttestation,
        onFailure: () => null,
        label: DOWNLOAD_ATTESTATION,
        responseType: "blob"
    });
}

export function setDownloadAttestation(data) {
    return {
        type: SET_DOWNLOAD_ATTESTATION,
        payload: data
    };
}

export function changeCanal(canal) {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/eclient/update/type-canal?typeCanal=" +
            canal,
        method: "PUT",
        onSuccess: setChangeCanal,
        onFailure: () => null,
        label: CHANGE_CANAL
    });
}

export function setChangeCanal(data) {
    return {
        type: SET_CHANGE_CANAL,
        payload: data
    };
}

export function setCanalNull() {
    return {
        type: SET_CANAL_NULL
    };
}

export function changePassword(data) {
    return apiAction({
        url: BACKEND_URL_ACHAT + "/api/v1/auth/change-password",
        method: "PUT",
        data: data,
        onSuccess: setChangePassword,
        onFailure: () => null,
        label: CHANGE_PASSWORD
    });
}

export function setChangePassword(data) {
    return {
        type: SET_CHANGE_PASSWORD,
        payload: data
    };
}

export function forgotPWD(email) {
    return apiAction({
        url: BACKEND_URL_ACHAT + "/api/v1/auth/forget/password?email=" + email,
        method: "PUT",
        onSuccess: setForgotPWD,
        onFailure: () => null,
        label: FORGOT_PWD
    });
}

export function setForgotPWD(data) {
    return {
        type: SET_FORGOT_PWD,
        payload: data
    };
}

export function sendNewPWD(data) {
    return apiAction({
        url: BACKEND_URL_ACHAT + "/api/v1/auth/change-passwordOublie",
        method: "PUT",
        data: data,
        onSuccess: setNewPWD,
        onFailure: () => null,
        label: NEW_PWD
    });
}

export function setNewPWD(data) {
    return {
        type: SET_NEW_PWD,
        payload: data
    };
}

export function changeData(data) {
    return apiAction({
        url: BACKEND_URL_ACHAT + "/api/v1/auth/change-donneePerso",
        method: "PUT",
        data: data,
        onSuccess: setChangeData,
        onFailure: () => null,
        label: CHANGE_DATA
    });
}

export function setChangeData(data) {
    return {
        type: SET_CHANGE_DATA,
        payload: data
    };
}

export function setPasswordNull() {
    return {
        type: SET_PASSWORD_NULL
    };
}

export function addWorkflowDisconnected(idDemande, cni) {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/demande/workflow/grand-public/" +
            idDemande +
            "?cni=" +
            cni,
        method: "GET",
        onSuccess: setAddWorkflowDisconnected,
        onFailure: () => null,
        label: ADD_WORKFLOW_DISCONNECTED
    });
}

export function setAddWorkflowDisconnected() {
    return {
        type: SET_ADD_WORKFLOW_DISCONNECTED
    };
}

export function addWorkflow(idDemande) {
    return apiAction({
        url: BACKEND_URL + "/api/v1/demande/workflow/" + idDemande,
        method: "GET",
        onSuccess: setAddWorkflow,
        onFailure: () => null,
        label: ADD_WORKFLOW
    });
}

export function setAddWorkflow() {
    return {
        type: SET_ADD_WORKFLOW
    };
}

export function souscriptionAttakmili(data) {
    return apiAction({
        url: BACKEND_URL + "/api/v1/attakmili/add",
        method: "POST",
        data: data,
        onSuccess: setSouscriptionAttakmili,
        onFailure: () => null,
        label: SEND_SOUSCRIPTION_ATTAKMILI
    });
}

export function setSouscriptionAttakmili(data) {
    return {
        type: SET_SOUSCRIPTION_ATTAKMILI,
        payload: data
    };
}

export function clearSouscriptionAttakmili() {
    return {
        type: CLEAR_SOUSCRIPTION_ATTAKMILI
    };
}

export function getOffres() {
    return apiAction({
        url: BACKEND_URL_R_HUMAINE + "/api/v1/offre/getOffre",
        method: "GET",
        privateAPI: false,
        onSuccess: setOffres,
        onFailure: () => null,
        label: GET_OFFRES
    });
}

export function setOffres(data) {
    return {
        type: SET_OFFRES,
        payload: data
    };
}

export function applyToOffre(data, id) {
    return apiAction({
        url: BACKEND_URL_R_HUMAINE + "/api/v1/application/apply/" + id,
        method: "POST",
        data: data,
        privateAPI: false,
        onSuccess: setApplyToOffre,
        onFailure: () => null,
        label: SEND_OFFRE_APPLICATION
    });
}

export function setApplyToOffre(data) {
    return {
        type: SET_OFFRE_APPLICATION,
        payload: data
    };
}

export function getContact(data) {
    return apiAction({
        url: BACKEND_URL_R_HUMAINE + "/api/v1/contact/getContact",
        method: "GET",
        data: data,
        privateAPI: false,
        onSuccess: setContact,
        onFailure: () => null,
        label: SEND_CONTACT
    });
}

export function setContact(data) {
    return {
        type: SET_CONTACT,
        payload: data
    };
}

export function clearContact() {
    return {
        type: CLEAR_CONTACT
    };
}

export function getActuality() {
    return apiAction({
        url: BACKEND_URL_R_HUMAINE + "/api/v1/actuality/get",
        method: "GET",
        privateAPI: false,
        onSuccess: setActuality,
        onFailure: () => null,
        label: GET_ACTUALITY
    });
}

export function setActuality(data) {
    return {
        type: SET_ACTUALITY,
        payload: data
    };
}

export function getStatistique() {
    return apiAction({
        url: BACKEND_URL_R_HUMAINE + "/api/v1/statistique/getStatistique",
        method: "GET",
        privateAPI: false,
        onSuccess: setStatistique,
        onFailure: () => null,
        label: GET_STATISTIQUE
    });
}

export function setStatistique(data) {
    return {
        type: SET_STATISTIQUE,
        payload: data
    };
}

export function getLinks() {
    return apiAction({
        url: BACKEND_URL_R_HUMAINE + "/api/v1/lien/getAll",
        method: "GET",
        privateAPI: false,
        onSuccess: setLinks,
        onFailure: () => null,
        label: GET_LINKS
    });
}

export function setLinks(data) {
    return {
        type: SET_LINKS,
        payload: data
    };
}

export function getConsultationDonnees() {
    return apiAction({
        url: BACKEND_URL + "/api/v1/attakmili/consultation",
        method: "GET",
        onSuccess: setConsultationDonnees,
        onFailure: () => null,
        label: GET_CONSULTATION_DONNEES
    });
}

export function setConsultationDonnees(data) {
    return {
        type: SET_CONSULTATION_DONNEES,
        payload: data
    };
}

export function addCandidature(data) {
    return apiAction({
        url: BACKEND_URL_R_HUMAINE + "/api/v1/candidatureSpontanee/add",
        method: "POST",
        data: data,
        privateAPI: false,
        onSuccess: setAddCandidature,
        onFailure: () => null,
        label: SEND_CANDIDATURE_SPONTANEE
    });
}

export function setAddCandidature(data) {
    return {
        type: SET_CANDIDATURE_SPONTANEE,
        payload: data
    };
}

export function addCandidatureFile(file, id) {
    return apiAction({
        url: BACKEND_URL_R_HUMAINE + "/api/v1/candidatureSpontanee/add/" + id,
        method: "POST",
        data: file,
        privateAPI: false,
        onSuccess: setAddCandidatureFile,
        onFailure: () => null,
        label: SEND_CANDIDATURE_SPONTANEE_FILE
    });
}

export function setAddCandidatureFile(data) {
    return {
        type: SET_CANDIDATURE_SPONTANEE_FILE,
        payload: data
    };
}

export function addApplicationFile(file, id) {
    return apiAction({
        url:
            BACKEND_URL_R_HUMAINE +
            "/api/v1/application/addDocumentPostulation/" +
            id,
        method: "POST",
        data: file,
        privateAPI: false,
        onSuccess: setAddApplicationFile,
        onFailure: () => null,
        label: SEND_APPLICATION_FILE
    });
}

export function setAddApplicationFile(data) {
    return {
        type: SET_APPLICATION_FILE,
        payload: data
    };
}

export function addPostulationFile(file, id) {
    return apiAction({
        url:
            BACKEND_URL_R_HUMAINE +
            "/api/v1/application/addDocumentPostulation/" +
            id,
        method: "POST",
        data: file,
        privateAPI: false,
        onSuccess: setAddPostulationFile,
        onFailure: () => null,
        label: SEND_POSTULATION_FILE
    });
}

export function setAddPostulationFile(data) {
    return {
        type: SET_POSTULATION_FILE,
        payload: data
    };
}

export function clearPostulationFile() {
    return {
        type: CLEAR_POSTULATION_FILE
    };
}

export function addDemandeLiquidation(data, id) {
    return apiAction({
        url: BACKEND_URL + "/api/v1/attakmili/demande-liquidation/add/" + id,
        method: "POST",
        data: data,
        onSuccess: setDemandeLiquidation,
        onFailure: () => null,
        label: SEND_DEMANDE_LIQUIDATION
    });
}

export function setDemandeLiquidation(data) {
    return {
        type: SET_DEMANDE_LIQUIDATION,
        payload: data
    };
}

export function clearDemandeLiquidation() {
    return {
        type: CLEAR_DEMANDE_LIQUIDATION
    };
}

export function addCotisation(data, id) {
    return apiAction({
        url: BACKEND_URL + "/api/v1/attakmili/update-cotisation/" + id,
        method: "POST",
        data: data,
        onSuccess: setCotisation,
        onFailure: () => null,
        label: SEND_COTISATION
    });
}

export function setCotisation(data) {
    return {
        type: SET_COTISATION,
        payload: data
    };
}

export function clearCotisation() {
    return {
        type: CLEAR_COTISATION
    };
}

export function validerCotisation(id) {
    return apiAction({
        url: BACKEND_URL + "/api/v1/attakmili/metier/update-cotisation/" + id,
        method: "POST",
        onSuccess: setValiderCotisation,
        onFailure: () => null,
        label: SEND_VALIDER_COTISATION
    });
}

export function setValiderCotisation(data) {
    return {
        type: SET_VALIDER_COTISATION,
        payload: data
    };
}

export function clearValiderCotisation() {
    return {
        type: CLEAR_VALIDER_COTISATION
    };
}

export function validerDemandeLiquidation(id) {
    return apiAction({
        url: BACKEND_URL + "/api/v1/attakmili/metier/demande-liquidation/" + id,
        method: "POST",
        onSuccess: setValiderDemandeLiquidation,
        onFailure: () => null,
        label: SEND_VALIDER_DEMANDE_LIQUIDATION
    });
}

export function setValiderDemandeLiquidation(data) {
    return {
        type: SET_VALIDER_DEMANDE_LIQUIDATION,
        payload: data
    };
}

export function clearValiderDemandeLiquidation() {
    return {
        type: CLEAR_VALIDER_DEMANDE_LIQUIDATION
    };
}

export function clearOffreApplication() {
    return {
        type: CLEAR_OFFRE_APPLICATION
    };
}

export function clearAddFacture() {
    return {
        type: CLEAR_ADD_FACTURE
    };
}

export function clearCandidature() {
    return {
        type: CLEAR_CANDIDATURE_SPONTANEE
    };
}

export function clearCandidatureFile() {
    return {
        type: CLEAR_CANDIDATURE_SPONTANEE_FILE
    };
}
export function clearApplicationeFile() {
    return {
        type: CLEAR_APPLICATION_FILE
    };
}
export function sendDataInscriptionFournisseur(data) {
    return apiAction({
        url: BACKEND_URL_ACHAT + "/api/v1/inscription/demande/add",
        method: "POST",
        data: data,
        onSuccess: setDataInscriptionFournisseur,
        onFailure: () => null,
        label: INSCRIPTION_FOURNISSEUR,
        privateAPI: false
    });
}

export function setDataInscriptionFournisseur(data) {
    return {
        type: SET_INSCRIPTION_FOURNISSEUR,
        payload: data
    };
}

export function sendDataInscriptionFournisseurFile(idDemande, file) {
    return apiAction({
        url:
            /*   BACKEND_URL_ACHAT +
            "/api/v1/inscription/demande/" +
            idDemande +
            "/add/piece-justif", */
            BACKEND_URL_ACHAT +
            "/api/v1/inscription/add/plaquette/" +
            idDemande,
        method: "PUT",
        data: file,
        onSuccess: setDataInscriptionFournisseurFile,
        onFailure: () => null,
        label: INSCRIPTION_FOURNISSEUR_FILE,
        privateAPI: false
    });
}

export function setDataInscriptionFournisseurFile(data) {
    return {
        type: SET_INSCRIPTION_FOURNISSEUR_FILE,
        payload: data
    };
}
export const resetPassword = (password, token) => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/eclient/reset-password",
        method: "PUT",
        data: { password, token },
        onSuccess: setAuthData,
        onFailure: () => null,
        label: SEND_MDP_RESET
    });
};
export function getVideosEvents(type) {
    return apiAction({
        url: BACKEND_URL + "/api/v1/video-events/all/grand-public?type=" + type,
        method: "GET",
        onSuccess: setVideosEvents,
        onFailure: () => null,
        label: GET_VIDEOS_EVENTS,
        privateAPI: false
    });
}

export function setVideosEvents(data) {
    return {
        type: SET_VIDEOS_EVENTS,
        payload: data
    };
}

export function getAppelsOffres() {
    return apiAction({
        url: BACKEND_URL_ACHAT + "/api/v1/appeloffre/displayAoFournisseut/all",
        method: "GET",
        onSuccess: setAppelsOffres,
        onFailure: () => null,
        label: SEND_APPELS_OFFRES,
        privateAPI: true
    });
}

export function setAppelsOffres(data) {
    return {
        type: SET_APPELS_OFFRES,
        payload: data
    };
}

export function getLibellePPR() {
    return apiAction({
        url: BACKEND_URL_R_HUMAINE + "/api/v1/ppr/getPpr",
        method: "GET",
        onSuccess: setLibellePPR,
        onFailure: () => null,
        label: SEND_LIBELLE_PPR,
        privateAPI: false
    });
}

export function setLibellePPR(data) {
    return {
        type: SET_LIBELLE_PPR,
        payload: data
    };
}

export function getAppelsOffresPublic() {
    return apiAction({
        url: BACKEND_URL_ACHAT + "/api/v1/questionnaire/public/display/all",
        method: "GET",
        onSuccess: setAppelsOffresPublic,
        onFailure: () => null,
        label: SEND_APPELS_OFFRES_PUBLIC,
        privateAPI: false
    });
}

export function setAppelsOffresPublic(data) {
    return {
        type: SET_APPELS_OFFRES_PUBLIC,
        payload: data
    };
}

export function getAppelQuestions(id) {
    return apiAction({
        url: BACKEND_URL_ACHAT + "/api/v1/questionnaire/public/display/" + id,
        method: "GET",
        onSuccess: setAppelQuestions,
        onFailure: () => null,
        label: SEND_APPEL_OFFRE_QUESTIONS,
        privateAPI: false
    });
}

export function setAppelQuestions(data) {
    return {
        type: SET_APPEL_OFFRE_QUESTIONS,
        payload: data
    };
}

export const configurePasswordFournisseur = (id, password) => {
    return apiAction({
        url:
            BACKEND_URL_ACHAT +
            "/api/v1/inscription/demande/" +
            id +
            "/configure-mdp",
        method: "PUT",
        data: { password },
        onSuccess: setConfigurePasswordFournisseur,
        onFailure: () => null,
        label: CONFIGURE_MDP_FOURNISSEUR
    });
};
export function setConfigurePasswordFournisseur(data) {
    return {
        type: SET_CONFIGURE_MDP_FOURNISSEUR,
        payload: data
    };
}

export function getAllAppelOffre() {
    return apiAction({
        url: BACKEND_URL_ACHAT + "/api/v1/appeloffre/displayAoFournisseut/all",
        method: "GET",
        onSuccess: setAllAppelOffre,
        onFailure: () => null,
        label: GET_APPELS_OFFRE,
        privateAPI: true
    });
}

export function setAllAppelOffre(data) {
    return {
        type: SET_APPELS_OFFRE,
        payload: data
    };
}

export function getAllAppelOffreNC() {
    return apiAction({
        url:
            BACKEND_URL_ACHAT + "/api/v1/appeloffre/displayAoGrandPublique/all",
        method: "GET",
        onSuccess: setAllAppelOffreNC,
        onFailure: () => null,
        label: GET_APPELS_OFFRE_NC,
        privateAPI: false
    });
}
export function setAllAppelOffreNC(data) {
    return {
        type: SET_APPELS_OFFRE_NC,
        payload: data
    };
}

export function getAllMarche(id) {
    return apiAction({
        url: BACKEND_URL_ACHAT + "/api/v1/appeloffre/display/allMarche/" + id,
        method: "GET",
        onSuccess: setAllMarche,
        onFailure: () => null,
        label: GET_MARCHE,
        privateAPI: true
    });
}

export function setAllMarche(data) {
    return {
        type: SET_MARCHE,
        payload: data
    };
}

export const uploadFacture = (id, file) => {
    //isLoadingData_UPLOAD_FACTURE
    return apiAction({
        url: BACKEND_URL_ACHAT + "/api/v1/facture/add/" + id,
        method: "POST",
        data: { file },
        onSuccess: setUploadFacture,
        onFailure: () => null,
        label: UPLOAD_FACTURE
    });
};

export function setUploadFacture(data) {
    //data_upload_facture
    return {
        type: SET_UPLOAD_FACTURE,
        payload: data
    };
}

export const uploadPJ = (id, form) => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/attakmili/file/add/" + id,
        method: "POST",
        data: form,
        onSuccess: setUploadPJ,
        onFailure: () => null,
        label: UPLOAD_PJ
    });
};

export function setUploadPJ(data) {
    return {
        type: SET_UPLOAD_PJ,
        payload: data
    };
}

export function clearUploadPJ() {
    return {
        type: CLEAR_UPLOAD_PJ
    };
}

export const deleteBenef = id => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/attakmili/deletebenefs/" + id,
        method: "DELETE",
        onSuccess: setDeleteBenef,
        onFailure: () => null,
        label: DELETE_BENEF
    });
};

export function setDeleteBenef(data) {
    return {
        type: SET_DELETE_BENEF,
        payload: data
    };
}

export const modifDonnee = form => {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/eclient/coordonnees?" +
            new URLSearchParams(form).toString(),
        method: "PUT",
        onSuccess: setModifDonnee,
        onFailure: () => null,
        label: MODIF_DONNEE
    });
};

export function setModifDonnee(data) {
    return {
        type: SET_MODIF_DONNEE,
        payload: data
    };
}

export function clearModifDonnee() {
    return {
        type: CLEAR_MODIF_DONNEE,
        payload: null
    };
}

export const synchroAttakmili = id => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/synchro/attakmili/afflilie/" + id,
        method: "GET",
        onSuccess: setSynchroAttakmili,
        onFailure: () => null,
        label: SYNCHRO_ATTAKMILI
    });
};

export function setSynchroAttakmili(data) {
    return {
        type: SET_SYNCHRO_ATTAKMILI,
        payload: data
    };
}

export const synchroAttakmiliLiquidation = id => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/synchro/attakmili/liquidation/" + id,
        method: "GET",
        onSuccess: setSynchroAttakmiliLiquidation,
        onFailure: () => null,
        label: SYNCHRO_ATTAKMILI_LIQUIDATION
    });
};

export function setSynchroAttakmiliLiquidation(data) {
    return {
        type: SET_SYNCHRO_ATTAKMILI_LIQUIDATION,
        payload: data
    };
}

export const validerSouscription = id => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/attakmili/metier/add/" + id,
        method: "post",
        onSuccess: setValiderSouscription,
        onFailure: () => null,
        label: VALIDER_SOUSCRIPTION
    });
};

export function setValiderSouscription(data) {
    return {
        type: SET_VALIDER_SOUSCRIPTION,
        payload: data
    };
}

export function clearValiderSouscription() {
    return {
        type: CLEAR_VALIDER_SOUSCRIPTION
    };
}

export function updateBenefs(id, data) {
    return apiAction({
        url: BACKEND_URL + "/api/v1/attakmili/benefs/add-update/" + id,
        method: "POST",
        data: data,
        onSuccess: setUpdateBenefs,
        onFailure: () => null,
        label: UPDATE_BENEFS
    });
}

export function setUpdateBenefs(data) {
    return {
        type: SET_UPDATE_BENEFS,
        payload: data
    };
}

export function clearUpdateBenefs() {
    return {
        type: CLEAR_UPDATE_BENEFS
    };
}

export function updateSouscription(id, data) {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/attakmili/update/souscription-attakmili/" +
            id,
        method: "POST",
        data: data,
        onSuccess: setUpdateSouscription,
        onFailure: () => null,
        label: UPDATE_SOUSCRIPTION
    });
}

export function setUpdateSouscription(data) {
    return {
        type: SET_UPDATE_SOUSCRIPTION,
        payload: data
    };
}

export function clearUpdateSouscription() {
    return {
        type: CLEAR_UPDATE_SOUSCRIPTION
    };
}

export const getBenefs = num => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/attakmili/benefs/" + num,
        method: "GET",
        onSuccess: setBenefs,
        onFailure: () => null,
        label: GET_BENEFS
    });
};

export function setBenefs(data) {
    return {
        type: SET_BENEFS,
        payload: data
    };
}

export const sendOTP = () => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/attakmili/validate-send-otp",
        method: "POST",
        onSuccess: setOTP,
        onFailure: () => null,
        label: SEND_OTP
    });
};

export function setOTP(data) {
    return {
        type: SET_OTP,
        payload: data
    };
}

export function clearAttakOTP() {
    return {
        type: CLEAR_ATTAK_OTP,
        payload: null
    };
}

export const verifyOTP = data => {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/attakmili/verify-otp?" +
            new URLSearchParams(data).toString(),
        method: "POST",
        onSuccess: setVerifyOTP,
        onFailure: () => null,
        label: SEND_VERIFY_ATTAK_OTP
    });
};

export function setVerifyOTP(data) {
    return {
        type: SET_VERIFY_ATTAK_OTP,
        payload: data
    };
}

export function clearVerifyOTP() {
    return {
        type: CLEAR_VERIFY_ATTAK_OTP,
        payload: null
    };
}

export const verifyOTPVer = data => {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/attakmili/validate-send-otp?" +
            new URLSearchParams(data).toString(),
        method: "POST",
        onSuccess: setVerifyOTPVer,
        onFailure: () => null,
        label: SEND_VERIFY_VER_OTP
    });
};

export function setVerifyOTPVer(data) {
    return {
        type: SET_VERIFY_VER_OTP,
        payload: data
    };
}

export function clearVerifyOTPVer() {
    return {
        type: CLEAR_VERIFY_VER_OTP,
        payload: null
    };
}

export const getSouscriptionStatus = () => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/attakmili/affiiation-attakmili",
        method: "GET",
        onSuccess: setSouscriptionStatus,
        onFailure: () => null,
        label: GET_SOUSCRIPTION_STATUS
    });
};

export function setSouscriptionStatus(data) {
    return {
        type: SET_SOUSCRIPTION_STATUS,
        payload: data
    };
}

export const sendVersement = data => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/attakmili/demande-versement/add ",
        method: "POST",
        data: data,
        onSuccess: setVersement,
        onFailure: () => null,
        label: SEND_VERSEMENT
    });
};

export function setVersement(data) {
    return {
        type: SET_VERSEMENT,
        payload: data
    };
}

export function clearVersement() {
    return {
        type: CLEAR_VERSEMENT,
        payload: null
    };
}

export const getPensDonnees = num => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/pension/data?numPension=" + num,
        method: "GET",
        onSuccess: setPensDonnees,
        onFailure: () => null,
        label: GET_PENS_DONNEES
    });
};

export function setPensDonnees(data) {
    return {
        type: SET_PENS_DONNEES,
        payload: data
    };
}

export const validerInterruption = id => {
    return apiAction({
        url:
            BACKEND_URL + "/api/v1/attakmili/metier/demande-interruption/" + id,
        method: "POST",
        onSuccess: setValiderInterruption,
        onFailure: () => null,
        label: VALIDER_INTERRUPTION
    });
};

export function setValiderInterruption(data) {
    return {
        type: SET_VALIDER_INTERRUPTION,
        payload: data
    };
}

export function clearValiderInterruption() {
    return {
        type: CLEAR_VALIDER_INTERRUPTION
    };
}

export const validerReprise = id => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/attakmili/metier/demande-reprise/" + id,
        method: "POST",
        onSuccess: setValiderReprise,
        onFailure: () => null,
        label: VALIDER_REPRISE
    });
};

export function setValiderReprise(data) {
    return {
        type: SET_VALIDER_REPRISE,
        payload: data
    };
}

export function clearValiderReprise() {
    return {
        type: CLEAR_VALIDER_REPRISE
    };
}

export const metierVer = form => {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/attakmili/demande-versement/siMetier?" +
            new URLSearchParams(form).toString(),
        method: "POST",
        onSuccess: setMetierVer,
        onFailure: () => null,
        label: METIER_VER
    });
};

export function setMetierVer(data) {
    return {
        type: SET_METIER_VER,
        payload: data
    };
}

export function clearMetierVer() {
    return {
        type: CLEAR_METIER_VER
    };
}

export const getSliders = () => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/slider/all/enabled",
        method: "GET",
        onSuccess: setSliders,
        onFailure: () => null,
        label: GET_SLIDERS,
        privateAPI: false
    });
};

export function setSliders(data) {
    return {
        type: SET_SLIDERS,
        payload: data
    };
}

export const addDemandeInterruption = (data, id) => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/attakmili/demande-interruption/add/" + id,
        method: "POST",
        data: data,
        onSuccess: setDemandeInterruption,
        onFailure: () => null,
        label: DEMANDE_INTERRUPTION
    });
};

export function setDemandeInterruption(data) {
    return {
        type: SET_DEMANDE_INTERRUPTION,
        payload: data
    };
}

export function clearDemandeInterruption() {
    return {
        type: CLEAR_DEMANDE_INTERRUPTION
    };
}

export const addDemandeReprise = (data, id) => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/attakmili/demande-reprise/add/" + id,
        method: "POST",
        data: data,
        onSuccess: setDemandeReprise,
        onFailure: () => null,
        label: DEMANDE_REPRISE
    });
};

export function setDemandeReprise(data) {
    return {
        type: SET_DEMANDE_REPRISE,
        payload: data
    };
}

export function clearDemandeReprise() {
    return {
        type: CLEAR_DEMANDE_REPRISE
    };
}

export function setIdSouscription(data) {
    return {
        type: SET_ID_SOUSCRIPTION,
        payload: data
    };
}

export function getAllFactureFournisseur(id) {
    return apiAction({
        url: BACKEND_URL_ACHAT + "/api/v1/facture/display/allFacture/" + id,
        method: "GET",
        onSuccess: setAllFactureFournisseur,
        onFailure: () => null,
        label: GET_FACTURE_FOURNISSEUR,
        privateAPI: true
    });
}

export function setAllFactureFournisseur(data) {
    return {
        type: SET_FACTURE_FOURNISSEUR,
        payload: data
    };
}

export function checkAuthenticite(cin, code, montant) {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/attestation/check-authenticite?cin=" +
            cin +
            "&code=" +
            code +
            "&montant=" +
            montant,
        method: "GET",
        onSuccess: setCheckAuthenticite,
        onFailure: () => null,
        label: CHECK_AUTHENTICITE,
        privateAPI: false
    });
}

export function setCheckAuthenticite(data) {
    return {
        type: SET_CHECK_AUTHENTICITE,
        payload: data
    };
}

export function clearCheckAuthenticite() {
    return {
        type: CLEAR_CHECK_AUTHENTICITE
    };
}

export function getAttakmiliInfo() {
    return apiAction({
        url: BACKEND_URL + "/api/v1/attakmili/user_info",
        method: "GET",
        onSuccess: setAttakmiliInfo,
        onFailure: () => null,
        label: GET_ATTAKMILI_INFO
    });
}

export function setAttakmiliInfo(data) {
    return {
        type: SET_ATTAKMILI_INFO,
        payload: data
    };
}

export function getNotif() {
    return apiAction({
        url: BACKEND_URL + "/api/v1/notification/get",
        method: "GET",
        onSuccess: setNotif,
        onFailure: () => null,
        label: GET_NOTIF
    });
}

export function setNotif(data) {
    return {
        type: SET_NOTIF,
        payload: data
    };
}

export function getInbox(cin) {
    return apiAction({
        url: BACKEND_URL + "/api/v1/dynamics/interaction?cin=" + cin,
        method: "GET",
        onSuccess: setInbox,
        onFailure: () => null,
        label: GET_INBOX
    });
}

export function setInbox(data) {
    return {
        type: SET_INBOX,
        payload: data
    };
}

export function answerQuestionnaire(data) {
    return apiAction({
        url: BACKEND_URL_ACHAT + "/api/v1/questionnaire/answer",
        data: data,
        method: "POST",
        onSuccess: setAnswerQuestionnaire,
        onFailure: () => null,
        label: SEND_ANSWER_QUESTIONNAIRE
    });
}

export function setAnswerQuestionnaire(data) {
    return {
        type: SET_ANSWER_QUESTIONNAIRE,
        payload: data
    };
}

export function getNumAffiliation(cin, email, nomComplet, phone) {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/eclient/affilation-attakmili?cin=" +
            cin +
            "&email=" +
            email +
            "&nomComplet=" +
            nomComplet +
            "&phone=" +
            phone,
        method: "GET",
        onSuccess: setGetNumAffiliation,
        onFailure: () => null,
        label: GET_NUM_AFFILIATION,
        privateAPI: false
    });
}

export function setGetNumAffiliation(data) {
    return {
        type: SET_NUM_AFFILIATION,
        payload: data
    };
}

export function clearNumAffiliation() {
    return {
        type: CLEAR_NUM_AFFILIATION
    };
}

export function resetPwd(data) {
    return apiAction({
        url: BACKEND_URL + "/api/v1/eclient/reset-pass",
        method: "PUT",
        onSuccess: setResetPassword,
        data: data,
        onFailure: () => null,
        label: SEND_RESET_PASSWORD,
        privateAPI: false
    });
}

export function setResetPassword(data) {
    return {
        type: SET_RESET_PASSWORD,
        payload: data
    };
}

export function clearResetPassword() {
    return {
        type: CLEAR_RESET_PASSWORD
    };
}

export function getPieces() {
    return apiAction({
        url: BACKEND_URL + "/api/v1/pieces-fournir/all/enabled",
        method: "GET",
        onSuccess: setPieces,
        onFailure: () => null,
        label: GET_PIECES,
        privateAPI: false
    });
}

export function setPieces(data) {
    return {
        type: SET_PIECES,
        payload: data
    };
}

export function getPhotos() {
    return apiAction({
        url: BACKEND_URL + "/api/v1/phototheque/get/allEnabled",
        method: "GET",
        onSuccess: setPhotos,
        onFailure: () => null,
        label: GET_PHOTOS,
        privateAPI: false
    });
}

export function setPhotos(data) {
    return {
        type: SET_PHOTOS,
        payload: data
    };
}

export function getAppelsFournisseur() {
    return apiAction({
        url: BACKEND_URL_ACHAT + "/api/v1/fournisseur/myappeloffres",
        method: "GET",
        onSuccess: setAppelsFournisseur,
        onFailure: () => null,
        label: GET_APPELS_FOURNISSEUR,
        privateAPI: true
    });
}

export function setAppelsFournisseur(data) {
    return {
        type: SET_APPELS_FOURNISSEUR,
        payload: data
    };
}

export function getFacturesByAppel(id) {
    return apiAction({
        url:
            BACKEND_URL_ACHAT +
            "/api/v1/facture/display/allFactureByAppelOffre/" +
            id,
        method: "GET",
        onSuccess: setFacturesByAppel,
        onFailure: () => null,
        label: GET_FACTURE_APPEL
    });
}

export function setFacturesByAppel(data) {
    return {
        type: SET_FACTURE_APPEL,
        payload: data
    };
}

export function addFacture(formData) {
    return apiAction({
        url: BACKEND_URL_ACHAT + "/api/v1/facture/add",
        method: "POST",
        data: formData,
        onSuccess: setFacture,
        onFailure: () => null,
        label: ADD_FACTURE
    });
}

export function setFacture(data) {
    return {
        type: SET_FACTURE,
        payload: data
    };
}

export function addReclamation(data) {
    return apiAction({
        url: BACKEND_URL + "/api/v1/dynamics/incident",
        method: "POST",
        data: data,
        onSuccess: setReclamation,
        onFailure: () => null,
        label: ADD_RECLAMATION
    });
}

export function setReclamation(data) {
    return {
        type: SET_RECLAMATION,
        payload: data
    };
}

export function clearReclamation() {
    return {
        type: SET_RECLAMATION,
        payload: null
    };
}
export function getReclamation(form) {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/dynamics/incident?" +
            new URLSearchParams(form).toString(),
        method: "GET",
        data: form,
        onSuccess: setGetReclamation,
        onFailure: () => null,
        label: GET_RECLAMATION
    });
}

export function setGetReclamation(data) {
    return {
        type: SET_GET_RECLAMATION,
        payload: data
    };
}

export function getMotifs() {
    return apiAction({
        url: BACKEND_URL + "/api/v1/dynamics/motifsBySubjectId",
        method: "GET",
        onSuccess: setMotifs,
        onFailure: () => null,
        label: GET_MOTIFS
    });
}

export function setMotifs(data) {
    return {
        type: SET_MOTIFS,
        payload: data
    };
}

export function configurePwd(data, idClient) {
    return apiAction({
        url:
            BACKEND_URL_ACHAT +
            "/api/v1/inscription/demande/" +
            idClient +
            "/configure-mdp",
        method: "PUT",
        data: data,
        onSuccess: setPwd,
        onFailure: () => null,
        label: CONFIGURE_PWD
    });
}

export function setPwd(data) {
    return {
        type: SET_CONFIGURE_PWD,
        payload: data
    };
}

export function updateDonnees(data) {
    return apiAction({
        url: BACKEND_URL_ACHAT + "/api/v1/fournisseur/update",
        method: "PUT",
        data: data,
        onSuccess: setDonnees,
        onFailure: () => null,
        label: UPDATE_DONNEES
    });
}

export function setDonnees(data) {
    return {
        type: SET_DONNEES,
        payload: data
    };
}

export function addPlaquette(id, formData) {
    return apiAction({
        url: BACKEND_URL_ACHAT + "/api/v1/inscription/add/plaquette/" + id,
        method: "PUT",
        data: formData,
        onSuccess: setPlaquette,
        onFailure: () => null,
        label: ADD_PLAQUETTE
    });
}

export function setPlaquette(data) {
    return {
        type: SET_PLAQUETTE,
        payload: data
    };
}

export function resetPwdFournisseur(data) {
    return apiAction({
        url: BACKEND_URL_ACHAT + "/api/v1/auth/change-passwordOublie",
        method: "PUT",
        data: data,
        onSuccess: setResetPwd,
        onFailure: () => null,
        label: RESET_PWD
    });
}

export function setResetPwd(data) {
    return {
        type: SET_RESET_PWD,
        payload: data
    };
}

export function getLiens() {
    return apiAction({
        url: BACKEND_URL_ACHAT + "/api/v1/lien/getAll",
        method: "GET",
        onSuccess: setLiens,
        onFailure: () => null,
        label: GET_LIENS,
        privateAPI: false
    });
}

export function setLiens(data) {
    return {
        type: SET_LIENS,
        payload: data
    };
}

export const getAllFactures = () => {
    return apiAction({
        url: BACKEND_URL_ACHAT + "/api/v1/facture/displayall",
        method: "GET",
        onSuccess: setAllFactures,
        onFailure: () => null,
        label: GET_FACTURES,
        privateAPI: true
    });
};

export function setAllFactures(data) {
    return {
        type: SET_FACTURES,
        payload: data
    };
}

export const simulerCapital = (cotisation, ageAffilie, ageRetraite) => {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/simulation/simulation-attakmili-capital/" +
            cotisation +
            "/" +
            ageAffilie +
            "/" +
            ageRetraite,
        method: "GET",
        onSuccess: setSimulationCapital,
        onFailure: () => null,
        label: SIMULER_CAPITAL
    });
};

export function setSimulationCapital(data) {
    return {
        type: SET_SIMULATION_CAPITAL,
        payload: data
    };
}

export const simulerRente = (
    cotisation,
    ageAffilie,
    ageRetraite,
    periode,
    annee
) => {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/simulation/simulation-attakmili-rente/" +
            cotisation +
            "/" +
            ageAffilie +
            "/" +
            ageRetraite +
            "/" +
            periode +
            "/" +
            annee,
        method: "GET",
        onSuccess: setSimulationRente,
        onFailure: () => null,
        label: SIMULER_RENTE
    });
};

export function setSimulationRente(data) {
    return {
        type: SET_SIMULATION_RENTE,
        payload: data
    };
}

export const simulerCapitalAvance = (
    cotisation,
    ageAffilie,
    ageRetraite,
    periode,
    versement,
    apport,
    autreApport
) => {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/simulation/simulation-avancee-attakmili-capital/" +
            cotisation +
            "/" +
            ageAffilie +
            "/" +
            ageRetraite +
            "/" +
            periode +
            "/" +
            versement +
            "/" +
            apport +
            "/" +
            autreApport,
        method: "GET",
        onSuccess: setSimulationCapitalAvance,
        onFailure: () => null,
        label: SIMULER_CAPITAL_AVANCE
    });
};

export function setSimulationCapitalAvance(data) {
    return {
        type: SET_SIMULATION_CAPITAL_AVANCE,
        payload: data
    };
}

export const simulerRenteAvance = (
    cotisation,
    ageAffilie,
    ageRetraite,
    periode,
    versement,
    apport,
    autreApport,
    annee
) => {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/simulation/simulation-avancee-attakmili-rente/" +
            cotisation +
            "/" +
            ageAffilie +
            "/" +
            ageRetraite +
            "/" +
            periode +
            "/" +
            versement +
            "/" +
            apport +
            "/" +
            autreApport +
            "/" +
            annee,

        method: "GET",
        onSuccess: setSimulationRenteAvance,
        onFailure: () => null,
        label: SIMULER_RENTE_AVANCE
    });
};

export function setSimulationRenteAvance(data) {
    return {
        type: SET_SIMULATION_RENTE_AVANCE,
        payload: data
    };
}

export const simulerCapitalNonConnecte = (
    cotisation,
    ageAffilie,
    ageRetraite
) => {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/simulation/simulation-attakmili-capital-nonConnecter/" +
            cotisation +
            "/" +
            ageAffilie +
            "/" +
            ageRetraite,
        method: "GET",
        onSuccess: setSimulationCapital,
        onFailure: () => null,
        privateAPI: false,
        label: SIMULER_CAPITAL
    });
};

export const simulerRenteNonConnecte = (
    cotisation,
    ageAffilie,
    ageRetraite,
    annee,
    ans
) => {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/simulation/simulation-attakmili-rente-nonConnecter/" +
            cotisation +
            "/" +
            ageAffilie +
            "/" +
            ageRetraite +
            "/" +
            annee +
            "/" +
            ans,
        method: "GET",
        onSuccess: setSimulationRente,
        onFailure: () => null,
        privateAPI: false,
        label: SIMULER_RENTE
    });
};

export const simulerCapitalAvanceNonConnecte = (
    cotisation,
    ageAffilie,
    ageRetraite,
    periode,
    versement,
    apport
) => {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/simulation/simulation-avancee-attakmili-capital-nonConnecter/" +
            cotisation +
            "/" +
            ageAffilie +
            "/" +
            ageRetraite +
            "/" +
            periode +
            "/" +
            versement +
            "/" +
            apport,
        method: "GET",
        onSuccess: setSimulationCapitalAvance,
        onFailure: () => null,
        privateAPI: false,
        label: SIMULER_CAPITAL_AVANCE
    });
};

export const simulerRenteAvanceNonConnecte = (
    cotisation,
    ageAffilie,
    ageRetraite,
    periode,
    versement,
    apport,
    annee
) => {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/simulation/simulation-avancee-attakmili-rente-nonConnecter/" +
            cotisation +
            "/" +
            ageAffilie +
            "/" +
            ageRetraite +
            "/" +
            periode +
            "/" +
            versement +
            "/" +
            apport +
            "/" +
            annee,
        method: "GET",
        onSuccess: setSimulationRenteAvance,
        onFailure: () => null,
        privateAPI: false,
        label: SIMULER_RENTE_AVANCE
    });
};

export const simulerCapitalInverse = (montant, ageAffilie, ageRetraite) => {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/simulation/simulation-inverse-attakmili-capital/" +
            montant +
            "/" +
            ageAffilie +
            "/" +
            ageRetraite,
        method: "GET",
        onSuccess: setSimulationCapitalInverse,
        onFailure: () => null,
        label: SIMULER_CAPITAL_INVERSE
    });
};

export function setSimulationCapitalInverse(data) {
    return {
        type: SET_SIMULATION_CAPITAL_INVERSE,
        payload: data
    };
}

export const simulerRenteInverse = (ageAffilie, ageRetraite, data) => {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/simulation/simulation-inverse-attakmili-rente/" +
            ageAffilie +
            "/" +
            ageRetraite,
        data: data,
        method: "POST",
        onSuccess: setSimulationRenteInverse,
        onFailure: () => null,
        label: SIMULER_RENTE_INVERSE
    });
};

export function setSimulationRenteInverse(data) {
    return {
        type: SET_SIMULATION_RENTE_INVERSE,
        payload: data
    };
}

export const getPenDetail = numPen => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/pension/details/?numPension=" + numPen,
        onSuccess: setPenDetail,
        onFailure: () => null,
        label: SEND_PENSION_DETAIL
    });
};

export function setPenDetail(data) {
    return {
        type: SET_PENSION_DETAIL,
        payload: data
    };
}

export const getPenPaimentDetail = numPen => {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/pension/paiement-details?numPension=" +
            numPen,
        onSuccess: setPenPaiementDetail,
        onFailure: () => null,
        label: SEND_PEN_PAIMENT_DETAIL
    });
};

export function setPenPaiementDetail(data) {
    return {
        type: SET_PEN_PAIMENT_DETAIL,
        payload: data
    };
}

export const getPenEnfantDetail = numPen => {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/pension/enfants-details/?numPension=" +
            numPen,
        onSuccess: setPenEnfantDetail,
        onFailure: () => null,
        label: SEND_PEN_ENFANT_DETAIL
    });
};

export function setPenEnfantDetail(data) {
    return {
        type: SET_PEN_ENFANT_DETAIL,
        payload: data
    };
}

export const getPenAttakDetail = numPen => {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/pension/pension-attakmili?numPension=" +
            numPen,
        onSuccess: setPenAttakDetail,
        onFailure: () => null,
        label: SEND_PEN_ATTAK_DETAIL
    });
};

export function setPenAttakDetail(data) {
    return {
        type: SET_PEN_ATTAK_DETAIL,
        payload: data
    };
}

export const getAllEvents = (num, data) => {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/demande/events/" +
            num +
            "?typeClient=" +
            data,
        method: "GET",
        onSuccess: setAllEvents,
        onFailure: () => null,
        label: GET_EVENTS,
        privateAPI: true
    });
};

export function setAllEvents(data) {
    return {
        type: SET_EVENTS,
        payload: data
    };
}

export const getAllNotifs = () => {
    return apiAction({
        url: BACKEND_URL_ACHAT + "/api/v1/notification/get",
        method: "GET",
        onSuccess: setAllNotifs,
        onFailure: () => null,
        label: GET_NOTIFS,
        privateAPI: true
    });
};

export function setAllNotifs(data) {
    return {
        type: SET_NOTIFS,
        payload: data
    };
}

export const openNotif = () => {
    return apiAction({
        url: BACKEND_URL_ACHAT + "/api/v1/notification/change/statut",
        method: "GET",
        onSuccess: setOpenNotif,
        onFailure: () => null,
        label: OPEN_NOTIF,
        privateAPI: true
    });
};

export function setOpenNotif(data) {
    return {
        type: SET_OPEN_NOTIF,
        payload: data
    };
}

export const getAllRencontres = () => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/rencontre-scientifique/all/enabled",
        method: "GET",
        onSuccess: setGetRencontres,
        onFailure: () => null,
        label: GET_ALL_RENCONTRES,
        privateAPI: false
    });
};

export function setGetRencontres(data) {
    return {
        type: SET_ALL_RENCONTRES,
        payload: data
    };
}

export const submitSurvey = data => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/survey/add",
        method: "POST",
        data,
        onSuccess: setSubmitSurvey,
        onFailure: () => null,
        label: SEND_SURVEY,
        privateAPI: false
    });
};

export function setSubmitSurvey(data) {
    return {
        type: SET_SURVEY,
        payload: data
    };
}

export const getAds = data => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/pub/get",
        method: "GET",
        data,
        onSuccess: setAds,
        onFailure: () => null,
        label: SEND_ADS,
        privateAPI: false
    });
};

export function setAds(data) {
    return {
        type: SET_ADS,
        payload: data
    };
}

export function setSim(data) {
    return {
        type: SET_SIM,
        payload: data
    };
}

export function setBack(data) {
    return {
        type: SET_BACK,
        payload: data
    };
}

export const sendData = data => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/grand-public/add",
        method: "POST",
        data: data,
        onSuccess: setData,
        onFailure: () => null,
        label: SEND_DATA,
        privateAPI: false
    });
};

export function setData(data) {
    return {
        type: SET_DATA,
        payload: data
    };
}

export const getServicesChauds = () => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/prestation/param/prio",
        method: "GET",
        onSuccess: setServicesChauds,
        onFailure: () => null,
        label: GET_CHAUDS,
        privateAPI: false
    });
};

export function setServicesChauds(data) {
    return {
        type: SET_CHAUDS,
        payload: data
    };
}

export const getInfoEpargne = numaffilie => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/simulation/infoEpargne/" + numaffilie,
        method: "GET",
        onSuccess: setInfoEpargne,
        onFailure: () => null,
        label: GET_INFO_EPARGNE
    });
};

export function setInfoEpargne(data) {
    return {
        type: SET_INFO_EPARGNE,
        payload: data
    };
}
export function setDataVersemnt(data) {
    return {
        type: SET_DATA_VERSEMENT,
        payload: data
    };
}

export const evaluatePresta = data => {
    return apiAction({
        url: BACKEND_URL + "/api/v1/survey/evaluate-presta",
        method: "POST",
        data: data,
        onSuccess: setEvaluatePresta,
        onFailure: () => null,
        label: EVALUATE_PRESTA
    });
};

export function setEvaluatePresta(data) {
    return {
        type: SET_EVALUATE_PRESTA,
        payload: data
    };
}

export function simulationCombine(
    ageALaRetraite,
    ageActuelle,
    montantSouhaite
) {
    return apiAction({
        url:
            BACKEND_URL +
            "/api/v1/simulation//simulation-combine/" +
            ageALaRetraite +
            "/" +
            ageActuelle +
            "/" +
            montantSouhaite,
        method: "GET",
        onSuccess: setSimulationCombine,
        onFailure: () => null,
        label: SIMULATION_COMBINE
    });
}
export function setSimulationCombine(data) {
    return {
        type: SET_SIMULATION_COMBINE,
        payload: data
    };
}

export function getTauxBo() {
    return apiAction({
        url: BACKEND_URL + "/api/v1/simulation/getTaux",
        method: "GET",
        onSuccess: setTauxBo,
        onFailure: () => null,
        label: GET_TAUX_BO
    });
}
export function setTauxBo(data) {
    return {
        type: SET_TAUX_BO,
        payload: data
    };
}

export function sendJournaliste(data) {
    return apiAction({
        url: BACKEND_URL + "/api/v1/media",
        method: "POST",
        data: data,
        onSuccess: setJournaliste,
        onFailure: () => null,
        label: SEND_JOURNALISTE
    });
}
export function setJournaliste(data) {
    return {
        type: SET_JOURNALISTE,
        payload: data
    };
}

export const setPaimentMode = mode => ({
    type: PAIMENT_MODE,
    payload: mode
});
export const setUnopnnedNotif = mode => ({
    type: UNOPPENED_NOTIF,
    payload: mode
});

export function changeStatus() {
    return apiAction({
        url: BACKEND_URL + "/api/v1/notification/change/statut",
        method: "GET",
        onSuccess: setChangeStatus,
        onFailure: () => null,
        label: CHANGE_STATUS_NOTIF
    });
}
export function setChangeStatus(data) {
    return {
        type: SET_CHANGE_STATUS_NOTIF,
        payload: data
    };
}

/*********************************************************************/
/*******************  TANDEM     ***************************/
/*********************************************************************/

export const SEND_CUSTOM_FILE = "SEND_CUSTOM_FILE";
export const SET_CUSTOM_FILE = "SET_CUSTOM_FILE";
export const CLEAR_CUSTOM_FILE = "CLEAR_CUSTOM_FILE";

export const SEND_FILE_CHEQUE = "SEND_FILE_CHEQUE";
export const SET_FILE_CHEQUE = "SET_FILE_CHEQUE";
export const CLEAR_FILE_CHEQUE = "CLEAR_FILE_CHEQUE";

export const SEND_DOC_STRUCTURE = "SEND_DOC_STRUCTURE";
export const SET_DOC_STRUCTURE = "SET_DOC_STRUCTURE";
export const CLEAR_DOC_STRUCTURE = "CLEAR_DOC_STRUCTURE";

export const SEND_DATA_CHAT = "SEND_DATA_CHAT";
export const SET_DATA_CHAT = "SET_DATA_CHAT";
export const CLEAR_DATA_CHAT = "CLEAR_DATA_CHAT";

export const SEND_TRAITER_DOSSIER = "SEND_TRAITER_DOSSIER";
export const SET_TRAITER_DOSSIER = "SET_TRAITER_DOSSIER";
export const CLEAR_TRAITER_DOSSIER = "CLEAR_TRAITER_DOSSIER";

export const COMPARER_SIGNATURE = "COMPARER_SIGNATURE";
export const SET_COMPARER_SIGNATURE = "SET_COMPARER_SIGNATURE";
export const CLEAR_COMPARER_SIGNATURE = "CLEAR_COMPARER_SIGNATURE";

//data_TRAITER_DOSSIER
//isLoadingData_SEND_TRAITER_DOSSIER
export function sendCustomFile(data) {
    return apiAction({
        url: "https://tandeem.seven-app.org/upload_custom",
        method: "POST",
        data: data,
        onSuccess: setCustomFile,
        onFailure: () => null,
        label: SEND_CUSTOM_FILE
    });
}
export function setCustomFile(data) {
    return {
        type: SET_CUSTOM_FILE,
        payload: data
    };
}
export function clearCustomFile(data) {
    return {
        type: CLEAR_CUSTOM_FILE,
        payload: data
    };
}

export function sendFileCheque(data) {
    return apiAction({
        url: "https://tandeem.seven-app.org/upload_cheque",
        method: "POST",
        data: data,
        onSuccess: setFileCheque,
        onFailure: () => null,
        label: SEND_FILE_CHEQUE
    });
}
export function setFileCheque(data) {
    return {
        type: SET_FILE_CHEQUE,
        payload: data
    };
}
export function clearFileCheque(data) {
    return {
        type: CLEAR_FILE_CHEQUE,
        payload: data
    };
}

export function comparerSignature(data) {
    return apiAction({
        url: "https://tandeem.seven-app.org/compare_signatures",
        method: "POST",
        data: data,
        onSuccess: setComparerSignature,
        onFailure: () => null,
        label: COMPARER_SIGNATURE
    });
}
export function setComparerSignature(data) {
    return {
        type: SET_COMPARER_SIGNATURE,
        payload: data
    };
}
export function clearComparerSignature(data) {
    return {
        type: CLEAR_COMPARER_SIGNATURE,
        payload: data
    };
}

export function sendDocStructure(data) {
    return apiAction({
        url: "https://tandeem.seven-app.org/upload",
        method: "POST",
        data: data,
        onSuccess: setDocStructure,
        onFailure: () => null,
        label: SEND_DOC_STRUCTURE
    });
}
export function setDocStructure(data) {
    return {
        type: SET_DOC_STRUCTURE,
        payload: data
    };
}
export function clearDocStructure(data) {
    return {
        type: CLEAR_DOC_STRUCTURE,
        payload: data
    };
}

export function sendDataChat(data) {
    return apiAction({
        url: "https://tandeem.seven-app.org/upload_chat",
        method: "POST",
        data: data,
        onSuccess: setDataChat,
        onFailure: () => null,
        label: SEND_DATA_CHAT
    });
}
export function setDataChat(data) {
    return {
        type: SET_DATA_CHAT,
        payload: data
    };
}
export function clearDataChat(data) {
    return {
        type: CLEAR_DATA_CHAT,
        payload: data
    };
}

export function sendTraiterDossier(data) {
    return apiAction({
        url: "https://tandeem.seven-app.org/upload2",
        method: "POST",
        data: data,
        onSuccess: setTraiterDossier,
        onFailure: () => null,
        label: SEND_TRAITER_DOSSIER
    });
}
export function setTraiterDossier(data) {
    return {
        type: SET_TRAITER_DOSSIER,
        payload: data
    };
}
export function clearTraiterDossier(data) {
    return {
        type: CLEAR_TRAITER_DOSSIER,
        payload: data
    };
}

function apiAction({
    url = "",
    method = "GET",
    privateAPI = true,
    data = null,
    accessToken = null,
    onSuccess = () => {},
    onFailure = () => {},
    label = "",
    headersOverride = null
}) {
    return {
        type: API,
        payload: {
            url,
            method,
            data,
            accessToken,
            onSuccess,
            onFailure,
            label,
            headersOverride,
            privateAPI
        }
    };
}
