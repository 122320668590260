import React, { useEffect } from "react";

const ChatBotCGI = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://cdn.botpress.cloud/webchat/v1/inject.js";
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            window.botpressWebChat.init({
                composerPlaceholder: "Découvrons la CGI. Parlez-moi !",
                botConversationDescription: "Disponible ✅",
                botId: "7ec60f23-7077-42ee-b64f-c9e07d4c487e",
                hostUrl: "https://cdn.botpress.cloud/webchat/v1",
                messagingUrl: "https://messaging.botpress.cloud",
                clientId: "7ec60f23-7077-42ee-b64f-c9e07d4c487e",
                webhookId: "76d2d41f-fc98-4484-9b21-bc2f208b7fcf",
                lazySocket: true,
                themeName: "prism",
                botName: "CGI Bot 🏠",
                avatarUrl:
                    "https://upload.wikimedia.org/wikipedia/fr/d/df/CGI_logo.gif",
                frontendVersion: "v1",
                showPoweredBy: true,
                theme: "prism",
                themeColor: "#2563eb",
                allowedOrigins: [],
                config: {
                    startOpen: true
                }
            });
            setTimeout(() => {
                if (window.botpressWebChat) {
                    window.botpressWebChat.sendEvent({ type: "show" });
                } else {
                    console.log("Botpress WebChat is not defined");
                }
            }, 100);
        };
    }, []);

    return <div id="webchat" />;
};

export default ChatBotCGI;
