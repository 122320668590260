import axios from "axios";
import {
    BACKEND_URL,
    BACKEND_URL_ACHAT,
    BACKEND_URL_R_HUMAINE
} from "../redux/actions/api";
import {
    AFFILIATION_SERVICES,
    ATTAKMILI_COMPLEMENTAIRE,
    ATTAKMILI_SERVICES,
    PENSION_DE_RESISTANT_URL,
    PENSION_DE_RETRAITE_DINVALIDITE_URL,
    PENSION_DE_RETRAITE_PRINCIPALE_URL,
    PENSION_DE_REVERSION_URL
} from "../constants/routes";

export const isName = name => !!name.match(/^[a-z]+[a-z à-ü\-\s]*$/i);
export const isCINE = cine => !!cine.match(/^[a-z]{1,2}[0-9]{2,8}$/i);
export const isTelValid = num => !!num.match(/0[0-9]{9}$/);
export const isEmailValid = mail =>
    !!mail.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);

export const sorterTable = (val1, val2) => {
    if (val1 === null) {
        return 1;
    } else if (val2 === null) {
        return -1;
    } else {
        return val1.localeCompare(val2);
    }
};

export const sorterTableNumber = (val1, val2) => {
    if (val1 === null) {
        return 1;
    } else if (val2 === null) {
        return -1;
    } else {
        return val1 > val2;
    }
};

export const convertDate = (date, ar) => {
    try {
        const dateObject = new Date(date);
        const options = {
            day: "2-digit",
            month: "2-digit",
            year: "numeric"
        };
        return dateObject.toLocaleString(ar === 1 ? "ar" : "fr", options);
    } catch (e) {
        return null;
    }
};

export const download = id => docType => {
    const cnxAccessToken = localStorage.getItem("token")
        ? localStorage.getItem("token")
        : "";
    axios.defaults.headers.common["Authorization"] = `Bearer ${cnxAccessToken}`;
    axios({
        url: BACKEND_URL + "/api/v1/" + docType + "/download?idAlfresco=" + id, // url
        method: "GET",
        responseType: "blob" // important
    }).then(response => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Document.pdf");
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
};

export const downloadAttestation = (form, set = console.log("")) => {
    const cnxAccessToken = localStorage.getItem("token")
        ? localStorage.getItem("token")
        : "";
    axios.defaults.headers.common["Authorization"] = `Bearer ${cnxAccessToken}`;
    axios({
        url:
            BACKEND_URL +
            "/api/v1/attestation/download?" +
            new URLSearchParams(form).toString(), // url
        method: "GET",
        responseType: "blob" // important
    }).then(response => {
        const contentDispositionHeader =
            response.headers["content-disposition"];
        const filenameMatch = contentDispositionHeader?.match(/filename=(.+)/);
        let filename = "attestation.pdf"; // Default filename if not found in header change extention
        if (filenameMatch && filenameMatch.length > 1) {
            filename = filenameMatch[1].replace(/"/g, "");
        }
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        set(false);
    });
};

export const downloadDocs = form => {
    // const cnxAccessToken = localStorage.getItem("token")
    //     ? localStorage.getItem("token")
    //     : "";
    // axios.defaults.headers.common["Authorization"] = `Bearer ${cnxAccessToken}`;
    axios({
        url:
            BACKEND_URL +
            "/api/v1/document/download?" +
            new URLSearchParams(form).toString(), // url
        method: "GET",
        responseType: "blob" // important
    }).then(response => {
        const contentDispositionHeader =
            response.headers["content-disposition"];
        const filenameMatch = contentDispositionHeader?.match(/filename=(.+)/);
        let filename = "doc.pdf"; // Default filename if not found in header change extention
        // if (filenameMatch && filenameMatch.length > 1) {
        //     filename = filenameMatch[1].replace(/"/g, "");
        // }
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
};

export const downloadDemande = (id, name, extension) => {
    const cnxAccessToken = localStorage.getItem("token")
        ? localStorage.getItem("token")
        : "";
    axios.defaults.headers.common["Authorization"] = `Bearer ${cnxAccessToken}`;
    axios({
        url: BACKEND_URL + "/api/v1/demande/download/" + id, // url
        method: "GET",
        responseType: "blob" // important
    }).then(response => {
        const contentDispositionHeader =
            response.headers["content-disposition"];
        const filenameMatch = contentDispositionHeader.match(/filename=(.+)/);
        let filename = `${name}.${extension}`; // Default filename if not found in header change extention
        // if (filenameMatch && filenameMatch.length > 1) {
        //     filename = filenameMatch[1].replace(/"/g, "");
        // }
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
};

export const afterLoginRedirection = (type, position) => {
    if (type.numAffilie && position !== "FIVE") return AFFILIATION_SERVICES;
    else if (type.numsTypePensions.length) {
        const pen = type.numsTypePensions[0];
        switch (pen.typePension) {
            case "PENSION_DE_RETRAITE_PRINCIPALE":
                return PENSION_DE_RETRAITE_PRINCIPALE_URL;
            case "PENSION_DE_RETRAITE_DINVALIDITE":
                return PENSION_DE_RETRAITE_DINVALIDITE_URL;
            case "PENSION_DE_REVERSION":
                return PENSION_DE_REVERSION_URL;
            case "PENSION_DE_RESISTANT":
                return PENSION_DE_RESISTANT_URL;
        }
    } else {
        return ATTAKMILI_SERVICES;
    }
};

export const downloadAO = id => {
    /*  const cnxAccessToken = localStorage.getItem("token")
        ? localStorage.getItem("token")
        : "";
    axios.defaults.headers.common["Authorization"] = `Bearer ${cnxAccessToken}`; */
    axios({
        url: BACKEND_URL_ACHAT + "/api/v1/appeloffre/download/" + id, // url
        method: "GET",
        responseType: "blob" // important
    }).then(response => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Document.pdf");
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
};
export const downloadFacture = id => {
    /*  const cnxAccessToken = localStorage.getItem("token")
        ? localStorage.getItem("token")
        : "";
    axios.defaults.headers.common["Authorization"] = `Bearer ${cnxAccessToken}`; */
    axios({
        url: BACKEND_URL_ACHAT + "/api/v1/facture/download/" + id, // url
        method: "GET",
        responseType: "blob" // important
    }).then(response => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Document.pdf");
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
};

export const downloadPj = id => {
    const cnxAccessToken = localStorage.getItem("token") || "";

    if (cnxAccessToken) {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${cnxAccessToken}`;
    }

    axios({
        url:
            BACKEND_URL +
            "/api/v1/prestation/param/visualize-pj?idAlfresco=" +
            id,
        method: "GET",
        responseType: "blob"
    })
        .then(response => {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("target", "_blank");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })
        .catch(error => {
            console.log(error);
        });
};

export const downloadPlaquette = id => {
    /*  const cnxAccessToken = localStorage.getItem("token")
        ? localStorage.getItem("token")
        : "";
    axios.defaults.headers.common["Authorization"] = `Bearer ${cnxAccessToken}`; */
    axios({
        url: BACKEND_URL_ACHAT + "/api/v1/inscription/download/plaquette/" + id, // url
        method: "GET",
        responseType: "blob" // important
    }).then(response => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "PlaquetteCommercial.pdf");
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
};

export const downloadPiece = id => {
    /*  const cnxAccessToken = localStorage.getItem("token")
        ? localStorage.getItem("token")
        : "";
    axios.defaults.headers.common["Authorization"] = `Bearer ${cnxAccessToken}`; */
    axios({
        url: BACKEND_URL + "/api/v1/pieces-fournir/download/" + id, // url
        method: "GET",
        responseType: "blob" // important
    }).then(response => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Piece.pdf");
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
};

export const downloadPPR = () => {
    /*  const cnxAccessToken = localStorage.getItem("token")
        ? localStorage.getItem("token")
        : "";
    axios.defaults.headers.common["Authorization"] = `Bearer ${cnxAccessToken}`; */
    axios({
        url: BACKEND_URL_R_HUMAINE + "/api/v1/ppr/download", // url
        method: "GET",
        responseType: "blob" // important
    }).then(response => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "PPR.pdf");
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
};

export const visualizeResultatt = id => {
    const cnxAccessToken = localStorage.getItem("token") || "";
    if (cnxAccessToken) {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${cnxAccessToken}`;
    }
    axios({
        url:
            BACKEND_URL_R_HUMAINE +
            "/api/v1/offre/visualize/file?idAlfresco=" +
            id,
        method: "GET",
        responseType: "blob"
    }).then(response => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);

        const newTab = window.open(fileURL, "_blank");
        if (!newTab) {
            console.error(
                "Failed to open PDF in a new tab. Pop-up blocker may be enabled."
            );
        }
    });
};

export const visualizePj = id => {
    const cnxAccessToken = localStorage.getItem("token") || "";
    if (cnxAccessToken) {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${cnxAccessToken}`;
    }
    axios({
        url:
            BACKEND_URL +
            "/api/v1/rencontre-scientifique/visualize-pj?idAlfresco=" +
            id,
        method: "GET",
        responseType: "blob"
    }).then(response => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);

        const newTab = window.open(fileURL, "_blank");
        if (!newTab) {
            console.error(
                "Failed to open PDF in a new tab. Pop-up blocker may be enabled."
            );
        }
    });
};
