import React, { lazy, Suspense, useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import {
    CMR,
    PAGE_404,
    REDIRECT_URL,
    ROOT,
    TEST_AUTH,
    URL_E_CLIENT,
    CONSTRUCTION,
    TANDEM_OVERVIEW,
    SMART_ANALYSIS,
    CHAT_DATA,
    SALES_ADVISOR
} from "../../constants/routes";
import Page404 from "../ErrorPages/Page404";
import LoadingPage from "../../components/Common/LoadingPage";
import { connect } from "react-redux";
import { verifyToken, verifyTokenFournisseur } from "../../redux/actions/api";
import { toast } from "react-toastify";
import AuthHandler from "../EspaceNonConnecte/ThirdPartyLogin/AuthHandler";
import TestPage from "../../components/Common/TestPage";
import UnderConstruction from "../ErrorPages/UnderConstruction";
import TandemOverview from "../Tandem/TandemOverview";
import SmartAnalysisOverview from "../Tandem/SmartAnalysisOverview";
import SalesAdvisorOverview from "../Tandem/SalesAdvisorOverview";
import ChatDataOverview from "../Tandem/ChatDataOverview";

const ClientLayout = lazy(() => import("./ClientLayout"));
const PublicLayout = lazy(() => import("./PublicLayout"));

const Layout = ({ verifyToken, verifyTokenFournisseur }) => {
    useEffect(() => {
        window.addEventListener("unhandledrejection", event => {
            console.warn(`UNHANDLED PROMISE REJECTION: ${event.reason}`);
        });
    }, []);

    useEffect(() => {
        const cnxAccessToken = localStorage.getItem("token");
        const profil = localStorage.getItem("profil");
        if (cnxAccessToken && profil === "fournisseur")
            verifyTokenFournisseur();
        else if (cnxAccessToken && profil !== "fournisseur") verifyToken();
    }, []);
    return (
        <Switch>
            <Route path={PAGE_404} exact component={Page404} />
            <Route path={TANDEM_OVERVIEW} exact component={TandemOverview} />
            <Route
                path={SMART_ANALYSIS}
                exact
                component={SmartAnalysisOverview}
            />
            <Route path={CHAT_DATA} exact component={ChatDataOverview} />
            <Route
                path={SALES_ADVISOR}
                exact
                component={SalesAdvisorOverview}
            />
            <Route path={CONSTRUCTION} exact component={UnderConstruction} />
            <Route path={REDIRECT_URL} exact component={AuthHandler} />
            <Route path={TEST_AUTH} exact component={TestPage} />
            <Route
                path={ROOT}
                component={() => <Redirect to={TANDEM_OVERVIEW} />}
            />
            <Route
                path="/"
                exact
                component={() => <Redirect to={TANDEM_OVERVIEW} />}
            />

            <Route
                path="/Portal"
                exact
                component={() => <Redirect to={TANDEM_OVERVIEW} />}
            />
            <Route
                path=""
                exact
                component={() => <Redirect to={TANDEM_OVERVIEW} />}
            />
        </Switch>
    );
};

const mapStateToProps = state => ({
    pathname: state.router.location.pathname
});

const mapDispatchToProps = dispatch => ({
    verifyToken: () => dispatch(verifyToken()),
    verifyTokenFournisseur: () => dispatch(verifyTokenFournisseur())
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
