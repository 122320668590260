import { SET_DISPLAYED_ADS, SET_ZOOM } from "../../actions/api";

const initialState = {
    zoom: "100%",
    adsAlreadyShown: false,
    prevZoom: "100%"
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_ZOOM:
            if (payload === state.zoom)
                return {
                    ...state
                };
            else
                return {
                    ...state,
                    prevZoom: state.zoom,
                    zoom: payload
                };
        case SET_DISPLAYED_ADS:
            return {
                ...state,
                adsAlreadyShown: payload
            };
        default:
            return state;
    }
};
