import { connect } from "react-redux";

import { push } from "connected-react-router";

import AuthHandler from "../../../components/EspaceNonConnecte/ThirdPartyLogin/AuthHandler";
import { authUsingThirdParty } from "../../../redux/actions/api";
import { afterLoginRedirection } from "../../../utils/utilFunctions";

const mapStateToProps = state => ({
    isLoading:
        state.data.api.isLoading &&
        state.data.api.isLoading.isLoadingData_SEND_AUTH_TOKEN,
    informations: state.data.api.data_AUTH
});

const mapDispatchToProps = dispatch => ({
    goToUrl: url => dispatch(push(url)),
    authWithToken: code => dispatch(authUsingThirdParty(code)),
    goToMonEspace: (type, position) =>
        dispatch(push(afterLoginRedirection(type, position)))
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthHandler);
