import { replace } from "connected-react-router";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { ROOT } from "../../constants/routes";
import TandemOverview from "../../components/Tandem/TandemOverview";

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    goToUrl: url => dispatch(push(url))
});

export default connect(mapStateToProps, mapDispatchToProps)(TandemOverview);
