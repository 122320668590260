import { LOCATION_CHANGE } from "connected-react-router";
import {
    TOKEN_EXPIRED,
    API_START,
    API_END,
    API_ERROR,
    ACCESS_DENIED,
    SET_AUTH,
    SEND_AUTH,
    GET_DETAILS_CLIENT,
    SET_GET_DETAILS_CLIENT,
    CLEAR_DECONNEXION,
    ADD_INSCRIPTION,
    SET_ADD_INSCRIPTION,
    CLEAR_ADD_INSCRIPTION,
    VERIFY_OTP,
    SET_VERIFY_OTP,
    CLEAR_VERIFY_OTP,
    CONFIGURE_MDP,
    SET_CONFIGURE_MDP,
    CLEAR_CONFIGURE_MDP,
    FORGET_MDP,
    SET_FORGET_MDP,
    CLEAR_FORGET_MDP,
    CHANGE_MDP,
    SET_CHANGE_MDP,
    CLEAR_CHANGE_MDP,
    RESEND_OTP,
    SET_RESEND_OTP,
    CLEAR_RESEND_OTP,
    SET_ALL_PRESTATIONS,
    SEND_ALL_PRESTATIONS,
    SEND_PRESTATION_BY_ID,
    SET_PRESTATION_BY_ID,
    SEND_LIST_ACTUALITES,
    SET_LIST_ACTUALITES,
    SEND_LIST_ACTUALITES_ENABLED,
    SET_LIST_ACTUALITES_ENABLED,
    SET_OTP_MAIL,
    SEND_OTP_MAIL,
    SET_OTP_PHONE,
    SEND_OTP_PHONE,
    SEND_UPDATE_MAIL_PHONE,
    SET_UPDATE_MAIL_PHONE,
    SEND_CHANGE_PWD,
    SET_CHANGE_PWD,
    SET_LIST_FAQ,
    SEND_LIST_FAQ,
    SEND_SIMULATION,
    SEND_PRESTATIONS,
    SET_PRESTATIONS,
    SET_SIMULATION,
    SET_PRESTATION,
    SEND_PRESTATION,
    ADD_PIECE,
    SET_ADD_PIECE,
    SEND_PREST_DEMANDE,
    SET_PREST_DEMANDE,
    SEND_SITUATION,
    SET_SITUATION,
    SEND_PENSION_DONNEE,
    SET_PENSION_DONNEE,
    SEND_PENSION_DEMANDES,
    SET_PENSION_DEMANDES,
    SET_PENSION_TYPE,
    CLEAR_LIST_PRESTATIONS,
    SEND_PRESTATION_CONNECTED,
    SET_PRESTATION_CONNECTED,
    ADD_PIECE_CONNECTED,
    SET_ADD_PIECE_CONNECTED,
    SET_SEND_OTP_PRESTATION,
    SET_VERIFY_OTP_PRESTATION,
    VERIFY_OTP_PRESTATION,
    SEND_OTP_PRESTATION,
    SET_PRESTATION_NULL,
    SET_PRESTATION_BY_ID_NULL,
    UPDATE_MY_STATE,
    SET_PJS,
    DOWNLOAD_ATTESTATION,
    SET_DOWNLOAD_ATTESTATION,
    CLEAR_OTP,
    SEND_DEMANDE_DETAILS,
    SET_DEMANDE_DETAILS,
    SEND_DOCUMENTATIONS,
    SET_DOCUMENTATIONS,
    SET_CHANGE_CANAL,
    CHANGE_CANAL,
    SET_CHANGE_PASSWORD,
    CHANGE_PASSWORD,
    SET_CANAL_NULL,
    SET_PASSWORD_NULL,
    SET_ADD_WORKFLOW,
    SET_VIDEOS_EVENTS,
    GET_VIDEOS_EVENTS,
    SET_ADD_WORKFLOW_DISCONNECTED,
    INSCRIPTION_FOURNISSEUR,
    SET_INSCRIPTION_FOURNISSEUR,
    CLEAR_INSCRIPTION_FOURNISSEUR,
    INSCRIPTION_FOURNISSEUR_FILE,
    SET_INSCRIPTION_FOURNISSEUR_FILE,
    CLEAR_INSCRIPTION_FOURNISSEUR_FILE,
    SEND_SIMULATION_DATA,
    SET_SIMULATION_DATA,
    SEND_MDP_RESET,
    SEND_VERIFY_TOKEN,
    CONFIGURE_MDP_FOURNISSEUR,
    SET_CONFIGURE_MDP_FOURNISSEUR,
    CLEAR_CONFIGURE_MDP_FOURNISSEUR,
    GET_APPELS_OFFRE,
    SET_APPELS_OFFRE,
    CLEAR_GET_APPELS_OFFRE,
    SEND_AUTH_FOURNISSEUR,
    SET_AUTH_FOURNISSEUR,
    SET_MARCHE,
    GET_MARCHE,
    UPLOAD_FACTURE,
    SET_UPLOAD_FACTURE,
    CLEAR_UPLOAD_FACTURE,
    GET_APPELS_OFFRE_NC,
    SET_APPELS_OFFRE_NC,
    CLEAR_APPELS_OFFRE_NC,
    GET_FACTURE_FOURNISSEUR,
    SET_FACTURE_FOURNISSEUR,
    SEND_UPDATE_MAIL,
    SEND_UPDATE_PHONE,
    SET_UPDATE_PHONE,
    SET_UPDATE_MAIL,
    SET_CHECK_AUTHENTICITE,
    CHECK_AUTHENTICITE,
    SET_NUM_AFFILIATION,
    GET_NUM_AFFILIATION,
    CLEAR_CHECK_AUTHENTICITE,
    CLEAR_NUM_AFFILIATION,
    CLEAR_OTP_MAIL,
    CLEAR_OTP_PHONE,
    SET_RESET_PASSWORD,
    SEND_RESET_PASSWORD,
    CLEAR_RESET_PASSWORD,
    SET_ATTAKMILI_INFO,
    GET_ATTAKMILI_INFO,
    SEND_APPELS_OFFRES,
    SET_APPELS_OFFRES,
    SEND_APPELS_OFFRES_PUBLIC,
    SET_APPELS_OFFRES_PUBLIC,
    SEND_APPEL_OFFRE_QUESTIONS,
    SET_APPEL_OFFRE_QUESTIONS,
    SEND_ANSWER_QUESTIONNAIRE,
    SET_ANSWER_QUESTIONNAIRE,
    GET_NOTIF,
    SET_NOTIF,
    SEND_SOUSCRIPTION_ATTAKMILI,
    SET_SOUSCRIPTION_ATTAKMILI,
    SET_ID_DEMANDE_ERSAL,
    UPLOAD_PJ,
    SET_UPLOAD_PJ,
    SET_ID_SOUSCRIPTION,
    VALIDER_SOUSCRIPTION,
    SET_VALIDER_SOUSCRIPTION,
    GET_SOUSCRIPTION_STATUS,
    SET_SOUSCRIPTION_STATUS,
    DEMANDE_INTERRUPTION,
    SET_DEMANDE_INTERRUPTION,
    DEMANDE_REPRISE,
    SET_DEMANDE_REPRISE,
    VALIDER_INTERRUPTION,
    SET_VALIDER_INTERRUPTION,
    GET_SLIDERS,
    SET_SLIDERS,
    SET_PIECES,
    GET_PIECES,
    GET_PHOTOS,
    SET_PHOTOS,
    SET_APPELS_FOURNISSEUR,
    GET_APPELS_FOURNISSEUR,
    GET_FACTURE_APPEL,
    SET_FACTURE_APPEL,
    SET_FACTURE,
    ADD_FACTURE,
    CLEAR_ADD_FACTURE,
    CLEAR_ANSWER,
    SEND_CANDIDATURE_SPONTANEE,
    SET_CANDIDATURE_SPONTANEE,
    SEND_CANDIDATURE_SPONTANEE_FILE,
    SET_CANDIDATURE_SPONTANEE_FILE,
    CLEAR_CANDIDATURE_SPONTANEE,
    CLEAR_CANDIDATURE_SPONTANEE_FILE,
    GET_OFFRES,
    SET_OFFRES,
    SET_STATISTIQUE,
    SET_ACTUALITY,
    GET_ACTUALITY,
    GET_STATISTIQUE,
    SET_LINKS,
    GET_LINKS,
    SET_CONFIGURE_PWD,
    CONFIGURE_PWD,
    SET_OFFRE_APPLICATION,
    SEND_OFFRE_APPLICATION,
    CLEAR_OFFRE_APPLICATION,
    SET_DONNEES,
    UPDATE_DONNEES,
    SET_PLAQUETTE,
    ADD_PLAQUETTE,
    CLEAR_PLAQUETTE,
    CLEAR_DONNEES,
    SET_CHANGE_DATA,
    CHANGE_DATA,
    NEW_PWD,
    FORGOT_PWD,
    SET_FORGOT_PWD,
    SET_NEW_PWD,
    RESET_PWD,
    SET_RESET_PWD,
    CLEAR_CHANGE_PWD,
    SEND_OFFRE_INFO,
    SET_OFFRE_INFO,
    SEND_APPLICATION_FILE,
    SET_APPLICATION_FILE,
    CLEAR_APPLICATION_FILE,
    SET_LIENS,
    SET_POSTULATION_FILE,
    SEND_POSTULATION_FILE,
    CLEAR_POSTULATION_FILE,
    SET_DEMANDE_LIQUIDATION,
    SEND_DEMANDE_LIQUIDATION,
    CLEAR_DEMANDE_LIQUIDATION,
    SET_VALIDER_DEMANDE_LIQUIDATION,
    SEND_VALIDER_DEMANDE_LIQUIDATION,
    CLEAR_VALIDER_DEMANDE_LIQUIDATION,
    CLEAR_UPLOAD_PJ,
    CLEAR_DEMANDE_REPRISE,
    CLEAR_DEMANDE_INTERRUPTION,
    SET_VALIDER_REPRISE,
    VALIDER_REPRISE,
    GET_LIENS,
    SET_FACTURES,
    GET_FACTURES,
    CLEAR_CHANGE_DATA,
    CLEAR_COTISATION,
    SET_COTISATION,
    SEND_COTISATION,
    CLEAR_VALIDER_COTISATION,
    SET_VALIDER_COTISATION,
    SEND_VALIDER_COTISATION,
    SET_CONTACT,
    SEND_CONTACT,
    CLEAR_CONTACT,
    SEND_LIBELLE_PPR,
    SET_LIBELLE_PPR,
    SET_SIMULATION_CAPITAL,
    SET_SIMULATION_RENTE,
    SET_SIMULATION_CAPITAL_AVANCE,
    SET_SIMULATION_RENTE_AVANCE,
    SIMULER_RENTE_AVANCE,
    SIMULER_CAPITAL_AVANCE,
    SIMULER_RENTE,
    SIMULER_CAPITAL,
    CLEAR_SIMULATION_CAPITAL,
    CLEAR_SIMULATION_RENTE,
    CLEAR_SIMULATION_RENTE_AVANCE,
    CLEAR_SIMULATION_CAPITAL_AVANCE,
    CLEAR_SOUSCRIPTION_ATTAKMILI,
    CLEAR_VALIDER_INTERRUPTION,
    CLEAR_VALIDER_REPRISE,
    CLEAR_VALIDER_SOUSCRIPTION,
    GET_EVENTS,
    SET_EVENTS,
    SET_BENEFS,
    GET_BENEFS,
    SET_UPDATE_BENEFS,
    CLEAR_UPDATE_BENEFS,
    UPDATE_BENEFS,
    SET_UPDATE_SOUSCRIPTION,
    UPDATE_SOUSCRIPTION,
    CLEAR_UPDATE_SOUSCRIPTION,
    SET_SIMULATION_RENTE_INVERSE,
    SET_SIMULATION_CAPITAL_INVERSE,
    SIMULER_RENTE_INVERSE,
    SIMULER_CAPITAL_INVERSE,
    CLEAR_SIMULATION_RENTE_INVERSE,
    CLEAR_SIMULATION_CAPITAL_INVERSE,
    SET_NOTIFS,
    GET_NOTIFS,
    SET_OPEN_NOTIF,
    OPEN_NOTIF,
    SEND_VERIFY_TOKEN_FOURNISSEUR,
    ADD_RECLAMATION,
    SET_RECLAMATION,
    CLEAR_RECLAMATION,
    SET_ALL_RENCONTRES,
    GET_ALL_RENCONTRES,
    GET_RECLAMATION,
    SET_GET_RECLAMATION,
    SET_SIM,
    CLEAR_SIM,
    SEND_ALL_PRESTATIONS_2,
    SET_ALL_PRESTATIONS_2,
    GET_MOTIFS,
    SET_MOTIFS,
    SET_BACK,
    CLEAR_BACK,
    SEND_SURVEY,
    SET_SURVEY,
    SEND_ADS,
    SET_ADS,
    GET_CONSULTATION_DONNEES,
    SET_CONSULTATION_DONNEES,
    SET_DATA,
    SEND_DATA,
    SET_VERIFY_TOKEN_FOURNISSEUR,
    SET_PENS_DONNEES,
    GET_PENS_DONNEES,
    SET_CHAUDS,
    GET_CHAUDS,
    CLEAR_ADD_PIECE_CONNECTED,
    CLEAR_PRESTATION,
    CLEAR_ADD_PIECE,
    SEND_OTP,
    SET_OTP,
    SEND_VERIFY_ATTAK_OTP,
    SET_VERIFY_ATTAK_OTP,
    CLEAR_VERIFY_ATTAK_OTP,
    CLEAR_ATTAK_OTP,
    SEND_VERSEMENT,
    SET_VERSEMENT,
    CLEAR_VERSEMENT,
    SEND_VERIFY_VER_OTP,
    SET_VERIFY_VER_OTP,
    CLEAR_VERIFY_VER_OTP,
    GET_INBOX,
    SET_INBOX,
    SEND_PENSION_DETAIL,
    SET_PENSION_DETAIL,
    SET_PEN_PAIMENT_DETAIL,
    SEND_PEN_PAIMENT_DETAIL,
    SET_PEN_ENFANT_DETAIL,
    SEND_PEN_ENFANT_DETAIL,
    SET_PEN_ATTAK_DETAIL,
    SEND_PEN_ATTAK_DETAIL,
    GET_INFO_EPARGNE,
    SET_INFO_EPARGNE,
    SEND_AUTH_TOKEN,
    METIER_VER,
    SET_METIER_VER,
    CLEAR_METIER_VER,
    DELETE_BENEF,
    SET_DELETE_BENEF,
    EVALUATE_PRESTA,
    SET_EVALUATE_PRESTA,
    SET_DATA_VERSEMENT,
    SET_MODIF_DONNEE,
    CLEAR_MODIF_DONNEE,
    MODIF_DONNEE,
    SYNCHRO_ATTAKMILI,
    SYNCHRO_ATTAKMILI_LIQUIDATION,
    SET_SYNCHRO_ATTAKMILI_LIQUIDATION,
    SIMULATION_COMBINE,
    SET_SIMULATION_COMBINE,
    CLEAR_SIMULATION_COMBINE,
    GET_TAUX_BO,
    SET_TAUX_BO,
    SET_JOURNALISTE,
    SEND_JOURNALISTE,
    CLEAR_JOURNALISTE,
    PAIMENT_MODE,
    CHANGE_STATUS_NOTIF,
    SET_CHANGE_STATUS_NOTIF,
    CLEAR_CHANGE_STATUS_NOTIF,
    UNOPPENED_NOTIF,
    SEND_CUSTOM_FILE,
    SET_CUSTOM_FILE,
    CLEAR_CUSTOM_FILE,
    SEND_FILE_CHEQUE,
    SET_FILE_CHEQUE,
    CLEAR_FILE_CHEQUE,
    SEND_DOC_STRUCTURE,
    SET_DOC_STRUCTURE,
    CLEAR_DOC_STRUCTURE,
    SEND_DATA_CHAT,
    SET_DATA_CHAT,
    CLEAR_DATA_CHAT,
    SEND_TRAITER_DOSSIER,
    SET_TRAITER_DOSSIER,
    CLEAR_TRAITER_DOSSIER,
    COMPARER_SIGNATURE,
    SET_COMPARER_SIGNATURE,
    CLEAR_COMPARER_SIGNATURE
} from "../../actions/api";
import { ROOT } from "../../../constants/routes";

const initialState = { paimentMode: null, unoppenedNotif: false };
export default (state = initialState, action) => {
    console.log("action type => ", action.type);
    switch (action.type) {
        case SET_AUTH:
            if (
                action.payload &&
                action.payload.success &&
                action.payload.token &&
                action.payload.profil
            ) {
                localStorage.setItem("token", action.payload.token);
            }
            return { ...state, data_AUTH: action.payload };
        case SET_GET_DETAILS_CLIENT:
            return { ...state, data_DETAILS_CLIENT: action.payload };
        case SET_ATTAKMILI_INFO:
            return { ...state, data_ATTAKMILI_INFO: action.payload };
        case SET_ADD_INSCRIPTION:
            return { data_add_inscription: action.payload };
        case SET_VERIFY_OTP:
            return { data_verify_otp: action.payload };
        case SET_SURVEY:
            return { data_survey: action.payload };
        case SET_ADS:
            return { data_ads: action.payload };
        case SET_CONFIGURE_MDP:
            return { data_configure_mdp: action.payload };
        case SET_FORGET_MDP:
            return { data_forget_mdp: action.payload };
        case SET_CHANGE_MDP:
            return { data_change_mdp: action.payload };
        case SET_RESEND_OTP:
            return {
                ...state,
                data_resend_otp: action.payload
            };
        case SET_OTP_MAIL:
            return {
                ...state,
                data_otp_mail: action.payload
            };
        case SET_UPDATE_PHONE:
            return {
                ...state,
                response_update_phone: action.payload
            };
        case SET_UPDATE_MAIL:
            return {
                ...state,
                response_update_mail: action.payload
            };
        case SET_APPELS_OFFRES:
            return {
                ...state,
                data_APPELS_OFFRES: action.payload
            };
        case SET_UPDATE_BENEFS:
            return {
                ...state,
                data_UPDATE_BENEFS: action.payload
            };
        case SET_UPDATE_SOUSCRIPTION:
            return {
                ...state,
                data_UPDATE_SOUSCRIPTION: action.payload
            };
        case SET_APPELS_OFFRES_PUBLIC:
            return {
                ...state,
                data_APPELS_OFFRES_PUBLIC: action.payload
            };
        case SET_APPEL_OFFRE_QUESTIONS:
            return {
                ...state,
                data_APPEL_OFFRE_QUESTIONS: action.payload
            };
        case SET_ANSWER_QUESTIONNAIRE:
            return {
                ...state,
                data_ANSWER_QUESTIONNAIRE: action.payload
            };
        case SET_LINKS:
            return {
                ...state,
                data_LINKS: action.payload
            };
        case SET_OFFRES:
            return {
                ...state,
                data_SET_OFFRES: action.payload
            };
        case SET_NOTIF:
            return {
                ...state,
                data_NOTIF: action.payload
            };
        case SET_INBOX:
            return {
                ...state,
                data_INBOX: action.payload
            };
        case SET_ID_DEMANDE_ERSAL:
            return {
                ...state,
                data_ID_DEMANDE_ERSAL: action.payload
            };
        case SET_SOUSCRIPTION_ATTAKMILI:
            return {
                ...state,
                data_SOUSCRIPTION_ATTAKMILI: action.payload
            };
        case SET_CANDIDATURE_SPONTANEE:
            return {
                ...state,
                data_CANDIDATURE_SPONTANEE: action.payload
            };
        case SET_CONTACT:
            return {
                ...state,
                data_CONTACT: action.payload
            };
        case SET_CANDIDATURE_SPONTANEE_FILE:
            return {
                ...state,
                data_CANDIDATURE_SPONTANEE_FILE: action.payload
            };
        case SET_APPLICATION_FILE:
            return {
                ...state,
                data_APPLICATION_FILE: action.payload
            };
        case SET_ID_SOUSCRIPTION:
            return {
                ...state,
                data_ID_SOUSCRIPTION: action.payload
            };
        case SET_UPLOAD_PJ:
            return {
                ...state,
                data_UPLOAD_PJ: action.payload
            };
        case SET_VALIDER_SOUSCRIPTION:
            return {
                ...state,
                data_VALIDER_SOUSCRIPTION: action.payload
            };
        case SET_ACTUALITY:
            return {
                ...state,
                data_ACTUALITY: action.payload
            };
        case SET_OFFRE_APPLICATION:
            return {
                ...state,
                data_OFFRE_APPLICATION: action.payload
            };
        case SET_CHANGE_DATA:
            return {
                ...state,
                data_CHANGE_DATA: action.payload
            };
        case SET_FORGOT_PWD:
            return {
                ...state,
                data_FORGOT_PWD: action.payload
            };
        case SET_NEW_PWD:
            return {
                ...state,
                data_NEW_PWD: action.payload
            };
        case SET_STATISTIQUE:
            return {
                ...state,
                data_STATISTIQUE: action.payload
            };
        case SET_BENEFS:
            return {
                ...state,
                data_BENEFS: action.payload
            };
        case SET_SOUSCRIPTION_STATUS:
            return {
                ...state,
                data_SOUSCRIPTION_STATUS: action.payload
            };
        case SET_DEMANDE_INTERRUPTION:
            return {
                ...state,
                data_DEMANDE_INTERRUPTION: action.payload
            };
        case SET_DEMANDE_REPRISE:
            return {
                ...state,
                data_DEMANDE_REPRISE: action.payload
            };
        case SET_POSTULATION_FILE:
            return {
                ...state,
                data_POSTULATION_FILE: action.payload
            };
        case SET_COTISATION:
            return {
                ...state,
                data_COTISATION: action.payload
            };
        case SET_VALIDER_COTISATION:
            return {
                ...state,
                data_VALIDER_COTISATION: action.payload
            };
        case SET_SLIDERS:
            return {
                ...state,
                data_SLIDERS: action.payload
            };
        case SET_OFFRE_INFO:
            return {
                ...state,
                data_OFFRE_INFO: action.payload
            };
        case SET_DEMANDE_LIQUIDATION:
            return {
                ...state,
                data_DEMANDE_LIQUIDATION: action.payload
            };
        case SET_VALIDER_DEMANDE_LIQUIDATION:
            return {
                ...state,
                data_VALIDER_DEMANDE_LIQUIDATION: action.payload
            };
        case SET_VALIDER_INTERRUPTION:
            return {
                ...state,
                data_VALIDER_INTERRUPTION: action.payload
            };
        case SET_VALIDER_REPRISE:
            return {
                ...state,
                data_VALIDER_REPRISE: action.payload
            };
        case SET_OTP_PHONE:
            return {
                ...state,
                data_otp_phone: action.payload
            };
        case CLEAR_OTP:
            return { data_otp_phone: null, data_otp_mail: null };
        case SET_UPDATE_MAIL_PHONE:
            return { ...state, data_update_mail_phone: action.payload };
        case SET_CHANGE_PWD:
            return { ...state, data_change_pwd: action.payload };
        case SET_SIMULATION:
            return { data_simulation: action.payload };
        case SET_LIST_ACTUALITES:
            return {
                ...state,
                data_List_Actualites: action.payload
            };
        case SET_PRESTATIONS:
            return {
                ...state,
                data_prestations: action.payload
            };
        case SET_LIST_ACTUALITES_ENABLED:
            return {
                ...state,
                data_List_Actualites_enabled: action.payload
            };

        case SET_ALL_PRESTATIONS:
            return {
                ...state,
                data_List_Prestations: action.payload
            };
        case SET_ALL_PRESTATIONS_2:
            return {
                ...state,
                data_List_Prestations_2: action.payload
            };
        case SET_PRESTATION_BY_ID:
            return {
                ...state,
                data_Prestation_by_Id: action.payload
            };
        case SET_LIBELLE_PPR:
            return {
                ...state,
                data_LIBELLE_PPR: action.payload
            };
        case SET_OTP:
            return {
                ...state,
                data_OTP: action.payload
            };
        case SET_VERIFY_ATTAK_OTP:
            return {
                ...state,
                data_VERIFY_ATTAK_OTP: action.payload
            };
        case SET_VERIFY_VER_OTP:
            return {
                ...state,
                data_VERIFY_VER_OTP: action.payload
            };
        case SET_PRESTATION_BY_ID_NULL:
            return {
                ...state,
                data_Prestation_by_Id: null
            };
        case SET_LIST_FAQ:
            return {
                ...state,
                data_List_Faq: action.payload
            };
        case SET_PRESTATION:
            return {
                ...state,
                data_Prestation: action.payload
            };
        case SET_ADD_PIECE:
            return {
                ...state,
                data_Add_Piece: action.payload
            };
        case SET_MODIF_DONNEE:
            return {
                ...state,
                data_SET_MODIF_DONNEE: action.payload
            };
        case SET_DELETE_BENEF:
            return {
                ...state,
                data_SET_DELETE_BENEF: action.payload
            };
        case SET_PREST_DEMANDE:
            return {
                ...state,
                data_prest_demande: action.payload
            };
        case SET_SITUATION:
            return {
                ...state,
                data_SITUATION: action.payload
            };
        case SET_PENSION_DONNEE:
            return {
                ...state,
                data_PENSION_DONNEE: action.payload
            };
        case SET_PENSION_DEMANDES:
            return {
                ...state,
                data_PENSION_DEMANDES: action.payload
            };
        case SET_PENSION_TYPE:
            return {
                ...state,
                data_PENSION_TYPE: action.payload
            };
        case SET_PRESTATION_CONNECTED:
            return {
                ...state,
                data_Prestation_Connected: action.payload
            };
        case SET_PRESTATION_NULL:
            return {
                ...state,
                data_Prestation_Connected: null
            };
        case SET_ADD_PIECE_CONNECTED:
            return {
                ...state,
                data_Add_Piece_Connected: action.payload
            };
        case SET_DOWNLOAD_ATTESTATION:
            return {
                ...state,
                data_DOWNLOAD_ATTESTATION: action.payload
            };
        case SET_DOCUMENTATIONS:
            return {
                ...state,
                data_DOCUMENTATIONS: action.payload
            };
        case SET_SIMULATION_DATA:
            return {
                ...state,
                data_SIMULATION_DATA: action.payload
            };
        case SET_DEMANDE_DETAILS:
            return {
                ...state,
                data_DEMANDE_DETAILS: action.payload
            };
        case SET_VERIFY_OTP_PRESTATION:
            return {
                ...state,
                data_Verify_Otp_Presta: action.payload
            };
        case SET_SEND_OTP_PRESTATION:
            return {
                ...state,
                data_Send_Otp_Presta: action.payload
            };
        case SET_PJS:
            return {
                ...state,
                data_Set_PJS: action.payload
            };
        case SET_CHANGE_CANAL:
            return {
                ...state,
                data_Set_Canal: action.payload
            };
        case SET_CHANGE_PASSWORD:
            return {
                ...state,
                data_Change_Password: action.payload
            };
        case SET_CANAL_NULL:
            return {
                ...state,
                data_Set_Canal: null
            };
        case SET_PASSWORD_NULL:
            return {
                ...state,
                data_Change_Password: null
            };
        case "SET_SCROLL":
            return {
                ...state,
                data_scroll: true
            };
        case "CLEAR_SCROLL":
            return {
                ...state,
                data_scroll: false
            };
        case SET_ADD_WORKFLOW:
            return {
                ...state,
                data_add_workflow: null
            };
        case CLEAR_SOUSCRIPTION_ATTAKMILI:
            return {
                ...state,
                data_SOUSCRIPTION_ATTAKMILI: null
            };
        case CLEAR_MODIF_DONNEE:
            return {
                ...state,
                data_SET_MODIF_DONNEE: null
            };
        case SET_INSCRIPTION_FOURNISSEUR:
            return {
                ...state,
                data_inscription_fournisseur: action.payload
            };
        case SET_INSCRIPTION_FOURNISSEUR_FILE:
            return {
                ...state,
                data_inscription_fournisseur_file: action.payload
            };
        case SET_CONFIGURE_MDP_FOURNISSEUR:
            return {
                ...state,
                data_CONFIGURE_MDP_FOURNISSEUR: action.payload
            };
        case SET_VIDEOS_EVENTS:
            return {
                ...state,
                data_Videos_Events: action.payload
            };
        case SET_RECLAMATION:
            return {
                ...state,
                data_RECLAMATION: action.payload
            };
        case SET_GET_RECLAMATION:
            return {
                ...state,
                data_GET_RECLAMATION: action.payload
            };
        case SET_ADD_WORKFLOW_DISCONNECTED:
            return {
                ...state,
                data_Worflow_Disconnected: action.payload
            };
        case SET_APPELS_OFFRE:
            return {
                ...state,
                data_APPELS_OFFRE: action.payload
            };
        case SET_APPELS_OFFRE_NC:
            return {
                ...state,
                data_APPELS_OFFRE_NC: action.payload
            };
        case SET_CHANGE_STATUS_NOTIF:
            return {
                ...state,
                data_CHANGE_STATUS_NOTIF: action.payload
            };

        case SET_AUTH_FOURNISSEUR:
            if (
                action.payload &&
                action.payload.success &&
                action.payload.token &&
                action.payload.profil
            ) {
                localStorage.setItem("token", action.payload.token);
                localStorage.setItem("client", JSON.stringify(action.payload));
                localStorage.setItem("profil", action.payload.profil);
            }
            return { ...state, data_AUTH_FOURNISSEUR: action.payload };

        case SET_MARCHE:
            return {
                ...state,
                data_MARCHE: action.payload
            };
        case SET_UPLOAD_FACTURE:
            return {
                ...state,
                data_upload_facture: action.payload
            };
        case SET_CHECK_AUTHENTICITE:
            return {
                ...state,
                data_check_authenticite: action.payload
            };
        case SET_NUM_AFFILIATION:
            return {
                ...state,
                data_get_num_affiliation: action.payload
            };
        case SET_FACTURE_FOURNISSEUR:
            return {
                ...state,
                data_FACTURE_FOURNISSEUR: action.payload
            };
        case CLEAR_CHECK_AUTHENTICITE:
            return {
                ...state,
                data_check_authenticite: null
            };
        case CLEAR_POSTULATION_FILE:
            return {
                ...state,
                data_POSTULATION_FILE: null
            };
        case CLEAR_NUM_AFFILIATION:
            return {
                ...state,
                data_get_num_affiliation: null
            };
        case SET_RESET_PASSWORD:
            return {
                ...state,
                data_reset_password: action.payload
            };
        case CLEAR_RESET_PASSWORD:
            return {
                ...state,
                data_reset_password: null
            };
        case CLEAR_RECLAMATION:
            return {
                ...state,
                data_RECLAMATION: null
            };
        case SET_PIECES:
            return {
                ...state,
                data_SET_PIECES: action.payload
            };
        case SET_PHOTOS:
            return {
                ...state,
                data_SET_PHOTOS: action.payload
            };
        case SET_APPELS_FOURNISSEUR:
            return {
                ...state,
                data_SET_APPELS_FOURNISSEUR: action.payload
            };
        case SET_FACTURE_APPEL:
            return {
                ...state,
                data_SET_FACTURE_APPEL: action.payload
            };
        case SET_FACTURE:
            return {
                ...state,
                data_SET_FACTURE: action.payload
            };
        case SET_CONFIGURE_PWD:
            return {
                ...state,
                data_SET_CONFIGURE_PWD: action.payload
            };
        case SET_DONNEES:
            return {
                ...state,
                data_UPDATE_DONNEES: action.payload
            };
        case SET_PLAQUETTE:
            return {
                ...state,
                data_ADD_PLAQUETTE: action.payload
            };
        case SET_RESET_PWD:
            return {
                ...state,
                data_RESET_PWD: action.payload
            };
        case SET_LIENS:
            return {
                ...state,
                data_GET_LIENS: action.payload
            };
        case SET_FACTURES:
            return {
                ...state,
                data_SET_FACTURES: action.payload
            };
        case SET_MOTIFS:
            return {
                ...state,
                data_SET_MOTIFS: action.payload
            };
        case SET_CONSULTATION_DONNEES:
            return {
                ...state,
                data_CONSULTATION_DONNEES: action.payload
            };
        case SET_SIMULATION_CAPITAL:
            return {
                ...state,
                data_SET_SIMULATION_CAPITAL: action.payload
            };
        case SET_SIMULATION_RENTE:
            return {
                ...state,
                data_SET_SIMULATION_RENTE: action.payload
            };
        case SET_SIMULATION_CAPITAL_AVANCE:
            return {
                ...state,
                data_SET_SIMULATION_CAPITAL_AVANCE: action.payload
            };
        case SET_SIMULATION_RENTE_AVANCE:
            return {
                ...state,
                data_SET_SIMULATION_RENTE_AVANCE: action.payload
            };
        case SET_EVENTS:
            return {
                ...state,
                data_SET_EVENTS: action.payload
            };
        case SET_VALIDER_REPRISE:
            return {
                ...state,
                data_SET_VALIDER_REPRISE: action.payload
            };
        case SET_METIER_VER:
            return {
                ...state,
                data_SET_METIER_VER: action.payload
            };
        case SET_SIMULATION_RENTE_INVERSE:
            return {
                ...state,
                data_SET_SIMULATION_RENTE_INVERSE: action.payload
            };
        case SET_SIMULATION_CAPITAL_INVERSE:
            return {
                ...state,
                data_SET_SIMULATION_CAPITAL_INVERSE: action.payload
            };
        case SET_PENS_DONNEES:
            return {
                ...state,
                data_SET_PENS_DONNEES: action.payload
            };
        case SET_VERSEMENT:
            return {
                ...state,
                data_SET_VERSEMENT: action.payload
            };
        case SET_NOTIFS:
            return {
                ...state,
                data_SET_NOTIFS: action.payload
            };
        case SET_OPEN_NOTIF:
            return {
                ...state,
                data_SET_OPEN_NOTIF: action.payload
            };
        case SET_ALL_RENCONTRES:
            return {
                ...state,
                data_GET_RENCONTRE: action.payload
            };
        case SET_SIM:
            return {
                ...state,
                data_Simulation: action.payload
            };
        case SET_BACK:
            return {
                ...state,
                data_Back: action.payload
            };
        case SET_DATA:
            return {
                ...state,
                data_SET_DATA: action.payload
            };
        case SET_CHAUDS:
            return {
                ...state,
                data_SET_CHAUDS: action.payload
            };
        case SET_PENSION_DETAIL:
            return {
                ...state,
                data_pen_detail: action.payload
            };
        case SET_PEN_PAIMENT_DETAIL:
            return {
                ...state,
                data_paiment_pen_detail: action.payload
            };
        case SET_PEN_ENFANT_DETAIL:
            return {
                ...state,
                data_enfant_pen_detail: action.payload
            };
        case SET_PEN_ATTAK_DETAIL:
            return {
                ...state,
                data_attak_pen_detail: action.payload
            };

        case SET_INFO_EPARGNE:
            return {
                ...state,
                data_info_epargne: action.payload
            };
        case SET_DATA_VERSEMENT:
            return {
                ...state,
                data_versement: action.payload
            };
        case SET_EVALUATE_PRESTA:
            return {
                ...state,
                data_EVALUATE_PRESTA: action.payload
            };
        case SET_SIMULATION_COMBINE:
            return {
                ...state,
                data_SIMULATION_COMBINE: action.payload
            };
        case SET_TAUX_BO:
            return {
                ...state,
                data_TAUX_BO: action.payload
            };
        case SET_JOURNALISTE:
            return {
                ...state,
                data_SET_JOURNALISTE: action.payload
            };
        case PAIMENT_MODE:
            return {
                ...state,
                paimentMode: action.payload
            };
        case UNOPPENED_NOTIF:
            return {
                ...state,
                unoppenedNotif: action.payload
            };
        case SET_CUSTOM_FILE:
            return {
                ...state,
                data_CUSTOM_FILE: action.payload
            };
        case SET_FILE_CHEQUE:
            return {
                ...state,
                data_FILE_CHEQUE: action.payload
            };
        case SET_COMPARER_SIGNATURE:
            return {
                ...state,
                data_COMPARER_SIGNATURE: action.payload
            };
        case SET_DOC_STRUCTURE:
            return {
                ...state,
                data_DOC_STRUCTURE: action.payload
            };
        case SET_DATA_CHAT:
            return {
                ...state,
                data_DATA_CHAT: action.payload
            };
        case SET_TRAITER_DOSSIER:
            return {
                ...state,
                data_TRAITER_DOSSIER: action.payload
            };

        case API_START:
            if (action.payload === SEND_AUTH) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_AUTH: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_AUTH_TOKEN) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_AUTH_TOKEN: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_VERIFY_TOKEN) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_VERIFY_TOKEN: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_MDP_RESET) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_MDP_RESET: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_LIST_ACTUALITES) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_LIST_ACTUALITES: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_POSTULATION_FILE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_POSTULATION_FILE: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_PENS_DONNEES) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_PENS_DONNEES: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_COTISATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_COTISATION: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_BENEFS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_BENEFS: true
                    },
                    isError: false
                };
            }
            if (action.payload === UPDATE_SOUSCRIPTION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_UPDATE_SOUSCRIPTION: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_VALIDER_COTISATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_VALIDER_COTISATION: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_LIST_ACTUALITES_ENABLED) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_LIST_ACTUALITES_ENABLED: true
                    },
                    isError: false
                };
            }
            if (action.payload === MODIF_DONNEE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_MODIF_DONNEE: true
                    },
                    isError: false
                };
            }

            if (action.payload === GET_DETAILS_CLIENT) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_DETAILS_CLIENT: true
                    },
                    isError: false
                };
            }
            if (action.payload === ADD_INSCRIPTION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_ADD_INSCRIPTION: true
                    },
                    isError: false
                };
            }
            if (action.payload === VERIFY_OTP) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_VERIFY_OTP: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_OTP_MAIL) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_send_mail: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_VERSEMENT) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_VERSEMENT: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_UPDATE_MAIL) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_UPDATE_MAIL: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_APPELS_OFFRES) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_APPELS_OFFRES: true
                    },
                    isError: false
                };
            }
            if (action.payload === UPDATE_BENEFS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_UPDATE_BENEFS: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_APPELS_OFFRES_PUBLIC) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_APPELS_OFFRES_PUBLIC: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_APPEL_OFFRE_QUESTIONS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_APPEL_OFFRE_QUESTIONS: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_ANSWER_QUESTIONNAIRE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_ANSWER_QUESTIONNAIRE: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_NOTIF) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_NOTIF: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_INBOX) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_INBOX: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_SOUSCRIPTION_ATTAKMILI) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SOUSCRIPTION_ATTAKMILI: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_CONSULTATION_DONNEES) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_CONSULTATION_DONNEES: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_CANDIDATURE_SPONTANEE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_CANDIDATURE_SPONTANEE: true
                    },
                    isError: false
                };
            }

            if (action.payload === GET_OFFRES) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_OFFRES: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_ACTUALITY) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_ACTUALITY: true
                    },
                    isError: false
                };
            }
            if (action.payload === FORGOT_PWD) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_FORGOT_PWD: true
                    },
                    isError: false
                };
            }
            if (action.payload === NEW_PWD) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_NEW_PWD: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_OFFRE_APPLICATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_OFFRE_APPLICATION: true
                    },
                    isError: false
                };
            }
            if (action.payload === CHANGE_DATA) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_CHANGE_DATA: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_LINKS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_LINKS: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_STATISTIQUE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_STATISTIQUE: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_APPLICATION_FILE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_APPLICATION_FILE: true
                    },
                    isError: false
                };
            }
            if (action.payload === VALIDER_REPRISE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_VALIDER_REPRISE: true
                    },
                    isError: false
                };
            }
            if (action.payload === UPLOAD_PJ) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_UPLOAD_PJ: true
                    },
                    isError: false
                };
            }
            if (action.payload === SYNCHRO_ATTAKMILI) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SYNCHRO_ATTAKMILI: true
                    },
                    isError: false
                };
            }
            if (action.payload === SYNCHRO_ATTAKMILI_LIQUIDATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SYNCHRO_ATTAKMILI_LIQUIDATION: true
                    },
                    isError: false
                };
            }
            if (action.payload === VALIDER_SOUSCRIPTION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_VALIDER_SOUSCRIPTION: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_SOUSCRIPTION_STATUS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SOUSCRIPTION_STATUS: true
                    },
                    isError: false
                };
            }
            if (action.payload === DEMANDE_INTERRUPTION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_DEMANDE_INTERRUPTION: true
                    },
                    isError: false
                };
            }
            if (action.payload === DEMANDE_REPRISE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_DEMANDE_REPRISE: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_SLIDERS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SLIDERS: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_OFFRE_INFO) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_OFFRE_INFO: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_DEMANDE_LIQUIDATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_DEMANDE_LIQUIDATION: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_VALIDER_DEMANDE_LIQUIDATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_VALIDER_DEMANDE_LIQUIDATION: true
                    },
                    isError: false
                };
            }
            if (action.payload === VALIDER_INTERRUPTION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_VALIDER_INTERRUPTION: true
                    },
                    isError: false
                };
            }
            if (action.payload === VALIDER_REPRISE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_VALIDER_REPRISE: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_UPDATE_PHONE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_UPDATE_PHONE: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_OTP_PHONE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_send_phone: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_UPDATE_MAIL_PHONE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_update_email_phone: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_CHANGE_PWD) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_change_pwd: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_SIMULATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_simulation: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_CONTACT) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_CONTACT: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_PRESTATIONS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_prestations: true
                    },
                    isError: false
                };
            }
            if (action.payload === CONFIGURE_MDP) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_CONFIGURE_MDP: true
                    },
                    isError: false
                };
            }
            if (action.payload === FORGET_MDP) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_FORGET_MDP: true
                    },
                    isError: false
                };
            }
            if (action.payload === CHANGE_MDP) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_CHANGE_MDP: true
                    },
                    isError: false
                };
            }
            if (action.payload === RESEND_OTP) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_RESEND_OTP: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_ALL_PRESTATIONS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_ALL_PRESTATIONS: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_ALL_PRESTATIONS_2) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_ALL_PRESTATIONS_2: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_PRESTATION_BY_ID) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_PRESTATION_BY_ID: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_LIBELLE_PPR) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_LIBELLE_PPR: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_OTP) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_OTP: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_VERIFY_ATTAK_OTP) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_VERIFY_ATTAK_OTP: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_VERIFY_VER_OTP) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_VERIFY_VER_OTP: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_LIST_FAQ) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_LIST_FAQ: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_PRESTATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_PRESTATION: true
                    },
                    isError: false
                };
            }
            if (action.payload === ADD_PIECE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_ADD_PIECE: true
                    },
                    isError: false
                };
            }
            if (action.payload === DELETE_BENEF) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_DELETE_BENEF: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_PRESTATION_CONNECTED) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_PRESTATION_CONNECTED: true
                    },
                    isError: false
                };
            }
            if (action.payload === DOWNLOAD_ATTESTATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_DOWNLOAD_ATTESTATION: true
                    },
                    isError: false
                };
            }
            if (action.payload === METIER_VER) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_METIER_VER: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_DEMANDE_DETAILS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_DEMANDE_DETAILS: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_ATTAKMILI_INFO) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_ATTAKMILI_INFO: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_DOCUMENTATIONS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_DOCUMENTATIONS: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_SIMULATION_DATA) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SIMULATION_DATA: true
                    },
                    isError: false
                };
            }
            if (action.payload === ADD_PIECE_CONNECTED) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_ADD_PIECE_CONNECTED: true
                    },
                    isError: false
                };
            }
            if (action.payload === VERIFY_OTP_PRESTATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_VERIFY_OTP_PRESTA: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_OTP_PRESTATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_OTP_PRESTA: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_PREST_DEMANDE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_PREST_DEMANDE: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_SITUATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SITUATION: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_PENSION_DONNEE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_PENSION_DONNEE: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_PENSION_DEMANDES) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_PENSION_DEMANDES: true
                    },
                    isError: false
                };
            }
            if (action.payload === CHANGE_CANAL) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_Change_Canal: true
                    },
                    isError: false
                };
            }
            if (action.payload === CHANGE_PASSWORD) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_Change_Password: true
                    },
                    isError: false
                };
            }
            if (action.payload === INSCRIPTION_FOURNISSEUR) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_INSCRIPTION_FOURNISSEUR: true
                    },
                    isError: false
                };
            }
            if (action.payload === INSCRIPTION_FOURNISSEUR_FILE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_INSCRIPTION_FOURNISSEUR_FILE: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_VIDEOS_EVENTS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_get_videos: true
                    },
                    isError: false
                };
            }
            if (action.payload === CONFIGURE_MDP_FOURNISSEUR) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_CONFIGURE_MDP_FOURNISSEUR: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_APPELS_OFFRE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_APPELS_OFFRE: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_AUTH_FOURNISSEUR) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_AUTH_FOURNISSEUR: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_MARCHE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_MARCHE: true
                    },
                    isError: false
                };
            }
            if (action.payload === UPLOAD_FACTURE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_UPLOAD_FACTURE: true
                    },
                    isError: false
                };
            }
            if (action.payload === CHECK_AUTHENTICITE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_CHECK_AUTHENTICITE: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_NUM_AFFILIATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_NUM_AFFILIATION: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_APPELS_OFFRE_NC) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_APPELS_OFFRE_NC: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_FACTURE_FOURNISSEUR) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_FACTURE_FOURNISSEUR: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_RESET_PASSWORD) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_RESET_PASSWORD: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_PIECES) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_PIECES: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_PHOTOS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_PHOTOS: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_APPELS_FOURNISSEUR) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_APPELS_FOURNISSEUR: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_FACTURE_APPEL) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_FACTURE_APPEL: true
                    },
                    isError: false
                };
            }
            if (action.payload === ADD_FACTURE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_ADD_FACTURE: true
                    },
                    isError: false
                };
            }
            if (action.payload === CONFIGURE_PWD) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_CONFIGURE_PWD: true
                    },
                    isError: false
                };
            }
            if (action.payload === UPDATE_DONNEES) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_UPDATE_DONNEES: true
                    },
                    isError: false
                };
            }
            if (action.payload === ADD_PLAQUETTE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_ADD_PLAQUETTE: true
                    },
                    isError: false
                };
            }
            if (action.payload === RESET_PWD) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_RESET_PWD: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_LIENS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_LIENS: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_FACTURES) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_FACTURES: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_MOTIFS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_MOTIFS: true
                    },
                    isError: false
                };
            }
            if (action.payload === SIMULER_CAPITAL) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SIMULER_CAPITAL: true
                    },
                    isError: false
                };
            }
            if (action.payload === SIMULER_RENTE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SIMULER_RENTE: true
                    },
                    isError: false
                };
            }
            if (action.payload === SIMULER_CAPITAL_AVANCE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SIMULER_CAPITAL_AVANCE: true
                    },
                    isError: false
                };
            }
            if (action.payload === SIMULER_RENTE_AVANCE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SIMULER_RENTE_AVANCE: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_EVENTS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_EVENTS: true
                    },
                    isError: false
                };
            }
            if (action.payload === SIMULER_RENTE_INVERSE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SIMULER_RENTE_INVERSE: true
                    },
                    isError: false
                };
            }
            if (action.payload === SIMULER_CAPITAL_INVERSE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SIMULER_CAPITAL_INVERSE: true
                    },
                    isError: false
                };
            }
            if (action.payload === ADD_RECLAMATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_ADD_RECLAMATION: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_RECLAMATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_RECLAMATION: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_NOTIFS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_NOTIFS: true
                    },
                    isError: false
                };
            }
            if (action.payload === OPEN_NOTIF) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_OPEN_NOTIF: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_VERIFY_TOKEN_FOURNISSEUR) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_VERIFY_TOKEN_FOURNISSEUR: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_SURVEY) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_SURVEY: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_ADS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_ADS: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_ALL_RENCONTRES) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_ALL_RENCONTRES: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_DATA) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_DATA: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_CHAUDS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_CHAUDS: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_PENSION_DETAIL) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_PENSION_DETAIL: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_PEN_PAIMENT_DETAIL) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_PEN_PAIMENT_DETAIL: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_PEN_ENFANT_DETAIL) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_PEN_ENFANT_DETAIL: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_PEN_ATTAK_DETAIL) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_PEN_ATTAK_DETAIL: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_INFO_EPARGNE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_INFO_EPARGNE: true
                    },
                    isError: false
                };
            }
            if (action.payload === EVALUATE_PRESTA) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_EVALUATE_PRESTA: true
                    },
                    isError: false
                };
            }
            if (action.payload === SIMULATION_COMBINE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SIMULATION_COMBINE: true
                    },
                    isError: false
                };
            }
            if (action.payload === GET_TAUX_BO) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_TAUX_BO: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_JOURNALISTE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_JOURNALISTE: true
                    },
                    isError: false
                };
            }
            if (action.payload === CHANGE_STATUS_NOTIF) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_CHANGE_STATUS_NOTIF: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_CUSTOM_FILE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_CUSTOM_FILE: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_FILE_CHEQUE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_FILE_CHEQUE: true
                    },
                    isError: false
                };
            }
            if (action.payload === COMPARER_SIGNATURE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_COMPARER_SIGNATURE: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_DOC_STRUCTURE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_DOC_STRUCTURE: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_DATA_CHAT) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_DATA_CHAT: true
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_TRAITER_DOSSIER) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_TRAITER_DOSSIER: true
                    },
                    isError: false
                };
            }
            return state;
        case API_END:
            if (action.payload === SEND_AUTH) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_AUTH: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_AUTH_TOKEN) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_AUTH_TOKEN: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_VERIFY_TOKEN) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_VERIFY_TOKEN: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_MDP_RESET) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_MDP_RESET: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_DETAILS_CLIENT) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_DETAILS_CLIENT: false
                    },
                    isError: false
                };
            }
            if (action.payload === ADD_INSCRIPTION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_ADD_INSCRIPTION: false
                    },
                    isError: false
                };
            }
            if (action.payload === VERIFY_OTP) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SET_DOCUMENT_VISIBIILTY: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_OTP_MAIL) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_send_mail: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_UPDATE_MAIL) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_UPDATE_MAIL: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_APPELS_OFFRES) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_APPELS_OFFRES: false
                    },
                    isError: false
                };
            }
            if (action.payload === UPDATE_BENEFS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_UPDATE_BENEFS: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_APPELS_OFFRES_PUBLIC) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_APPELS_OFFRES_PUBLIC: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_APPEL_OFFRE_QUESTIONS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_APPEL_OFFRE_QUESTIONS: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_ANSWER_QUESTIONNAIRE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_ANSWER_QUESTIONNAIRE: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_NOTIF) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_NOTIF: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_INBOX) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_INBOX: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_SOUSCRIPTION_ATTAKMILI) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SOUSCRIPTION_ATTAKMILI: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_CONSULTATION_DONNEES) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_CONSULTATION_DONNEES: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_CANDIDATURE_SPONTANEE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_CANDIDATURE_SPONTANEE: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_OFFRES) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_OFFRES: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_ACTUALITY) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_ACTUALITY: false
                    },
                    isError: false
                };
            }
            if (action.payload === FORGOT_PWD) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_FORGOT_PWD: false
                    },
                    isError: false
                };
            }
            if (action.payload === NEW_PWD) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_NEW_PWD: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_OFFRE_APPLICATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_OFFRE_APPLICATION: false
                    },
                    isError: false
                };
            }
            if (action.payload === CHANGE_DATA) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_CHANGE_DATA: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_LINKS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_LINKS: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_STATISTIQUE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_STATISTIQUE: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_CANDIDATURE_SPONTANEE_FILE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_CANDIDATURE_SPONTANEE_FILE: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_APPLICATION_FILE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_APPLICATION_FILE: false
                    },
                    isError: false
                };
            }
            if (action.payload === VALIDER_REPRISE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_VALIDER_REPRISE: false
                    },
                    isError: false
                };
            }
            if (action.payload === UPLOAD_PJ) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_UPLOAD_PJ: false
                    },
                    isError: false
                };
            }
            if (action.payload === SYNCHRO_ATTAKMILI) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SYNCHRO_ATTAKMILI: false
                    },
                    isError: false
                };
            }
            if (action.payload === SYNCHRO_ATTAKMILI_LIQUIDATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SYNCHRO_ATTAKMILI_LIQUIDATION: false
                    },
                    isError: false
                };
            }
            if (action.payload === VALIDER_SOUSCRIPTION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_VALIDER_SOUSCRIPTION: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_SOUSCRIPTION_STATUS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SOUSCRIPTION_STATUS: false
                    },
                    isError: false
                };
            }
            if (action.payload === DEMANDE_INTERRUPTION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_DEMANDE_INTERRUPTION: false
                    },
                    isError: false
                };
            }
            if (action.payload === DEMANDE_REPRISE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_DEMANDE_REPRISE: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_SLIDERS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SLIDERS: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_OFFRE_INFO) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_OFFRE_INFO: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_DEMANDE_LIQUIDATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_DEMANDE_LIQUIDATION: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_VALIDER_DEMANDE_LIQUIDATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_VALIDER_DEMANDE_LIQUIDATION: false
                    },
                    isError: false
                };
            }
            if (action.payload === VALIDER_INTERRUPTION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_VALIDER_INTERRUPTION: false
                    },
                    isError: false
                };
            }
            if (action.payload === VALIDER_REPRISE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_VALIDER_REPRISE: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_UPDATE_PHONE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_UPDATE_PHONE: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_OTP_PHONE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_send_phone: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_UPDATE_MAIL_PHONE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_update_email_phone: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_CHANGE_PWD) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_change_pwd: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_SIMULATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_simulation: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_CONTACT) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_CONTACT: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_PRESTATIONS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_prestations: false
                    },
                    isError: false
                };
            }
            if (action.payload === CONFIGURE_MDP) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_CONFIGURE_MDP: false
                    },
                    isError: false
                };
            }
            if (action.payload === FORGET_MDP) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_FORGET_MDP: false
                    },
                    isError: false
                };
            }
            if (action.payload === CHANGE_MDP) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_CHANGE_MDP: false
                    },
                    isError: false
                };
            }
            if (action.payload === RESEND_OTP) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_RESEND_OTP: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_ALL_PRESTATIONS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_ALL_PRESTATIONS: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_ALL_PRESTATIONS_2) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_ALL_PRESTATIONS_2: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_PRESTATION_BY_ID) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_PRESTATION_BY_ID: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_LIBELLE_PPR) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_LIBELLE_PPR: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_OTP) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_OTP: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_VERIFY_ATTAK_OTP) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_VERIFY_ATTAK_OTP: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_VERIFY_VER_OTP) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_VERIFY_VER_OTP: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_LIST_ACTUALITES) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_LIST_ACTUALITES: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_POSTULATION_FILE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_POSTULATION_FILE: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_PENS_DONNEES) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_PENS_DONNEES: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_VERSEMENT) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_VERSEMENT: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_COTISATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_COTISATION: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_BENEFS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_BENEFS: false
                    },
                    isError: false
                };
            }
            if (action.payload === UPDATE_SOUSCRIPTION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_UPDATE_SOUSCRIPTION: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_VALIDER_COTISATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_VALIDER_COTISATION: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_LIST_ACTUALITES_ENABLED) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_LIST_ACTUALITES_ENABLED: false
                    },
                    isError: false
                };
            }
            if (action.payload === MODIF_DONNEE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_MODIF_DONNEE: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_LIST_FAQ) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_LIST_FAQ: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_PRESTATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_PRESTATION: false
                    },
                    isError: false
                };
            }
            if (action.payload === ADD_PIECE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_ADD_PIECE: false
                    },
                    isError: false
                };
            }
            if (action.payload === DELETE_BENEF) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_DELETE_BENEF: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_PRESTATION_CONNECTED) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_PRESTATION_CONNECTED: false
                    },
                    isError: false
                };
            }
            if (action.payload === DOWNLOAD_ATTESTATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_DOWNLOAD_ATTESTATION: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_DEMANDE_DETAILS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_DEMANDE_DETAILS: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_ATTAKMILI_INFO) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_ATTAKMILI_INFO: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_DOCUMENTATIONS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_DOCUMENTATIONS: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_SIMULATION_DATA) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SIMULATION_DATA: false
                    },
                    isError: false
                };
            }
            if (action.payload === ADD_PIECE_CONNECTED) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_ADD_PIECE_CONNECTED: false
                    },
                    isError: false
                };
            }
            if (action.payload === VERIFY_OTP_PRESTATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_VERIFY_OTP_PRESTA: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_OTP_PRESTATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_OTP_PRESTA: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_PREST_DEMANDE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_PREST_DEMANDE: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_SITUATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SITUATION: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_PENSION_DONNEE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_PENSION_DONNEE: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_PENSION_DEMANDES) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_PENSION_DEMANDES: false
                    },
                    isError: false
                };
            }
            if (action.payload === CHANGE_CANAL) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_Change_Canal: false
                    },
                    isError: false
                };
            }
            if (action.payload === CHANGE_PASSWORD) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_Change_Password: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_VIDEOS_EVENTS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_get_videos: false
                    },
                    isError: false
                };
            }
            if (action.payload === INSCRIPTION_FOURNISSEUR) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_INSCRIPTION_FOURNISSEUR: false
                    },
                    isError: false
                };
            }
            if (action.payload === INSCRIPTION_FOURNISSEUR_FILE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_INSCRIPTION_FOURNISSEUR_FILE: false
                    },
                    isError: false
                };
            }
            if (action.payload === CONFIGURE_MDP_FOURNISSEUR) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_CONFIGURE_MDP_FOURNISSEUR: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_APPELS_OFFRE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_APPELS_OFFRE: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_AUTH_FOURNISSEUR) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_AUTH_FOURNISSEUR: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_MARCHE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_MARCHE: false
                    },
                    isError: false
                };
            }
            if (action.payload === UPLOAD_FACTURE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_UPLOAD_FACTURE: false
                    },
                    isError: false
                };
            }
            if (action.payload === CHECK_AUTHENTICITE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_CHECK_AUTHENTICITE: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_NUM_AFFILIATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_NUM_AFFILIATION: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_APPELS_OFFRE_NC) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_APPELS_OFFRE_NC: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_FACTURE_FOURNISSEUR) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_FACTURE_FOURNISSEUR: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_RESET_PASSWORD) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_RESET_PASSWORD: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_PHOTOS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_PHOTOS: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_APPELS_FOURNISSEUR) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_APPELS_FOURNISSEUR: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_FACTURE_APPEL) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_FACTURE_APPEL: false
                    },
                    isError: false
                };
            }
            if (action.payload === ADD_FACTURE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_ADD_FACTURE: false
                    },
                    isError: false
                };
            }
            if (action.payload === CONFIGURE_PWD) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_CONFIGURE_PWD: false
                    },
                    isError: false
                };
            }
            if (action.payload === UPDATE_DONNEES) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_UPDATE_DONNEES: false
                    },
                    isError: false
                };
            }
            if (action.payload === ADD_PLAQUETTE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_ADD_PLAQUETTE: false
                    },
                    isError: false
                };
            }
            if (action.payload === RESET_PWD) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_RESET_PWD: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_LIENS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_LIENS: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_FACTURES) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_FACTURES: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_MOTIFS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_MOTIFS: false
                    },
                    isError: false
                };
            }
            if (action.payload === SIMULER_CAPITAL) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SIMULER_CAPITAL: false
                    },
                    isError: false
                };
            }
            if (action.payload === SIMULER_RENTE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SIMULER_RENTE: false
                    },
                    isError: false
                };
            }
            if (action.payload === SIMULER_CAPITAL_AVANCE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SIMULER_CAPITAL_AVANCE: false
                    },
                    isError: false
                };
            }
            if (action.payload === SIMULER_RENTE_AVANCE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SIMULER_RENTE_AVANCE: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_EVENTS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_EVENTS: false
                    },
                    isError: false
                };
            }
            if (action.payload === SIMULER_RENTE_INVERSE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SIMULER_RENTE_INVERSE: false
                    },
                    isError: false
                };
            }
            if (action.payload === SIMULER_CAPITAL_INVERSE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SIMULER_CAPITAL_INVERSE: false
                    },
                    isError: false
                };
            }
            if (action.payload === METIER_VER) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_METIER_VER: false
                    },
                    isError: false
                };
            }
            if (action.payload === ADD_RECLAMATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_ADD_RECLAMATION: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_RECLAMATION) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_RECLAMATION: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_NOTIFS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_NOTIFS: false
                    },
                    isError: false
                };
            }
            if (action.payload === OPEN_NOTIF) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_OPEN_NOTIF: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_VERIFY_TOKEN_FOURNISSEUR) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_VERIFY_TOKEN_FOURNISSEUR: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_SURVEY) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_SURVEY: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_ADS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_ADS: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_ALL_RENCONTRES) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_ALL_RENCONTRES: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_DATA) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_DATA: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_CHAUDS) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_CHAUDS: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_PENSION_DETAIL) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_PENSION_DETAIL: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_PEN_PAIMENT_DETAIL) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_PEN_PAIMENT_DETAIL: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_PEN_ENFANT_DETAIL) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_PEN_ENFANT_DETAIL: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_PEN_ATTAK_DETAIL) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_PEN_ATTAK_DETAIL: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_INFO_EPARGNE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_INFO_EPARGNE: false
                    },
                    isError: false
                };
            }
            if (action.payload === EVALUATE_PRESTA) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_EVALUATE_PRESTA: false
                    },
                    isError: false
                };
            }
            if (action.payload === SIMULATION_COMBINE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SIMULATION_COMBINE: false
                    },
                    isError: false
                };
            }
            if (action.payload === GET_TAUX_BO) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_GET_TAUX_BO: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_JOURNALISTE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_JOURNALISTE: false
                    },
                    isError: false
                };
            }
            if (action.payload === CHANGE_STATUS_NOTIF) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_CHANGE_STATUS_NOTIF: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_CUSTOM_FILE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_CUSTOM_FILE: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_FILE_CHEQUE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_FILE_CHEQUE: false
                    },
                    isError: false
                };
            }
            if (action.payload === COMPARER_SIGNATURE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_COMPARER_SIGNATURE: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_DOC_STRUCTURE) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_DOC_STRUCTURE: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_DATA_CHAT) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_DATA_CHAT: false
                    },
                    isError: false
                };
            }
            if (action.payload === SEND_TRAITER_DOSSIER) {
                return {
                    ...state,
                    isLoadingData: {
                        ...state.isLoadingData,
                        isLoadingData_SEND_TRAITER_DOSSIER: false
                    },
                    isError: false
                };
            }
            return state;
        case CLEAR_DECONNEXION:
            localStorage.clear();
            return initialState;

        case API_ERROR:
        case ACCESS_DENIED:
            return {
                ...state,
                isError: true
            };
        case TOKEN_EXPIRED:
            localStorage.clear();
            return initialState;

        case CLEAR_ADD_INSCRIPTION:
            return {
                ...state,
                data_add_inscription: null
            };
        case CLEAR_CONTACT:
            return {
                ...state,
                data_CONTACT: null
            };
        case CLEAR_LIST_PRESTATIONS:
            return {
                ...state,
                data_prestations: null
            };
        case CLEAR_COTISATION:
            return {
                ...state,
                data_COTISATION: null
            };
        case CLEAR_VERSEMENT:
            return {
                ...state,
                data_SET_VERSEMENT: action.payload
            };
        case CLEAR_VALIDER_COTISATION:
            return {
                ...state,
                data_VALIDER_COTISATION: null
            };
        case CLEAR_DEMANDE_REPRISE:
            return {
                ...state,
                data_DEMANDE_REPRISE: null
            };
        case CLEAR_DEMANDE_INTERRUPTION:
            return {
                ...state,
                data_DEMANDE_INTERRUPTION: null
            };
        case CLEAR_VERIFY_OTP:
            return {
                ...state,
                data_verify_otp: null
            };
        case CLEAR_DEMANDE_LIQUIDATION:
            return {
                ...state,
                data_DEMANDE_LIQUIDATION: null
            };
        case CLEAR_VALIDER_DEMANDE_LIQUIDATION:
            return {
                ...state,
                data_VALIDER_DEMANDE_LIQUIDATION: null
            };
        case CLEAR_VALIDER_INTERRUPTION:
            return {
                ...state,
                data_VALIDER_INTERRUPTION: null
            };
        case CLEAR_VALIDER_REPRISE:
            return {
                ...state,
                data_VALIDER_REPRISE: null
            };
        case CLEAR_VALIDER_SOUSCRIPTION:
            return {
                ...state,
                data_VALIDER_SOUSCRIPTION: null
            };
        case CLEAR_CONFIGURE_MDP:
            return {
                ...state,
                data_configure_mdp: null
            };
        case CLEAR_FORGET_MDP:
            return {
                ...state,
                data_forget_mdp: null
            };
        case CLEAR_CHANGE_MDP:
            return {
                ...state,
                data_change_mdp: null
            };
        case CLEAR_RESEND_OTP:
            return {
                ...state,
                data_resend_otp: null
            };
        case CLEAR_INSCRIPTION_FOURNISSEUR:
            return {
                ...state,
                data_inscription_fournisseur: null
            };
        case CLEAR_INSCRIPTION_FOURNISSEUR_FILE:
            return {
                ...state,
                data_inscription_fournisseur_file: null
            };
        case CLEAR_CONFIGURE_MDP_FOURNISSEUR:
            return {
                ...state,
                data_CONFIGURE_MDP_FOURNISSEUR: null
            };
        case CLEAR_UPLOAD_FACTURE:
            return {
                ...state,
                data_upload_facture: null
            };
        case CLEAR_APPELS_OFFRE_NC:
            return {
                ...state,
                data_APPELS_OFFRE_NC: null
            };
        case CLEAR_UPDATE_BENEFS:
            return {
                ...state,
                data_UPDATE_BENEFS: null
            };
        case CLEAR_UPDATE_SOUSCRIPTION:
            return {
                ...state,
                data_UPDATE_SOUSCRIPTION: null
            };
        case CLEAR_OTP_MAIL:
            return {
                ...state,
                data_otp_mail: null
            };
        case CLEAR_OTP_PHONE:
            return {
                ...state,
                data_otp_phone: null
            };
        case CLEAR_CHANGE_PWD:
            return {
                ...state,
                data_change_pwd: null
            };
        case CLEAR_ADD_FACTURE:
            return {
                ...state,
                data_SET_FACTURE: null
            };
        case CLEAR_OFFRE_APPLICATION:
            return {
                ...state,
                data_OFFRE_APPLICATION: null
            };
        case CLEAR_CANDIDATURE_SPONTANEE:
            return {
                ...state,
                data_CANDIDATURE_SPONTANEE: null
            };
        case CLEAR_CANDIDATURE_SPONTANEE_FILE:
            return {
                ...state,
                data_CANDIDATURE_SPONTANEE_FILE: null
            };

        case CLEAR_APPLICATION_FILE:
            return {
                ...state,
                data_APPLICATION_FILE: null
            };
        case CLEAR_ANSWER:
            return {
                ...state,
                data_ANSWER_QUESTIONNAIRE: null
            };
        case CLEAR_UPLOAD_PJ:
            return {
                ...state,
                data_UPLOAD_PJ: null
            };
        case CLEAR_DONNEES:
            return {
                ...state,
                data_UPDATE_DONNEES: null
            };
        case CLEAR_PLAQUETTE:
            return {
                ...state,
                data_ADD_PLAQUETTE: null
            };
        case CLEAR_ADD_FACTURE:
            return {
                ...state,
                data_SET_FACTURE: null
            };
        case CLEAR_CHANGE_DATA:
            return {
                ...state,
                data_CHANGE_DATA: null
            };
        case CLEAR_SIMULATION_CAPITAL:
            return {
                ...state,
                data_SET_SIMULATION_CAPITAL: null
            };
        case CLEAR_SIMULATION_RENTE:
            return {
                ...state,
                data_SET_SIMULATION_RENTE: null
            };
        case CLEAR_SIMULATION_CAPITAL_AVANCE:
            return {
                ...state,
                data_SET_SIMULATION_CAPITAL_AVANCE: null
            };
        case CLEAR_SIMULATION_RENTE_AVANCE:
            return {
                ...state,
                data_SET_SIMULATION_RENTE_AVANCE: null
            };
        case CLEAR_SIMULATION_RENTE_INVERSE:
            return {
                ...state,
                data_SET_SIMULATION_RENTE_INVERSE: null
            };
        case CLEAR_SIMULATION_CAPITAL_INVERSE:
            return {
                ...state,
                data_SET_SIMULATION_CAPITAL_INVERSE: null
            };
        case CLEAR_SIM:
            return {
                ...state,
                data_Simulation: null
            };
        case CLEAR_BACK:
            return {
                ...state,
                data_Back: null
            };
        case CLEAR_ADD_PIECE_CONNECTED:
            return {
                ...state,
                data_Add_Piece_Connected: null
            };
        case CLEAR_VALIDER_REPRISE:
            return {
                ...state,
                data_SET_VALIDER_REPRISE: null
            };
        case CLEAR_PRESTATION:
            return {
                ...state,
                data_Prestation: null
            };
        case CLEAR_ADD_PIECE:
            return {
                ...state,
                data_Add_Piece: null
            };
        case CLEAR_ATTAK_OTP:
            return {
                ...state,
                data_OTP: null
            };
        case CLEAR_VERIFY_ATTAK_OTP:
            return {
                ...state,
                data_VERIFY_ATTAK_OTP: null
            };
        case CLEAR_VERIFY_VER_OTP:
            return {
                ...state,
                data_VERIFY_VER_OTP: null
            };
        case CLEAR_METIER_VER:
            return {
                ...state,
                data_SET_METIER_VER: null
            };
        case CLEAR_SIMULATION_COMBINE:
            return {
                ...state,
                data_SIMULATION_COMBINE: null
            };
        case CLEAR_JOURNALISTE:
            return {
                ...state,
                data_SET_JOURNALISTE: null
            };
        case CLEAR_CHANGE_STATUS_NOTIF:
            return {
                ...state,
                data_CHANGE_STATUS_NOTIF: null
            };
        case CLEAR_CUSTOM_FILE:
            return {
                ...state,
                data_CUSTOM_FILE: null
            };
        case CLEAR_FILE_CHEQUE:
            return {
                ...state,
                data_FILE_CHEQUE: null
            };
        case CLEAR_COMPARER_SIGNATURE:
            return {
                ...state,
                data_COMPARER_SIGNATURE: null
            };
        case CLEAR_DOC_STRUCTURE:
            return {
                ...state,
                data_DOC_STRUCTURE: null
            };
        case CLEAR_DATA_CHAT:
            return {
                ...state,
                data_DATA_CHAT: null
            };
        case CLEAR_TRAITER_DOSSIER:
            return {
                ...state,
                data_TRAITER_DOSSIER: null
            };
        case LOCATION_CHANGE:
            if (
                action.payload.location.pathname == ROOT &&
                action.payload.action === "PUSH" &&
                action.payload.location.state
            ) {
                return {
                    ...state,
                    isOpenLogin: action.payload.location.state
                };
            }
        default:
            return state;
    }
};
