import { replace } from "connected-react-router";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { ROOT } from "../../constants/routes";
import ChatDataOverview from "../../components/Tandem/ChatData/ChatDataOverview";
import { clearDataChat, sendDataChat } from "../../redux/actions/api";

const mapStateToProps = state => ({
    isLoading:
        state.data.api.isLoadingData &&
        state.data.api.isLoadingData.isLoadingData_SEND_DATA_CHAT,
    questionSended: state.data.api.data_DATA_CHAT
});

const mapDispatchToProps = dispatch => ({
    goToUrl: url => dispatch(push(url)),
    sendDataChat: data => dispatch(sendDataChat(data)),
    clearDataChat: () => dispatch(clearDataChat())
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatDataOverview);
