import { ArrowLeftOutlined } from "@ant-design/icons";
import React, { useState } from "react";

import scan from "../Assets/scan.png";
import extractData from "../Assets/extractionData.png";
import moyenPaiement from "../Assets/paiment.png";
import comparaison from "../Assets/comparaison.png";
import logo from "../Assets/TendemLogo.png";
import { Button } from "antd";
import SmartAnalysisFile from "./SmartAnalysisFile";
import ExtractionAnalysCC from "./ExtractionAnalysCC";
import MoyensPaiment from "./MoyensPaiment";
import ComparaisonSignature from "./ComparaisonSignature";

const SMART_ANALYSIS_ETAPE = {
    OVERVIEW: "OVERVIEW",
    FILE: "FILE",
    EXTRACTION: "EXTRACTION",
    PAIEMENT: "PAIEMENT",
    SIGNATURE: "SIGNATURE"
};
const SmartAnalysisOverview = ({
    isLoading,
    sendCustomFile,
    custoFileSended,
    clearCustomFile,
    sendFileCheque,
    chequeFileSended,
    clearFileCheque,
    sendDocStructure,
    docStructureSended,
    clearDocStructure,
    sendTraiterDossier,
    folderSended,
    clearTraiterDossier,
    comparerSignature,
    signatureCompared,
    clearComparerSignature
}) => {
    const [showEtape, setShowEtape] = useState(SMART_ANALYSIS_ETAPE.OVERVIEW);
    return (
        <>
            {showEtape === SMART_ANALYSIS_ETAPE.OVERVIEW && (
                <div className="tandem">
                    <div className="tandem-logo">
                        <img
                            src={logo}
                            alt="Tandem"
                            style={{ width: "350px" }}
                        />
                    </div>

                    <div className="tandem-title">
                        La plateforme qui analyse et automatise tous vos
                        traitements Back-office
                    </div>
                    <div className="tandem-cards">
                        <div
                            className="tandem-page2"
                            onClick={() =>
                                setShowEtape(SMART_ANALYSIS_ETAPE.EXTRACTION)
                            }
                        >
                            {" "}
                            <div className="tandem-card-page2">
                                <img
                                    src={scan}
                                    alt=""
                                    className="img-card-page2"
                                />
                            </div>
                            <div className="tandem-card-text-page2">
                                Analyse et extraction de toutes les données et
                                points de contrôles paramétrés
                            </div>
                            <div className="button">
                                <Button className="doc-button">
                                    <div>Documents structurés</div>
                                </Button>
                            </div>
                        </div>

                        <div
                            className="tandem-page2"
                            onClick={() =>
                                setShowEtape(SMART_ANALYSIS_ETAPE.PAIEMENT)
                            }
                        >
                            {" "}
                            <div className="tandem-card-page2">
                                <img
                                    src={moyenPaiement}
                                    alt=""
                                    className="img-card-page2"
                                />
                            </div>
                            <div className="tandem-card-text-page2">
                                Vérification des moyens de paiement (Chèques,
                                Virements, MAD…)
                            </div>
                            <div className="button">
                                <Button className="doc-button">
                                    <div>Moyens de paiement</div>
                                </Button>
                            </div>
                        </div>
                        <div
                            className="tandem-page2"
                            onClick={() =>
                                setShowEtape(SMART_ANALYSIS_ETAPE.SIGNATURE)
                            }
                        >
                            {" "}
                            <div className="tandem-card-page2">
                                <img
                                    src={comparaison}
                                    alt=""
                                    className="img-card-page2"
                                />
                            </div>
                            <div className="tandem-card-text-page2">
                                Comparaison des signatures entre les documents
                            </div>
                            <div className="button">
                                <Button className="doc-button">
                                    <div>Modèle de signature</div>
                                </Button>
                            </div>
                        </div>
                        <div
                            className="tandem-page2"
                            onClick={() =>
                                setShowEtape(SMART_ANALYSIS_ETAPE.FILE)
                            }
                        >
                            {" "}
                            <div className="tandem-card-page2">
                                <img
                                    src={extractData}
                                    alt=""
                                    className="img-card-page2"
                                />
                            </div>
                            <div className="tandem-card-text-page2">
                                Analyse et extraction de données souhaitées à
                                partir de tout document
                            </div>
                            <div className="button">
                                <Button className="doc-button">
                                    <div>Autres documents</div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showEtape === SMART_ANALYSIS_ETAPE.FILE && (
                <SmartAnalysisFile
                    setShowEtape={setShowEtape}
                    SMART_ANALYSIS_ETAPE={SMART_ANALYSIS_ETAPE}
                    isLoading={isLoading}
                    sendCustomFile={sendCustomFile}
                    custoFileSended={custoFileSended}
                    clearCustomFile={clearCustomFile}
                />
            )}
            {showEtape === SMART_ANALYSIS_ETAPE.EXTRACTION && (
                <ExtractionAnalysCC
                    setShowEtape={setShowEtape}
                    SMART_ANALYSIS_ETAPE={SMART_ANALYSIS_ETAPE}
                    isLoading={isLoading}
                    sendDocStructure={sendDocStructure}
                    docStructureSended={docStructureSended}
                    clearDocStructure={clearDocStructure}
                    sendTraiterDossier={sendTraiterDossier}
                    folderSended={folderSended}
                    clearTraiterDossier={clearTraiterDossier}
                />
            )}
            {showEtape === SMART_ANALYSIS_ETAPE.SIGNATURE && (
                <ComparaisonSignature
                    setShowEtape={setShowEtape}
                    SMART_ANALYSIS_ETAPE={SMART_ANALYSIS_ETAPE}
                    isLoading={isLoading}
                    comparerSignature={comparerSignature}
                    signatureCompared={signatureCompared}
                    clearComparerSignature={clearComparerSignature}
                />
            )}
            {showEtape === SMART_ANALYSIS_ETAPE.PAIEMENT && (
                <MoyensPaiment
                    setShowEtape={setShowEtape}
                    SMART_ANALYSIS_ETAPE={SMART_ANALYSIS_ETAPE}
                    isLoading={isLoading}
                    sendFileCheque={sendFileCheque}
                    chequeFileSended={chequeFileSended}
                    clearFileCheque={clearFileCheque}
                />
            )}
        </>
    );
};

export default SmartAnalysisOverview;
