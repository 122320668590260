// router path
export const ROOT = "/Portal/";

export const REDIRECT_URL = "/Portal/auth/redirect";
export const TEST_AUTH = "/Portal/test/dgsn";
export const CMR = "cmr";
export const CMR_URL = ROOT + CMR;
export const URL_E_CLIENT = ROOT + "client";
export const PAGE_404 = ROOT + "notFound";
export const CONSTRUCTION = ROOT + "underConstruction";

export const TANDEM_OVERVIEW = "/demo";
export const SMART_ANALYSIS = TANDEM_OVERVIEW + "/smartanalysis";
export const CHAT_DATA = TANDEM_OVERVIEW + "/chatData";
export const SALES_ADVISOR = TANDEM_OVERVIEW + "/salesAdvisor";

export const E_RETRAITE = "https://www.cmr.gov.ma/Eretraite/";
export const E_RETRAITE_LOGIN = "https://www.cmr.gov.ma/Eretraite/login";
export const E_PARTENAIRE = "https://www.cmr.gov.ma/Partenaire/";
export const E_PARTENAIRE_LOGIN = "https://www.cmr.gov.ma/Partenaire/login";
// URL GRAND PUBLIC
export const PRESTATIONS_NON_CONNECTE = ROOT + "service";
export const RESTITUION = PRESTATIONS_NON_CONNECTE + "/restitution";
export const NUM_AFFILIE = PRESTATIONS_NON_CONNECTE + "/num-affilie";
export const AUTHENTICITE = PRESTATIONS_NON_CONNECTE + "/authenticite";
export const SIMULATEUR = PRESTATIONS_NON_CONNECTE + "/simulateur";
export const MENTIONS_LEGALES = CMR_URL + "/mentions-legales";

export const INSCRIPTION = CMR_URL + "/inscription";
export const INNOVATION = CMR_URL + "/innovation";
export const ALL_ACTS = CMR_URL + "/actualites";
export const ACTUALITES_DETAILS = CMR_URL + "/actualites-details";
export const INSCRIPTION_FOURNISSEUR = ROOT + "inscription-fournisseur";
export const URL_EVENTS = ROOT + "events";
export const PRESTATIONS_LIST = CMR_URL + "/prestations";
export const CAPITAL = ROOT + "capital";
export const FOURNISSEUR = ROOT + "fournisseur";
export const GOUVERNANCE = CMR_URL + "/gouvernance";
export const NEW_PASSWORD = ROOT + "new-password";
export const DOCS = CMR_URL + "/docs";
export const FAQ = DOCS + "/faq";
export const CONTACTER = CMR_URL + "/contacter";
export const DOCUMENTATION = DOCS + "/documentation";
export const ESPACE_FOURNISSUER = FOURNISSEUR + "/client";
export const SETTINGS_FOURNISSEUR = ESPACE_FOURNISSUER + "/mon-compte";
export const NOTIFICATIONS_FOURNISSEUR = ESPACE_FOURNISSUER + "/notifications";

export const ATTAKMILI_LP = CMR_URL + "/attakmili";
export const ATTAKMILI_LP_PRESENTATION = ATTAKMILI_LP + "/presentation";
export const ATTAKMILI_LP_SIMULATION = ATTAKMILI_LP + "/simulation";
export const ATTAKMILI_LP_SOUSCRIPTION = ATTAKMILI_LP + "/souscription";
export const ATTAKMILI_PRESENTATION = ROOT + "presentation-attakmili";

export const ALL_SERVICES = CMR_URL + "/services";
export const SETTINGS = URL_E_CLIENT + "/mon-compte";
export const CONFIGURE_PASSWORD = FOURNISSEUR + "/new-password";

export const PRESENTATION = CMR_URL + "/presentation";
export const GOUV_ORG = CMR_URL + "/gouvernance_org";
export const CMR_CHIFFRES = CMR_URL + "/chiffres";
export const PIECES = CMR_URL + "/pieces";
export const FORMULAIRES = CMR_URL + "/formulaires";
export const MEDIA = CMR_URL + "/media";
export const TEXTES_JURIDIQUES = CMR_URL + "/textes-juridiques";
export const PARCOURS_RETRAITE = CMR_URL + "/mon-parcours";
export const RENCONTRES = CMR_URL + "/rencontres-scientifiques";
//still hard coded
export const STRAT_INNOVATION = ROOT + "strategie-innovation";
export const DOSSIER = ROOT + "dossier";
//
export const CONFIG_FOURNISSEUR = FOURNISSEUR + "/configuration";
export const FORGET_PASSWORD_FOURNISSEUR = FOURNISSEUR + "/forget-password";

//  URL ATTAKMILI
export const ATTAKMILI = URL_E_CLIENT + "/attakmili";
export const AUTRES_SERVICES = ATTAKMILI + "/autres";
export const ATTAKMILI_SOUSCRIPTION = ATTAKMILI + "/souscription";
export const ATTAKMILI_SOUSCRIPTION_VIS = ATTAKMILI + "/souscription-vis";
export const ATTAKMILI_SOUSCRIPTION_AUT = ATTAKMILI + "/souscription-aut";
export const ATTAKMILI_COTISATION_AUT = ATTAKMILI + "/cotisation-aut";
export const ATTAKMILI_COTISATION_VIS = ATTAKMILI + "/cotisation-vis";
export const ATTAKMILI_CONSULTATION_DONNEES =
    ATTAKMILI + "/consultation-donnees";
export const ATTAKMILI_SOUSCRIPTION_PJS = ATTAKMILI + "/souscription-PJs";
export const ATTAKMILI_INFO = ATTAKMILI + "/informations";
export const ATTAKMILI_VERSEMENT = ATTAKMILI + "/versement";
export const ATTAKMILI_VERSEMENT_VIS = ATTAKMILI + "/versement-vis";
export const ATTAKMILI_VERSEMENT_PREST = ATTAKMILI + "/versement-prest";
export const ATTAKMILI_SERVICES = ATTAKMILI + "/services";
export const ATTAKMILI_DEMANDE_INTERRUPTION_PJ = ATTAKMILI + "/demande-pj";
export const ATTAKMILI_DEMANDE_INTERRUPTION =
    AUTRES_SERVICES + "/demande_interruption";
export const ATTAKMILI_DEMANDE_MODIFICATION =
    ATTAKMILI + "/demande_modification";
export const ATTAKMILI_DEMANDE_MODIFICATION_PJ =
    ATTAKMILI + "/demande_modification_pj";
export const ATTAKMILI_MODIFICATION_BENIFICIAIRE =
    ATTAKMILI + "/modification-benificiaires";
export const ATTAKMILI_COMPLEMENTAIRE = ATTAKMILI + "/complementaire";
export const ATTAKMILI_CONSULTATION = AUTRES_SERVICES + "/consultation";
export const ATTAKMILI_LIQUIDATION = AUTRES_SERVICES + "/liquidation";
export const ATTAKMILI_LIQUIDATION_PJ = ATTAKMILI + "/liquidation-pj";
export const ATTAKMILI_RACHAT = ATTAKMILI + "/rachat";
export const ATTAKMILI_RACHAT_PJ = ATTAKMILI + "/rachat-pj";
export const ATTAKMILI_BULLETIN = ATTAKMILI + "/consultation-bulletin";
export const ATTAKMILI_RECLAMATION = AUTRES_SERVICES + "/reclamations";
export const ATTAKMILI_SIMULATION = ATTAKMILI + "/simulation";

// URL AFFILIATION
export const AFFILIATION = URL_E_CLIENT + "/affiliation";
export const AFFILIATION_SERVICES = AFFILIATION + "/services";
export const AFFILIATION_PRESTATION = AFFILIATION_SERVICES + "/prestation";
export const AFFILIATION_DEMANDE_CARTE = AFFILIATION + "/carte";
export const AFFILIATION_DEMANDE_ATTESTATION = AFFILIATION + "/attestation";
export const AFFILIATION_REMBOURSEMENT = AFFILIATION + "/remboursement";
export const MISE_A_JOUR_CONTACT = AFFILIATION + "/mise-a-jour";
export const MES_DEMANDES = AFFILIATION + "/mes-demandes";
export const CALCUL_SIMULATION_PRINCIPALE =
    AFFILIATION + "/calcul-simulation-principale";
export const CLIENT_RECLAMATIONS = AFFILIATION + "/reclamations";
export const CLIENT_CONSULTATION_DONNEE = AFFILIATION + "/consultationDonnee";
export const PAIEMENT_COTISATION = AFFILIATION + "/paiement-cotisation";
export const AFFILIATION_RECLAMATION = AFFILIATION + "/reclamations";

//URL PENSION
export const PENSION = URL_E_CLIENT + "/pension";
export const PENSION_SERVICES = PENSION + "/services";
export const PENSION_PRESTATION = PENSION_SERVICES + "/prestation";

export const PENSION_DE_RETRAITE_PRINCIPALE_URL =
    PENSION + "/principale/services";

export const PENSION_DE_RETRAITE_DINVALIDITE_URL =
    PENSION + "/invalidite/services";
export const PENSION_DE_REVERSION_URL = PENSION + "/reversion/services";
export const PENSION_DE_RESISTANT_URL = PENSION + "/resistant/services";

export const P_P_CONSULTATION_DONNEE =
    PENSION_DE_RETRAITE_PRINCIPALE_URL + "/consultation-donnee";
export const P_I_CONSULTATION_DONNEE =
    PENSION_DE_RETRAITE_DINVALIDITE_URL + "/consultation-donnee";
export const P_RE_CONSULTATION_DONNEE =
    PENSION_DE_REVERSION_URL + "/consultation-donnee";
export const P_R_CONSULTATION_DONNEE =
    PENSION_DE_RESISTANT_URL + "/consultation-donnee";

export const P_P_RECLAMATION =
    PENSION_DE_RETRAITE_PRINCIPALE_URL + "/reclamations";
export const P_I_RECLAMATION =
    PENSION_DE_RETRAITE_DINVALIDITE_URL + "/reclamations";
export const P_RE_RECLAMATION = PENSION_DE_REVERSION_URL + "/reclamations";
export const P_R_RECLAMATION = PENSION_DE_RESISTANT_URL + "/reclamations";

export const P_P_CONSULTATION_DEMANDE =
    PENSION_DE_RETRAITE_PRINCIPALE_URL + "/consultation-demande";
export const P_I_CONSULTATION_DEMANDE =
    PENSION_DE_RETRAITE_DINVALIDITE_URL + "/consultation-demande";
export const P_RE_CONSULTATION_DEMANDE =
    PENSION_DE_REVERSION_URL + "/consultation-demande";
export const P_R_CONSULTATION_DEMANDE =
    PENSION_DE_RESISTANT_URL + "/consultation-demande";

export const P_P_DEMANDE_ATTESTATION =
    PENSION_DE_RETRAITE_PRINCIPALE_URL + "/demande-attestation";
export const P_I_DEMANDE_ATTESTATION =
    PENSION_DE_RETRAITE_DINVALIDITE_URL + "/demande-attestation";
export const P_R_DEMANDE_ATTESTATION =
    PENSION_DE_RESISTANT_URL + "/demande-attestation";
export const P_RE_DEMANDE_ATTESTATION =
    PENSION_DE_REVERSION_URL + "/demande-attestation";

export const PENSION_PRINCIPALE_PRESTATION =
    PENSION_DE_RETRAITE_PRINCIPALE_URL + "/prestation";
export const PENSION_INVALIDITE_PRESTATION =
    PENSION_DE_RETRAITE_DINVALIDITE_URL + "/prestation";
export const PENSION_REVERSION_PRESTATION =
    PENSION_DE_REVERSION_URL + "/prestation";
export const PENSION_RESISTANT_PRESTATION =
    PENSION_DE_RESISTANT_URL + "/prestation";

export const P_P_CARTE = PENSION_DE_RETRAITE_PRINCIPALE_URL + "/carte";
export const P_I_CARTE = PENSION_DE_RETRAITE_DINVALIDITE_URL + "/carte";
export const P_RE_CARTE = PENSION_DE_REVERSION_URL + "/carte";
export const P_R_CARTE = PENSION_DE_RESISTANT_URL + "/carte";
//  URL SERVICES AUTRES

/*export const CLIENT_MAJ = URL_E_CLIENT + "/modificationDonnees";
export const DEMANDE_ATTESTATION = URL_E_CLIENT + "/demandeAttestation";
export const DEMANDE_CARTE = URL_E_CLIENT + "/demandeCarte";
export const REMBOURSEMENT = URL_E_CLIENT + "/remboursement";*/
export const NOTIFICATIONS = URL_E_CLIENT + "/notifications";

export const ERROR_TOAST = "ERROR_TOAST";
