import {
    CLEAR_DECONNEXION,
    SET_AUTH_FOURNISSEUR,
    SET_VERIFY_TOKEN_FOURNISSEUR,
    TOKEN_EXPIRED
} from "../../actions/api";

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTH_FOURNISSEUR:
            if (
                action.payload &&
                action.payload.success &&
                action.payload.token &&
                action.payload.profil
            ) {
                return {
                    ...state,
                    authenticated: true,
                    info: action.payload?.client
                };
            }
        case SET_VERIFY_TOKEN_FOURNISSEUR:
            if (action.payload) {
                return {
                    ...state,
                    authenticated: true,
                    info: action.payload?.client
                };
            }
            return state;
        case TOKEN_EXPIRED:
        case CLEAR_DECONNEXION:
            localStorage.clear();
            return initialState;
        default:
            return state;
    }
};
