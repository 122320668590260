import { applyMiddleware } from "redux";

import router from "./router";
import apiMiddleware from "./api";

const middlewares = [
    router,
    apiMiddleware
    // other middlewares go here
];
export default applyMiddleware(...middlewares);
